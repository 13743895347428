import { Injectable } from '@angular/core';
import { AudioProfile } from '../../models/audio-profile.model';
import { AudioProfileUpload } from '../../models/audio-profile-upload.model';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { WaveformModel } from '../../models/waveform.model';
import { catchError, shareReplay, tap } from 'rxjs/operators';

@Injectable()
export class PallotoneApiService {
  local_url = 'http://localhost:3000/api/v1/';
  staging_url = 'https://staging.api.pallotone.com/api/';
  staging_static_url = 'https://i.s.ptoneimage.com/';
  production_url = 'https://prod.api.pallotone.com/api/';
  production_static_url = 'https://i.ptoneimage.com/';
  current_static_url = '';
  current_api_url = '';
  headers = new HttpHeaders();
  initializing = true;
  defaultOptions: any = { responseType: 'json', headers: this.headers };
  projectFeed$: Observable<any>;

  constructor(private http: HttpClient) {
    let origin = document.location.origin;
    if (
      origin.indexOf('dev-site.pallotone.com') > -1 ||
      origin.indexOf('9000') > -1
    ) {
      this.current_api_url = this.staging_url;
      this.current_static_url = this.staging_static_url;
    } else if (origin.indexOf('pallotone.com') > -1) {
      this.current_api_url = this.production_url;
      this.current_static_url = this.production_static_url;
    } else {
      window.alert('Error accessing api');
    }
  }

  /*******************************
   Endpoints
   *******************************/

  getTrackWaveform(waveformUrl: string) {
    let options: Object = { ...this.defaultOptions };
    return this.http.get<WaveformModel>(waveformUrl, options).pipe(
      tap((data) => this.log(data)),
      shareReplay(1),
      catchError(this.handleError),
    );
  }

  uploadAudioProfile(id: number) {
    let options: Object = { ...this.defaultOptions };
    return this.http
      .get<AudioProfileUpload>(
        this.current_api_url + `v1/audio_profiles/${id}/upload.json`,
        options,
      )
      .pipe(
        tap((data) => this.log(data)),
        catchError(this.handleError),
      );
  }

  updateAudioProfile(id: number, body: AudioProfileUpload) {
    let options: Object = { ...this.defaultOptions };
    return this.http
      .put<AudioProfile>(
        this.current_api_url + `v1/audio_profiles/${id}.json`,
        body,
        options,
      )
      .pipe(
        tap((data) => this.log(data)),
        catchError(this.handleError),
      );
  }

  getUsersSearch(query: string) {
    let params = new HttpParams();
    params = params.set('q', query);
    let options: Object = { ...this.defaultOptions, params: params };
    return this.http
      .get(this.current_api_url + 'v1/search/users.json', options)
      .pipe(
        tap((data) => this.log(data)),
        catchError(this.handleError),
      );
  }

  private log(data: any) {
    // console.log(data);
  }

  private handleError(error: any) {
    // In a real world app, we might use a remote logging infrastructure
    // We'd also dig deeper into the error to get a better message
    let errMsg = error.message
      ? error.message
      : error.status
      ? `${error.status} - ${error.statusText}`
      : 'Server error';
    console.error(errMsg); // log to console instead
    return throwError(error);
  }
}
