import { Injectable } from '@angular/core';
import { WaveformModel } from '../../models/waveform.model';

@Injectable({ providedIn: 'root' })
export class WaveformCacheService {
  private waveformCache: { [key: string]: WaveformModel } = {};

  getWaveform(channelId: string): WaveformModel | undefined {
    return this.waveformCache[channelId];
  }

  setWaveform(channelId: string, data: WaveformModel): void {
    this.waveformCache[channelId] = data;
  }
}
