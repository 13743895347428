<div class="dialog-container" [ngClass]="{ 'pick-image': !uploader.queue[0] }">
  @if (!uploader.queue[0]) {
  <div
    ng2FileDrop
    [ngClass]="{ 'nv-file-over': hasBaseDropZoneOver }"
    (fileOver)="fileOverBase($event)"
    [uploader]="uploader"
    (click)="selectFile()"
    class="file-drop-zone"
  >
    <div class="drop-area-wrapper absolute-center">
      <div class="drop-area-content absolute-center">
        <mat-icon class="size-60">cloud_upload</mat-icon>
        <h1>Select image or drag and drop</h1>
        @if (type === UploadType.Avatar) {
        <div class="image-format">JPG or PNG at least 1080x1080 pixels</div>
        } @if (type === UploadType.Artwork) {
        <div class="image-format">JPG or PNG at least 1080x1080 pixels</div>
        }
      </div>
    </div>
    <input
      mat-flat-button
      class="file-upload-input"
      type="file"
      #fileUploadSelector
      ng2FileSelect
      [uploader]="uploader"
    />
  </div>
  } @if (uploader.queue[0] && (uploader.queue[0].isUploading || loadingCropper))
  {
  <div class="loading-text">Loading image...</div>
  } @if ( uploader.queue[0] && !uploader.queue[0].isUploading &&
  !uploader.queue[0].isUploaded) {
  <div class="preview">
    <img #imagePreviewEl />
  </div>
  } @if (uploader.queue[0]) {
  <div class="crop-confirm-row">
    <h5>Crop Image</h5>
    <div class="crop-buttons">
      <button mat-button (click)="restartUploadProcess()">CANCEL</button>
      @if (cropperReady) {
      <button mat-button color="primary" (click)="saveCrop()">CROP</button>
      }
    </div>
  </div>
  }
  <button mat-icon-button class="close-dialog-button" (click)="close(false)">
    <mat-icon>close</mat-icon>
  </button>
</div>
