<div
  class="router"
  [ngClass]="{ 'fixed-toolbar': !scrollingToolbar && !noToolbar }"
>
  @if (!noToolbar) {
  <ptone-toolbar
    [scrolling]="scrollingToolbar"
    [transparent]="transparentToolbar"
  ></ptone-toolbar>
  }
  <ptone-now-playing-bar></ptone-now-playing-bar>
  <router-outlet></router-outlet>
  @if (!noFooter) {
  <ptone-footer></ptone-footer>
  }
</div>
