import { Injectable, inject } from '@angular/core';
import { TrackList } from '../../models/tracklist.model';
import { SortType } from '../../models/sort-type.enum';
import { TrackDataService } from '../track-data/track-data.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import {
  AudioSnackbarComponent,
  AudioSnackbarConfig,
} from '../../core-ui/audio-snackbar/audio-snackbar.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { first } from 'rxjs/operators';
import { getPtoneSnackBarConfig } from '../../utils/ptone-material.config';
import { TrackEntity } from '../../models/track-entity.model';

@Injectable()
export class NextUpService {
  private trackDataService = inject(TrackDataService);
  private matSnackBar = inject(MatSnackBar);

  trackLists: TrackList[] = [];

  currentTrackList: TrackList = undefined;
  nextUpSortedTracks: TrackEntity[] = [];
  queueTracks: TrackEntity[] = [];
  lastPlayedTracks: TrackEntity[] = [];
  lastGrabbed: TrackEntity;
  currentSort: SortType;
  sortedMaxPage: number;
  trackListSubscription: Subscription;
  trackListPosSubscription: Subscription;
  resolveNextTrack: (value?: PromiseLike<TrackEntity> | TrackEntity) => void;

  private nextUpChangedSource = new BehaviorSubject<[TrackList, TrackEntity[]]>(
    [this.currentTrackList, this.nextUpSortedTracks],
  );
  nextUpChangedWatch$ = this.nextUpChangedSource.asObservable();

  private trackListChangedSource = new BehaviorSubject<TrackList>(
    this.currentTrackList,
  );
  trackListChangedWatch$ = this.trackListChangedSource.asObservable();

  private queueChangedSource = new BehaviorSubject<TrackEntity[]>(this.queueTracks);
  queueChangedWatch$ = this.queueChangedSource.asObservable();

  createNewTrackList(type, title, perPage, data?): TrackList {
    return new TrackList({
      type: type,
      title: title,
      perPage: perPage,
      data: data,
    });
  }

  createOrReturnTrackList(type, title, perPage, data?): TrackList {
    const existingTrackList = this.trackLists.filter(
      (trackList: TrackList) =>
        trackList.type === type && trackList.data === data,
    );
    if (existingTrackList[0]) {
      return existingTrackList[0];
    } else {
      const temp = new TrackList({
        type: type,
        title: title,
        perPage: perPage,
        data: data,
      });
      this.trackLists.push(temp);
      return temp;
    }
  }

  setTrackList(trackList: TrackList) {
    if (trackList !== this.currentTrackList) {
      this.currentTrackList = trackList;
      this.nextUpSortedTracks = [];
      this.trackListChangedSource.next(this.currentTrackList);
      this.trackListSubscription = trackList.tracksChangedWatch$.subscribe(
        this.sortNextUp,
      );
      this.trackListPosSubscription = trackList.posChangedWatch$.subscribe(
        this.posChanged,
      );
    }
  }

  posChanged = (pos: number) => {
    this.lastGrabbed = this.currentTrackList.tracks[pos];
    this.lastPlayedTracks = this.currentTrackList.tracks.slice(0, pos);
    this.nextUpSortedTracks = this.currentTrackList.tracks.slice(pos + 1);
    this.nextUpChangedSource.next([
      this.currentTrackList,
      this.nextUpSortedTracks,
    ]);
  };

  sortNextUp = (res: TrackEntity[]) => {
    if (this.nextUpSortedTracks.length === 0 && this.currentTrackList.pos > 0) {
      this.lastPlayedTracks = res.splice(0, this.currentTrackList.pos + 1);
      this.lastGrabbed = this.lastPlayedTracks.pop();
    } else if (this.nextUpSortedTracks.length === 0 && res.length > 0) {
      this.lastGrabbed = res[0];
    }
    this.nextUpSortedTracks = this.nextUpSortedTracks.concat(res);
    if (this.resolveNextTrack) {
      this.resolveNextTrack(
        (this.lastGrabbed = this.nextUpSortedTracks.shift()),
      );
      this.resolveNextTrack = undefined;
    }
    this.nextUpChangedSource.next([
      this.currentTrackList,
      this.nextUpSortedTracks,
    ]);
  };

  async previousTrack(): Promise<TrackEntity> {
    return new Promise(
      (
        resolve: (value?: PromiseLike<TrackEntity> | TrackEntity) => void,
        reject,
      ) => {
        let tempPrev = this.lastPlayedTracks.pop();
        if (tempPrev) {
          this.currentTrackList.pos--;
          this.nextUpSortedTracks.unshift(this.lastGrabbed);
          resolve(tempPrev);
          this.lastGrabbed = tempPrev;
          this.nextUpChangedSource.next([
            this.currentTrackList,
            this.nextUpSortedTracks,
          ]);
        }
      },
    );
  }

  async nextTrack(): Promise<TrackEntity> {
    return new Promise(
      (
        resolve: (value?: PromiseLike<TrackEntity> | TrackEntity) => void,
        reject,
      ) => {
        let tempNext;
        if (this.queueTracks.length > 0) {
          tempNext = this.queueTracks.shift();
        } else {
          tempNext = this.nextUpSortedTracks.shift();
        }
        if (tempNext) {
          this.lastPlayedTracks.push(this.lastGrabbed);
          this.currentTrackList.pos++;
          this.lastGrabbed = tempNext;
          resolve(tempNext);
          this.nextUpChangedSource.next([
            this.currentTrackList,
            this.nextUpSortedTracks,
          ]);
          // this.resolveNextTrack = resolve;
          if (this.nextUpSortedTracks.length < 8) {
            this.loadNext();
          }
        } else {
          resolve(undefined);
        }
      },
    );
  }

  removeNextTracksTo(index: number) {
    this.lastPlayedTracks.push(
      this.lastGrabbed,
      ...this.nextUpSortedTracks.slice(0, index),
    );
    this.lastGrabbed = this.nextUpSortedTracks.slice(index, index + 1)[0];
    this.nextUpSortedTracks = this.nextUpSortedTracks.slice(
      index + 1,
      this.nextUpSortedTracks.length,
    );
    this.nextUpChangedSource.next([
      this.currentTrackList,
      this.nextUpSortedTracks,
    ]);
    if (this.nextUpSortedTracks.length < 8) {
      this.loadNext();
    }
  }

  sortTracks(sortType: SortType) {
    this.currentSort = sortType;
    switch (sortType) {
      case SortType.normal:
        break;
      case SortType.shuffle:
        break;
    }
  }

  loadNext(): void {
    this.trackDataService
      .loadNextTracks(this.currentTrackList)
      .subscribe((res) => {});
  }

  removeQueueTracksTo(index: number) {
    this.queueTracks = this.queueTracks.slice(
      index + 1,
      this.queueTracks.length,
    );
    this.queueChangedSource.next(this.queueTracks);
  }

  shiftQueueTracks(): TrackEntity {
    let shift = this.queueTracks.shift();
    this.queueChangedSource.next(this.queueTracks);
    return shift;
  }

  addQueueTrack(id: string) {
    this.queueChangedSource.next(this.queueTracks);
    this.trackDataService
      .getTrack(id)
      .pipe(first())
      .subscribe((res: TrackEntity) => {
        this.queueTracks.push(res);
        let audioSnackbarConfig = new AudioSnackbarConfig({
          name: res.title,
          artworkUrl: res.artworkUrl,
          trackId: res.id,
          playlistName: 'your Queue',
        });
        this.openSnackbar(undefined, true, audioSnackbarConfig);
      });
  }

  private openSnackbar(
    text: string,
    audioSnackbar?: boolean,
    audioSnackbarConfig?: AudioSnackbarConfig,
  ) {
    let snackBarConfig = getPtoneSnackBarConfig();
    if (audioSnackbar && audioSnackbarConfig) {
      snackBarConfig.panelClass = 'audio-snackbar';
      snackBarConfig.data = { config: audioSnackbarConfig };
      this.matSnackBar.openFromComponent(
        AudioSnackbarComponent,
        snackBarConfig,
      );
    } else {
      this.matSnackBar.open(text, undefined, snackBarConfig);
    }
  }
}

// Next in TrackData use addTracks of TrackList when data and type match from loadTracks
