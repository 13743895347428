import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { UploadManagerService } from './upload/upload.service';
import { UploadDialogManagerService } from './upload/upload-dialog-manager.service';
import { TrackDataService } from './track-data/track-data.service';
import { LocalSettingsService } from './local-settings/local-settings.service';
import { TrackSettingsService } from './track-settings/track-settings.service';
import { NextUpService } from './next-up/next-up.service';
import { LoginDialogGuard } from './gaurds/login-dialog.guard';
import { RevelationService } from './revelation/revelation.service';
import { PendingChangesGuard } from './gaurds/pending-changes.gaurd';
import { LoginGuard } from './gaurds/login.guard';
import { PallotoneApiService } from './ptoneapi/ptoneapi.service';
import { AuthGuard } from './gaurds/auth.guard';
import { AlreadyAuthGuard } from './gaurds/already-auth.guard';
import { LocalTrackService } from './local-track/local-track.service';
import { AuthService } from './auth/auth.service';
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { FileUploadService } from './file-upload/file-upload.service';
import { WaveformCacheService } from './waveform-cache/waveform-cache.service';
import { ArtistGuard } from './gaurds/artist.guard';
import { ArtistOfTrackGuard } from './gaurds/artist-of-track.guard';
import { TrackService } from './track/track.service';
import { UserService } from './user/user.service';
import { StripeService } from './stripe/stripe.service';
import { AudioChannelService } from './audio-channel/audio-channel.service';
import { ApolloService } from './apollo/apollo.service';

@NgModule({
  imports: [CommonModule, RouterModule, ApolloModule],
  providers: [
    RevelationService,
    UploadManagerService,
    UploadDialogManagerService,
    TrackDataService,
    LoginGuard,
    LoginDialogGuard,
    PendingChangesGuard,
    LocalSettingsService,
    TrackSettingsService,
    NextUpService,
    PallotoneApiService,
    AuthService,
    AuthGuard,
    AlreadyAuthGuard,
    ArtistGuard,
    ArtistOfTrackGuard,
    LocalTrackService,
    FileUploadService,
    WaveformCacheService,
    TrackService,
    UserService,
    StripeService,
    AudioChannelService,
    {
      provide: APOLLO_OPTIONS,
      useFactory: (apolloService: ApolloService) =>
        apolloService.createApollo(),
      deps: [ApolloService],
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class CoreModule {}
