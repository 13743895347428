import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  ApplicationRef,
  OnDestroy,
  Inject,
} from '@angular/core';
import { FileUploader, FileItem, FileUploadModule } from 'ng2-file-upload';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogModule,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { ImageUtils } from '../../../utils/image.utils';
import { getPtoneSnackBarConfig } from '../../../utils/ptone-material.config';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { UploadType } from '@pallotone/data-access';

declare let Cropper: any;

@Component({
  selector: 'ptone-upload-dialog',
  templateUrl: './upload-dialog.component.html',
  styleUrls: ['./upload-dialog.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    FileUploadModule,
  ],
})
export class UploadDialog implements OnInit, OnDestroy {
  @ViewChild('fileUploadSelector') fileUploadSelector: ElementRef;
  @ViewChild('imagePreviewEl') imagePreviewEl: ElementRef;
  type: UploadType;
  policy: string;
  awsFileName: string;
  uploadImage: FileItem;
  uploader: FileUploader;
  hasBaseDropZoneOver = false;
  cropper: any;
  cropperReady: boolean;
  loadingCropper: boolean;
  complete: boolean;

  UploadType = UploadType;

  private snackBarConfig = getPtoneSnackBarConfig();
  private destroy$ = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) private dialogData: any,
    public dialogRef: MatDialogRef<UploadDialog>,
    private applicationRef: ApplicationRef,
    private matSnackBar: MatSnackBar,
  ) {}

  ngOnInit() {
    this.uploader = this.dialogData.uploader;
    this.type = this.dialogData.type;
    this.uploader.onAfterAddingFile = (fileItem: FileItem) => {
      if (
        fileItem.file.type === 'image/jpeg' ||
        fileItem.file.type === 'image/jpg' ||
        fileItem.file.type === 'image/png'
      ) {
        this.uploadImage = fileItem;
        let file: File = fileItem._file;
        let myReader: FileReader = new FileReader();
        myReader.onloadend = (loadEvent: any) => {
          this.imagePreviewEl.nativeElement.src = loadEvent.target.result;
          this.applicationRef.tick();
          if (
            this.type === UploadType.Avatar ||
            this.type === UploadType.Artwork
          ) {
            this.cropper = new Cropper(this.imagePreviewEl.nativeElement, {
              aspectRatio: 1,
              viewMode: 3,
            });
            this.loadingCropper = false;
            this.cropperReady = true;
          } else {
            this.loadingCropper = false;
            this.cropperReady = true;
          }
        };
        this.loadingCropper = true;
        myReader.readAsDataURL(file);
      } else {
        this.openSnackbar('Upload must be in JPG or PNG format');
        fileItem.remove();
      }
    };
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  close(result: any) {
    this.dialogRef.close(result);
  }

  restartUploadProcess() {
    this.uploader.clearQueue();
  }

  saveCrop() {
    console.log('saveCrop');
    if (this.cropper && !this.complete) {
      console.log('cropper exists');
      let croppedCanvas = this.cropper.getCroppedCanvas({
        fillColor: '#ffffff',
      });
      ImageUtils.dataURLtoBlob(
        croppedCanvas.toDataURL('image/jpeg', 1.0),
      ).subscribe((blob: Blob) => {
        this.uploadImage._file = new File([blob], this.awsFileName, {
          type: 'image/jpeg',
        });
        this.complete = true;
        this.dialogRef.close('cropped');
      });
    }
  }

  selectFile() {
    this.fileUploadSelector.nativeElement.click();
  }

  fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  private openSnackbar(text: string) {
    this.matSnackBar.open(text, undefined, this.snackBarConfig);
  }
}
