import { AudioChannelEntity } from './audio-channel-entity.model';
import { PlayerState } from './player-state.enum';
import { TrackSettings } from './track-settings.model';
import {
  Favorite,
  LicenseSales,
  LicenseType,
  Maybe,
  Scalars,
  Track,
  TrackStatus,
  User,
  UserLicensePurchase,
} from '@pallotone/data-access';

export class TrackEntity implements Track {
  __typename?: 'Track';
  artist: User;
  artworkUrl?: Maybe<Scalars['String']['output']>;
  audioChannels?: Maybe<Array<Maybe<AudioChannelEntity>>>;
  commercialLicensePrice?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  exclusiveLicensePrice?: Maybe<Scalars['Int']['output']>;
  favorites?: Maybe<Favorite>;
  genres: Array<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  instruments: Array<Scalars['String']['output']>;
  isExclusiveOnly: Scalars['Boolean']['output'];
  isFree: Scalars['Boolean']['output'];
  likedByUser?: Maybe<Scalars['Boolean']['output']>;
  moods: Array<Scalars['String']['output']>;
  playCount: Scalars['Int']['output'];
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  purchasedLicenses?: Maybe<Array<Maybe<LicenseType>>>;
  salesSummary: Array<LicenseSales>;
  slug?: Maybe<Scalars['String']['output']>;
  standardLicensePrice?: Maybe<Scalars['Int']['output']>;
  status: TrackStatus;
  tags: Array<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  totalRevenue: Scalars['Float']['output'];
  updatedAt: Scalars['DateTime']['output'];
  userLicensePurchases: Array<UserLicensePurchase>;

  // Local properties
  settings?: TrackSettings;
  state?: PlayerState;
  duration?: number;

  public constructor(init?: Partial<TrackEntity>) {
    Object.assign(this, {
      settings: {},
      state: PlayerState.no_track,
      duration: 0,
      ...init,
    });
  }
}
