import { Injectable, inject } from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { UploadDialog } from '../../core-ui/dialogs/upload-dialog/upload-dialog.component';
import { FileUploader } from 'ng2-file-upload';
import { getPtoneDialogConfig } from '../../utils/ptone-material.config';
import { UploadType } from '@pallotone/data-access';

@Injectable()
export class UploadDialogManagerService {
  private matDialog = inject(MatDialog);

  private uploadDialogRef: MatDialogRef<UploadDialog>;

  createDialog(
    uploadType: UploadType,
    uploader: FileUploader,
  ): MatDialogRef<UploadDialog> {
    if (!this.uploadDialogRef) {
      let config: MatDialogConfig = getPtoneDialogConfig();
      // config.viewContainerRef = viewContainer;
      config.data = {
        type: uploadType,
        uploader: uploader,
      };
      this.uploadDialogRef = this.matDialog.open(UploadDialog, config);
      this.uploadDialogRef.afterClosed().subscribe(() => {
        this.uploadDialogRef = undefined;
      });
      return this.uploadDialogRef;
    } else {
      return undefined;
    }
  }

  getUploadDialogRef(): MatDialogRef<UploadDialog> {
    return this.uploadDialogRef;
  }

  close(value: any): boolean {
    if (this.uploadDialogRef) {
      this.uploadDialogRef.close(value);
      return true;
    }
    return false;
  }
}
