import { TrackListType } from './track-list-type.enum';
import { BehaviorSubject, Subject } from 'rxjs';
import { TrackEntity } from './track-entity.model';

export class TrackList {
  title: string;
  relUrl: string; // relative url to get back to the page for this list
  type: TrackListType;
  data: any; // unique data representing the track list, like username or playlist ID
  tracks: TrackEntity[] = [];
  pos = 0; // current index being played
  maxPage: number; // maximum page loaded from server
  perPage: number;

  private tracksChangedSource = new BehaviorSubject<TrackEntity[]>(
    this.tracks,
  );
  tracksChangedWatch$ = this.tracksChangedSource.asObservable();

  private posChangedSource = new Subject<number>();
  posChangedWatch$ = this.posChangedSource.asObservable();

  public constructor(init?: Partial<TrackList>) {
    Object.assign(this, init);
  }

  addTracks(tracks: TrackEntity[], page: number) {
    this.maxPage = page;
    this.tracks = this.tracks.concat(tracks);
    this.tracksChangedSource.next(tracks);
  }

  replaceTracks(tracks: TrackEntity[]) {
    this.tracks = tracks;
    this.tracksChangedSource.next(tracks);
  }

  setPos(pos: number) {
    this.pos = pos;
    this.posChangedSource.next(pos);
  }
}
