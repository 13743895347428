<div class="dialog-container">
  <div class="inner-dialog-container">
    <div class="track-header">
      @if (track.artworkUrl) {
      <a
        class="artwork"
        [routerLink]="['/track', track.id]"
        ptoneImage
        [ptoneImageUrl]="track.artworkUrl"
        ptoneImageSize="500x500"
        ptoneImageDefault="transparent"
      ></a>
      }
      <div>
        <a
          class="track-title a-underline"
          [routerLink]="['/track', track.id]"
          >{{ track.title }}</a
        >
      </div>
      <div>
        <a
          class="track-author a-underline"
          [routerLink]="['/artist', track.artist.username]"
          >{{ track.artist.name }}</a
        >
      </div>
    </div>
    @for (licenseOption of licenseOptions; track licenseOptions) {
    <div
      class="license-option"
      (click)="setSelectedLicense(licenseOption.type)"
      [ngClass]="{ selected: selectedLicense() === licenseOption.type }"
    >
      <div>
        <div class="license-option-title montserrat">
          {{ licenseOption.type | titlecase }}
        </div>
        <div class="license-option-subtitle">
          {{ licenseOption.shortDescription[0] }}. <br />
          {{ licenseOption.shortDescription[1] }}
        </div>
      </div>
      <div class="license-option-price">
        <span class="dollar-value">{{ licenseOption.price | currency }}</span>
      </div>
    </div>
    }
    <a
      mat-raised-button
      class="checkout-button full-button"
      [ngClass]="{ disabled: !selectedLicense() }"
      [disabled]="!selectedLicense()"
      (click)="navigateToCheckout()"
    >
      CHECKOUT
      <div class="selected-license-type">
        {{ selectedLicense() || 'Pick License' }}
      </div></a
    >
    <button mat-icon-button class="close-dialog-button" (click)="closeDialog()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
