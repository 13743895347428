import { Injectable, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LoginDialog } from '../../core-ui/dialogs/login-dialog/login-dialog.component';
import { Observable, map, take } from 'rxjs';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class LoginDialogGuard {
  private authService = inject(AuthService);
  private matDialog = inject(MatDialog);

  canActivate(): Observable<boolean> {
    return this.authService.authDto$.pipe(
      take(1),
      map((authDto) => {
        if (!authDto) {
          LoginDialog.showLoginDialog(this.matDialog);
          return false;
        }
        return true;
      }),
    );
  }
}
