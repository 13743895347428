<div class="audio-snackbar">
  @if (config.artworkUrl) {
  <img [src]="config.artworkUrl + '/120x120'" />
  } @else if (!config.artworkUrl) {
  <div class="artwork-placeholder"></div>
  }
  <div class="message">
    <div class="audio-name truncate">{{ config.name }}</div>
    <div class="audio-destination truncate">
      was added to {{ config.playlistName }}
    </div>
  </div>
</div>
