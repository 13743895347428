<mat-menu #matMenu="matMenu">
  <ng-template matMenuContent>
    <a mat-menu-item routerLink="/">Home</a>
    <a mat-menu-item routerLink="/upload">Submit music</a>
    <!-- <a mat-menu-item routerLink="/about-us">About us</a> -->
    <div class="divider"></div>
    <a mat-menu-item class="purple-menu-item" routerLink="/signin">Sign in</a>
    <a mat-menu-item class="purple-menu-item" routerLink="/signup">Sign up</a>
  </ng-template>
</mat-menu>
