import { Injectable, inject } from '@angular/core';
import { Observable, map } from 'rxjs';
import {
  GetUsersByRolesGQL,
  SearchArtistsGQL,
  UpdateOneUserGQL,
  UserByEmailGQL,
  UserByIdentifierGQL,
} from '@pallotone/data-access-ng';
import { User, UserRole } from '@pallotone/data-access';

@Injectable({ providedIn: 'root' })
export class UserService {
  private userByEmailGql = inject(UserByEmailGQL);
  private userByIdentifierGql = inject(UserByIdentifierGQL);
  private updateOneUserGql = inject(UpdateOneUserGQL);
  private getUsersByRolesGql = inject(GetUsersByRolesGQL);
  private searchArtistsGql = inject(SearchArtistsGQL);

  userByEmail(email: string): Observable<User> {
    return this.userByEmailGql
      .fetch({
        email,
      })
      .pipe(
        map((result) => {
          const user: User = result.data.userByEmail;
          return user;
        }),
      );
  }

  userByIdentifier(identifier: string): Observable<User> {
    return this.userByIdentifierGql
      .fetch({
        identifier,
      })
      .pipe(
        map((result) => {
          const user: User = result.data.userByIdentifier;
          return user;
        }),
      );
  }

  updateOneUser(
    id: string,
    name?: string,
    username?: string,
    biography?: string,
    avatarUrl?: string,
    websiteUrl?: string,
  ): Observable<User> {
    return this.updateOneUserGql
      .mutate({
        id,
        update: {
          name,
          username,
          biography,
          avatarUrl,
          websiteUrl,
        },
      })
      .pipe(
        map((result) => {
          const user: User = result.data.updateOneUser;
          return user;
        }),
      );
  }

  getUsersByRoles(roles: UserRole[], limit: number): Observable<User[]> {
    return this.getUsersByRolesGql
      .fetch({
        roles,
        limit,
      })
      .pipe(
        map((result) => {
          const users: User[] = result.data.getUsersByRoles;
          return users;
        }),
      );
  }

  searchArtists(
    query: string,
    limit?: number,
    offset?: number,
  ): Observable<User[]> {
    return this.searchArtistsGql
      .fetch({
        query,
        limit,
        offset,
      })
      .pipe(
        map((result) => {
          const users: User[] = result.data.searchArtists;
          return users;
        }),
      );
  }
}
