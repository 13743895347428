import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
  inject,
  signal,
} from '@angular/core';
import { LocalSettingsService } from '../core/local-settings/local-settings.service';
import { Subject, Subscription } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { RevelationService } from '../core/revelation/revelation.service';
import { TrackState } from '../models/track-state.enum';
import {
  TrackSettings,
  TrackSettingsRecord,
} from '../models/track-settings.model';
import { PlayerSettings } from '../models/player-settings.model';
import { RepeatState } from '../models/repeat-state.enum';
import { CommonModule } from '@angular/common';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterModule,
} from '@angular/router';
import { NgPipesModule } from 'ngx-pipes';
import { FormsModule } from '@angular/forms';
import { MatSliderModule } from '@angular/material/slider';
import { StemButtonsComponent } from '../stem-buttons/stem-buttons.component';
import { BuyLicenseButtonComponent } from '../core-ui/buy-license-button/buy-license-button.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DefaultArtworkComponent } from '../core-ui/default-artwork/default-artwork.component';
import { TimeStringPipe } from '../core-ui/pipes/time-string/time-string.pipe';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { TrackEntity } from '../models/track-entity.model';
import { getImageResizeUrl } from '../core/utils/image-resize-url.utils';

@Component({
  selector: 'ptone-now-playing-bar',
  templateUrl: './now-playing-bar.component.html',
  styleUrls: ['./now-playing-bar.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    RouterModule,
    NgPipesModule,
    FormsModule,
    MatSliderModule,
    MatButtonModule,
    StemButtonsComponent,
    BuyLicenseButtonComponent,
    MatProgressSpinnerModule,
    DefaultArtworkComponent,
    TimeStringPipe,
    MatIconModule,
  ],
})
export class NowPlayingBarComponent implements OnInit, OnDestroy {
  private playerService = inject(RevelationService);
  private localSettingsService = inject(LocalSettingsService);
  private readonly router = inject(Router);
  private changeDetectorRef = inject(ChangeDetectorRef);

  @ViewChildren('vuMeter') vuMeters: QueryList<ElementRef>;

  vuCanvases: HTMLCanvasElement[] = [];

  state = TrackState;
  activeTrack: TrackEntity;
  prevProgress: [number, number] = [0, 0];
  progress: [number, number] = [0, 0];
  trackChanging: boolean = true;
  tempProgress: number = undefined;
  draggingSliderProgress: boolean = false;
  noProgress: boolean = true;
  activeTrackSettingsSub: Subscription;
  volumeValue = signal(1);
  playerSettings: PlayerSettings;
  repeat: RepeatState = RepeatState.none;
  hoverX: number;
  activeChannelIndex: number;
  onActiveTrackPage = signal(false);

  private destroy$ = new Subject<void>();

  ngOnInit() {
    this.playerService.activeTrack$
      .pipe(takeUntil(this.destroy$))
      .subscribe((track: TrackEntity) => {
        this.activeTrack = track;
        this.clearCanvases();
        this.vuCanvases = [];
        if (this.activeTrack) {
          this.setupTrackSettingsSub();
        }
        this.checkOnActiveTrackPage();
        this.changeDetectorRef.detectChanges();
      });
    this.playerService.trackProgress$
      .pipe(takeUntil(this.destroy$))
      .subscribe((progress: [number, number]) => {
        if (!this.draggingSliderProgress && progress) {
          this.trackChanging = false;
          this.prevProgress = this.progress;
          this.progress = progress;
          this.changeDetectorRef.detectChanges();
          if (this.noProgress) {
            setTimeout(() => {
              this.noProgress = false;
            }, 200);
          }
        }
      });
    this.localSettingsService.playerSettingsWatch$
      .pipe(takeUntil(this.destroy$))
      .subscribe((settings: PlayerSettings) => {
        this.playerSettings = settings;
        if (settings.mute) {
          this.volumeValue.set(0);
        } else {
          this.volumeValue.set(settings.volume);
        }
        this.repeat = settings.repeat;
      });

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.checkOnActiveTrackPage();
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  checkOnActiveTrackPage() {
    const snapshot = this.router.routerState.root.firstChild;
    if (snapshot) {
      const path = snapshot.routeConfig.path;
      const params = snapshot.snapshot.params;
      if (
        (path === 'track/:identifier' || path === 'track/:identifier/edit') &&
        params['identifier'] === this.activeTrack?.id
      ) {
        this.onActiveTrackPage.set(true);
      } else {
        this.onActiveTrackPage.set(false);
      }
    } else {
      this.onActiveTrackPage.set(false);
    }
  }

  setupTrackSettingsSub() {
    if (this.activeTrackSettingsSub) {
      this.activeTrackSettingsSub.unsubscribe();
      this.activeTrackSettingsSub = undefined;
    }
    this.activeTrackSettingsSub = this.activeTrack.settings.change$
      .pipe(takeUntil(this.destroy$))
      .subscribe((value: [TrackSettingsRecord, TrackSettings]) => {
        this.vuCanvases = [];
        this.activeChannelIndex =
          value[1].stemsActive.findIndex((val) => val === true) || 0;
        this.vuMeters.forEach((item) => {
          this.vuCanvases.push(item.nativeElement);
        });
        this.vuCanvases.forEach((item) => {
          item.setAttribute('style', 'z-index: 0');
        });
        this.vuCanvases[this.activeChannelIndex]?.setAttribute(
          'style',
          'z-index: 2',
        );
        this.playerService.setCanvases('nowPlayingBar', this.vuCanvases);
        this.changeDetectorRef.detectChanges();
      });
  }

  togglePlay($event) {
    $event.stopPropagation();
    if (this.activeTrack) {
      this.playerService.togglePlay(this.activeTrack.id);
    }
  }

  sliderInput(event: any) {
    if (!this.draggingSliderProgress) {
      this.draggingSliderProgress = true;
    }
    this.tempProgress = Math.round(event.target.value);
    this.changeDetectorRef.detectChanges();
  }

  sliderProgressChanged(event: any) {
    if (!this.draggingSliderProgress) {
      this.draggingSliderProgress = true;
      this.changeDetectorRef.detectChanges();
    }
    let progSec = Math.round(event.target.value);
    this.playerService.setTrackTime(progSec);
    // this.playerService.requestProgress();
    this.progress = [progSec, progSec];
    this.tempProgress = undefined;
    setTimeout(() => {
      this.draggingSliderProgress = false;
      this.changeDetectorRef.detectChanges();
    }, 100);
  }

  volumeChange(event: any) {
    this.localSettingsService.setPlayerVolume(event.target.value);
  }

  toggleMute() {
    this.localSettingsService.toggleMute();
  }

  toggleRepeat() {
    switch (this.repeat) {
      case RepeatState.none:
        this.repeat = RepeatState.tracklist;
        break;
      case RepeatState.tracklist:
        this.repeat = RepeatState.track;
        break;
      case RepeatState.track:
        this.repeat = RepeatState.none;
        break;
      default:
        this.repeat = RepeatState.none;
        break;
    }
    this.localSettingsService.toggleRepeat(this.repeat);
  }

  getHoverX(event: MouseEvent) {
    this.hoverX = event.x;
  }

  clearCanvases() {
    for (let vuCanvas of this.vuCanvases) {
      let canvasCtx = vuCanvas.getContext('2d');
      canvasCtx.fillStyle = 'rgb(0, 0, 0)';
      canvasCtx.fillRect(0, 0, vuCanvas.width, vuCanvas.height);
    }
  }

  getImageResizeUrl(url: string, size: string): string {
    return getImageResizeUrl(url, size);
  }
}
