<!-- <div class="waveform-lane">
  <ptone-playhead
    [track]="activeTrack"
    (click)="$event.stopPropagation()"
  ></ptone-playhead>
  <div class="waveform-lane-inner">
    <ptone-waveform
      [track]="activeTrack"
      [channel]="activeChannelIndex"
      [trackSettings]="activeTrack.settings"
      [isExplorer]="true"
      [columnWidth]="1"
    ></ptone-waveform>
  </div>
</div> -->
<div
  #nowPlayingBar
  class="now-playing-bar"
  [ngClass]="{ 'on-active-track-page': onActiveTrackPage() }"
  (mousemove)="getHoverX($event)"
>
  <mat-slider
    class="ptone-audio-slider-bar"
    [ngClass]="{
      'hidden-progress': !activeTrack,
      'smooth-transition':
        !draggingSliderProgress &&
        !trackChanging &&
        !noProgress &&
        !activeTrack?.settings.buffering &&
        progress[0] - prevProgress[0] <= 2 &&
        progress[0] - prevProgress[0] >= -2
    }"
    min="0"
    [max]="
      activeTrack?.settings &&
      activeTrack?.settings.duration !== 0 &&
      !trackChanging
        ? activeTrack?.settings.duration
        : null
    "
    [disabled]="!activeTrack"
    (keydown)="$event.stopPropagation()"
  >
    <input
      matSliderThumb
      step="1"
      [value]="progress[0]"
      (input)="sliderInput($event)"
      (change)="sliderProgressChanged($event)"
    />
  </mat-slider>
  @if (activeTrack && hoverX && hoverX > 2 && !activeTrack?.settings.buffering)
  {
  <div class="hover-time" [style.left]="hoverX + 'px'">
    {{
      (hoverX / nowPlayingBar.offsetWidth) *
        activeTrack?.audioChannels[0].duration | timeString
    }}
  </div>
  }
  <div class="flex-wrapper">
    <div class="audio-controls">
      <button
        mat-icon-button
        class="play-button"
        [ngClass]="{
          playing:
            activeTrack?.settings.state === state.play ||
            activeTrack?.settings.state === state.begin_play
        }"
        [disabled]="!activeTrack"
        [title]="
          activeTrack?.settings.state === (state.play || state.begin_play)
            ? 'Pause (space)'
            : 'Play (space)'
        "
        (click)="togglePlay($event)"
      >
        @if ( activeTrack?.settings.state === state.play ||
        activeTrack?.settings.state === state.begin_play) {
        <mat-icon class="size-30">pause</mat-icon>
        } @else {
        <mat-icon class="size-30">play_arrow</mat-icon>
        }
      </button>
      <button
        mat-icon-button
        disabled="true"
        class="medium skip-button"
        title="Next track"
      >
        <mat-icon>skip_next</mat-icon>
      </button>
      <div class="volume">
        <button
          mat-icon-button
          class="medium volume-button"
          (click)="toggleMute()"
        >
          @if (volumeValue() > 0 && volumeValue() < 0.6) {
          <mat-icon>volume_down</mat-icon>
          } @else if (volumeValue() >= 0.6) {
          <mat-icon>volume_up</mat-icon>
          } @else {
          <mat-icon>volume_off</mat-icon>
          }
        </button>
        <mat-slider
          min="0"
          max="1"
          step="0.025"
          class="horizontal-volume-slider"
          (keydown)="$event.stopPropagation()"
          ><input
            matSliderThumb
            [value]="volumeValue()"
            (input)="volumeChange($event)"
        /></mat-slider>
        <div class="slider-pseudo-track"></div>
        @for (channel of activeTrack?.audioChannels; track $index) {
        <canvas #vuMeter></canvas>
        }
      </div>
      <span class="time">
        @if (activeTrack?.settings.active) {
        <span
          >{{
            (tempProgress === undefined ? (progress[0] | floor) : tempProgress)
              | timeString
          }}&nbsp;/&nbsp;</span
        >
        }{{ activeTrack?.settings.duration | timeString }}
      </span>
    </div>
    @if (activeTrack) {
    <div class="stem-buttons">
      <ptone-stem-buttons [track]="activeTrack"></ptone-stem-buttons>
    </div>
    }
    <div class="track-badge">
      <div class="artwork-container">
        <div
          class="artwork-image"
          [style.background]="
            activeTrack?.artworkUrl &&
            !activeTrack?.settings.stemsBuffering.get(0)
              ? 'url(' +
                getImageResizeUrl(activeTrack?.artworkUrl, '100x100') +
                ')'
              : 'transparent'
          "
        >
          @if (!activeTrack?.artworkUrl) {
          <ptone-default-artwork></ptone-default-artwork>
          } @if (activeTrack?.settings.stemsBuffering.get(0)) {
          <mat-spinner
            class="absolute-fill inverted"
            diameter="24"
          ></mat-spinner>
          }
        </div>
        @if (activeTrack && !activeTrack?.audioChannels[0].isLocal) {
        <a class="absolute-fill" [routerLink]="['/track', activeTrack?.id]"></a>
        }
      </div>
      @if (!activeTrack) {
      <div class="track-info-placeholder-block"></div>
      } @if (activeTrack) {
      <div class="track-info truncate">
        <div class="track-title truncate">
          <a
            class="a-underline"
            [ngClass]="{
              'non-link': activeTrack?.audioChannels[0].isLocal
            }"
            [routerLink]="['/track', activeTrack?.id]"
            >{{ activeTrack?.title }}</a
          >
        </div>
        @if (activeTrack?.artist) {
        <div class="author-name truncate">
          <a
            class="a-underline"
            [ngClass]="{
              'non-link': activeTrack?.audioChannels[0].isLocal
            }"
            [routerLink]="['/artist', activeTrack?.artist.username]"
            >{{ activeTrack?.artist.name }}</a
          >
        </div>
        }
      </div>
      }
      <div class="flex-spacer"></div>
      @if (activeTrack && !activeTrack?.audioChannels[0].isLocal) {
      <ptone-buy-license-button
        [track]="activeTrack"
        [shortenText]="true"
      ></ptone-buy-license-button>
      }
    </div>
  </div>
  @if (activeTrack && !activeTrack?.audioChannels[0].isLocal) {
  <a
    class="absolute-fill mobile-link-bg"
    [routerLink]="['/track', activeTrack?.id]"
  ></a>
  }
</div>
