import { List, Record } from 'immutable';
import { TrackState } from './track-state.enum';
import { Observable, Subject } from 'rxjs';

export class TrackSettings {
  private settingsChange: Subject<[TrackSettingsRecord, TrackSettings]> =
    new Subject<[TrackSettingsRecord, TrackSettings]>();
  change$: Observable<[TrackSettingsRecord, TrackSettings]> =
    this.settingsChange.asObservable();

  id: string = '';
  state: TrackState = TrackState.inactive;
  buffering: boolean = false;
  ready: boolean = false;
  active: boolean = false;
  investigating: boolean = false;
  duration: number = 0;
  gains: List<number> = List();
  mutes: List<boolean> = List();
  solos: List<boolean> = List();
  stemsActive: List<boolean> = List();
  stemsBuffering: List<boolean> = List();

  defaultSettings: TrackSettingsRecord;
  nextSettings: TrackSettingsRecord;

  public constructor(init?: Partial<TrackSettings>) {
    Object.assign(this, init);
    this.defaultSettings = new TrackSettingsRecord(this);
    this.nextSettings = new TrackSettingsRecord(this);
  }

  save() {
    const prevSettings = new TrackSettingsRecord(this);
    Object.assign(this, this.nextSettings.toObject());
    this.settingsChange.next([prevSettings, this]);
  }

  resetSettings(): TrackSettings {
    // console.log(this.defaultSettings);
    this.set('gains', this.defaultSettings.gains)
      .set('solos', this.defaultSettings.solos)
      .set('mutes', this.defaultSettings.mutes)
    // console.log('resetSettings', this.nextSettings);
    return this;
  }

  get(prop: string) {
    return this[prop];
  }

  set(prop: any, value: any): TrackSettings {
    this.nextSettings = this.nextSettings.set(prop, value);
    return this;
  }

  setGain(index: number, value: number): TrackSettings {
    this.nextSettings = this.nextSettings.setIn(['gains', index], value);
    return this;
  }

  setMute(index: number, value: boolean): TrackSettings {
    this.nextSettings = this.nextSettings.setIn(['mutes', index], value);
    return this;
  }

  toggleMute(index: number): TrackSettings {
    this.nextSettings = this.nextSettings.setIn(
      ['mutes', index],
      !this.mutes.get(index),
    );
    return this;
  }

  setSolo(index: number, value: boolean): TrackSettings {
    this.nextSettings = this.nextSettings.setIn(['solos', index], value);
    return this;
  }

  toggleSolo(index: number): TrackSettings {
    this.nextSettings = this.nextSettings.setIn(
      ['solos', index],
      !this.solos.get(index),
    );
    return this;
  }

  setStemActive(index: number, value: boolean): TrackSettings {
    this.nextSettings = this.nextSettings.setIn(['stemsActive', index], value);
    return this;
  }

  setStemBuffering(index: number, value: boolean): TrackSettings {
    this.nextSettings = this.nextSettings.setIn(
      ['stemsBuffering', index],
      value,
    );
    return this;
  }

  setStemsActive(stems: number[]): TrackSettings {
    this.nextSettings = this.nextSettings.set(
      'stemsActive',
      this.mapStemsActive(stems),
    );
    return this;
  }

  setStemsBuffering(stems: number[]): TrackSettings {
    this.nextSettings = this.nextSettings.set(
      'stemsBuffering',
      this.mapStemsBuffering(stems),
    );
    return this;
  }

  private mapStemsActive(trackIndexes: number[]): List<boolean> {
    const stemsActive = Array(this.stemsActive.size).fill(false);
    for (let stem of trackIndexes) {
      stemsActive[stem] = true;
    }
    return List(stemsActive);
  }

  private mapStemsBuffering(trackIndexes: number[]): List<boolean> {
    const stemsBuffering = Array(this.stemsBuffering.size).fill(false);
    for (let stem of trackIndexes) {
      stemsBuffering[stem] = true;
    }
    return List(stemsBuffering);
  }
}

export class TrackSettingsRecord extends Record({
  id: '',
  state: TrackState.inactive,
  buffering: false,
  ready: false,
  active: false,
  investigating: false,
  duration: 0,
  gains: List(),
  mutes: List(),
  solos: List(),
  stemsActive: List(),
  stemsBuffering: List(),
}) {
  id: string;
  state: TrackState;
  buffering: boolean;
  ready: boolean;
  active: boolean;
  investigating: boolean;
  duration: number;
  gains: List<number>;
  mutes: List<boolean>;
  solos: List<boolean>;
  stemsActive: List<boolean>;
  stemsBuffering: List<boolean>;
}
