<mat-menu #matMenu="matMenu">
  <ng-template matMenuContent>
    @if (authDto$ | async) {
    <div class="user-header truncate">
      <div class="user-name truncate">{{ (authDto$ | async).name }}</div>
      <div class="user-email truncate">{{ (authDto$ | async).email }}</div>
    </div>
    <div class="divider"></div>
    <a mat-menu-item routerLink="/account">
      <mat-icon>person</mat-icon>
      Account
    </a>
    <a mat-menu-item routerLink="/library">
      <mat-icon>folder</mat-icon>
      Library
    </a>
    @if ((authDto$ | async).roles.includes(UserRole.Artist)) {
    <!-- <a mat-menu-item [routerLink]="['/artist', (authDto$ | async).username]">
      <mat-icon>person</mat-icon>
      Artist profile
    </a> -->
    <a mat-menu-item routerLink="/upload">
      <mat-icon>file_upload</mat-icon>
      Submit music
    </a>
    }
    <!-- <a mat-menu-item routerLink="/about">
      <img
        src="../../../assets/images/logos/symbol-white.svg"
        alt="Pallotone logo"
        class="ptone-symbol-icon"
      />
      About
    </a> -->
    <button mat-menu-item (click)="showKeyboardShortcutsDialog()">
      <mat-icon>keyboard</mat-icon>
      Keyboard shortcuts
    </button>
    <div class="divider"></div>
    <button mat-menu-item (click)="signout()">
      <mat-icon>exit_to_app</mat-icon>
      Sign out
    </button>
    }
  </ng-template>
</mat-menu>
