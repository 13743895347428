import * as Operations from '@pallotone/data-access';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';

  @Injectable({
    providedIn: 'root'
  })
  export class CreateAudioChannelGQL extends Apollo.Mutation<Operations.CreateAudioChannelMutation, Operations.CreateAudioChannelMutationVariables> {
    document = Operations.CreateAudioChannelDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }

  @Injectable({
    providedIn: 'root'
  })
  export class StartTranscodingGQL extends Apollo.Mutation<Operations.StartTranscodingMutation, Operations.StartTranscodingMutationVariables> {
    document = Operations.StartTranscodingDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }

  @Injectable({
    providedIn: 'root'
  })
  export class GetListenUrlGQL extends Apollo.Query<Operations.GetListenUrlQuery, Operations.GetListenUrlQueryVariables> {
    document = Operations.GetListenUrlDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }

  @Injectable({
    providedIn: 'root'
  })
  export class GetDownloadUrlGQL extends Apollo.Query<Operations.GetDownloadUrlQuery, Operations.GetDownloadUrlQueryVariables> {
    document = Operations.GetDownloadUrlDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateAudioChannelGQL extends Apollo.Mutation<Operations.UpdateAudioChannelMutation, Operations.UpdateAudioChannelMutationVariables> {
    document = Operations.UpdateAudioChannelDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }

  @Injectable({
    providedIn: 'root'
  })
  export class RequestSignupGQL extends Apollo.Mutation<Operations.RequestSignupMutation, Operations.RequestSignupMutationVariables> {
    document = Operations.RequestSignupDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }

  @Injectable({
    providedIn: 'root'
  })
  export class SignupWithTokenGQL extends Apollo.Mutation<Operations.SignupWithTokenMutation, Operations.SignupWithTokenMutationVariables> {
    document = Operations.SignupWithTokenDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }

  @Injectable({
    providedIn: 'root'
  })
  export class SignupGQL extends Apollo.Mutation<Operations.SignupMutation, Operations.SignupMutationVariables> {
    document = Operations.SignupDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }

  @Injectable({
    providedIn: 'root'
  })
  export class SigninGQL extends Apollo.Mutation<Operations.SigninMutation, Operations.SigninMutationVariables> {
    document = Operations.SigninDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }

  @Injectable({
    providedIn: 'root'
  })
  export class SendPasswordResetEmailGQL extends Apollo.Mutation<Operations.SendPasswordResetEmailMutation, Operations.SendPasswordResetEmailMutationVariables> {
    document = Operations.SendPasswordResetEmailDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }

  @Injectable({
    providedIn: 'root'
  })
  export class ResetPasswordGQL extends Apollo.Mutation<Operations.ResetPasswordMutation, Operations.ResetPasswordMutationVariables> {
    document = Operations.ResetPasswordDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }

  @Injectable({
    providedIn: 'root'
  })
  export class ChangePasswordGQL extends Apollo.Mutation<Operations.ChangePasswordMutation, Operations.ChangePasswordMutationVariables> {
    document = Operations.ChangePasswordDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }

  @Injectable({
    providedIn: 'root'
  })
  export class RefreshAuthDataGQL extends Apollo.Query<Operations.RefreshAuthDataQuery, Operations.RefreshAuthDataQueryVariables> {
    document = Operations.RefreshAuthDataDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }

  @Injectable({
    providedIn: 'root'
  })
  export class OnboardArtistGQL extends Apollo.Mutation<Operations.OnboardArtistMutation, Operations.OnboardArtistMutationVariables> {
    document = Operations.OnboardArtistDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }

  @Injectable({
    providedIn: 'root'
  })
  export class CreateStripeLoginLinkGQL extends Apollo.Mutation<Operations.CreateStripeLoginLinkMutation, Operations.CreateStripeLoginLinkMutationVariables> {
    document = Operations.CreateStripeLoginLinkDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }

  @Injectable({
    providedIn: 'root'
  })
  export class IsStripeAccountVerifiedGQL extends Apollo.Query<Operations.IsStripeAccountVerifiedQuery, Operations.IsStripeAccountVerifiedQueryVariables> {
    document = Operations.IsStripeAccountVerifiedDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }

  @Injectable({
    providedIn: 'root'
  })
  export class GetTagsGQL extends Apollo.Query<Operations.GetTagsQuery, Operations.GetTagsQueryVariables> {
    document = Operations.GetTagsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }

  @Injectable({
    providedIn: 'root'
  })
  export class CreateTrackGQL extends Apollo.Mutation<Operations.CreateTrackMutation, Operations.CreateTrackMutationVariables> {
    document = Operations.CreateTrackDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }

  @Injectable({
    providedIn: 'root'
  })
  export class FindTrackByIdentifierGQL extends Apollo.Query<Operations.FindTrackByIdentifierQuery, Operations.FindTrackByIdentifierQueryVariables> {
    document = Operations.FindTrackByIdentifierDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }

  @Injectable({
    providedIn: 'root'
  })
  export class GetMyUploadedTracksGQL extends Apollo.Query<Operations.GetMyUploadedTracksQuery, Operations.GetMyUploadedTracksQueryVariables> {
    document = Operations.GetMyUploadedTracksDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }

  @Injectable({
    providedIn: 'root'
  })
  export class FindPublishedTracksByUserGQL extends Apollo.Query<Operations.FindPublishedTracksByUserQuery, Operations.FindPublishedTracksByUserQueryVariables> {
    document = Operations.FindPublishedTracksByUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }

  @Injectable({
    providedIn: 'root'
  })
  export class GetPublishedTracksGQL extends Apollo.Query<Operations.GetPublishedTracksQuery, Operations.GetPublishedTracksQueryVariables> {
    document = Operations.GetPublishedTracksDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateTrackGQL extends Apollo.Mutation<Operations.UpdateTrackMutation, Operations.UpdateTrackMutationVariables> {
    document = Operations.UpdateTrackDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }

  @Injectable({
    providedIn: 'root'
  })
  export class IncrementPlayCountGQL extends Apollo.Mutation<Operations.IncrementPlayCountMutation, Operations.IncrementPlayCountMutationVariables> {
    document = Operations.IncrementPlayCountDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }

  @Injectable({
    providedIn: 'root'
  })
  export class GetMyFavoriteTracksGQL extends Apollo.Query<Operations.GetMyFavoriteTracksQuery, Operations.GetMyFavoriteTracksQueryVariables> {
    document = Operations.GetMyFavoriteTracksDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }

  @Injectable({
    providedIn: 'root'
  })
  export class PurchaseLicenseGQL extends Apollo.Mutation<Operations.PurchaseLicenseMutation, Operations.PurchaseLicenseMutationVariables> {
    document = Operations.PurchaseLicenseDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }

  @Injectable({
    providedIn: 'root'
  })
  export class GetMyPurchasedTracksGQL extends Apollo.Query<Operations.GetMyPurchasedTracksQuery, Operations.GetMyPurchasedTracksQueryVariables> {
    document = Operations.GetMyPurchasedTracksDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateTrackStatusGQL extends Apollo.Mutation<Operations.UpdateTrackStatusMutation, Operations.UpdateTrackStatusMutationVariables> {
    document = Operations.UpdateTrackStatusDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteTrackGQL extends Apollo.Mutation<Operations.DeleteTrackMutation, Operations.DeleteTrackMutationVariables> {
    document = Operations.DeleteTrackDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }

  @Injectable({
    providedIn: 'root'
  })
  export class AddOrRemoveFavoriteGQL extends Apollo.Mutation<Operations.AddOrRemoveFavoriteMutation, Operations.AddOrRemoveFavoriteMutationVariables> {
    document = Operations.AddOrRemoveFavoriteDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }

  @Injectable({
    providedIn: 'root'
  })
  export class GetPresignedUrlGQL extends Apollo.Mutation<Operations.GetPresignedUrlMutation, Operations.GetPresignedUrlMutationVariables> {
    document = Operations.GetPresignedUrlDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }

  @Injectable({
    providedIn: 'root'
  })
  export class UserByEmailGQL extends Apollo.Query<Operations.UserByEmailQuery, Operations.UserByEmailQueryVariables> {
    document = Operations.UserByEmailDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }

  @Injectable({
    providedIn: 'root'
  })
  export class UserByIdentifierGQL extends Apollo.Query<Operations.UserByIdentifierQuery, Operations.UserByIdentifierQueryVariables> {
    document = Operations.UserByIdentifierDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateOneUserGQL extends Apollo.Mutation<Operations.UpdateOneUserMutation, Operations.UpdateOneUserMutationVariables> {
    document = Operations.UpdateOneUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }

  @Injectable({
    providedIn: 'root'
  })
  export class GetUsersByRolesGQL extends Apollo.Query<Operations.GetUsersByRolesQuery, Operations.GetUsersByRolesQueryVariables> {
    document = Operations.GetUsersByRolesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }

  @Injectable({
    providedIn: 'root'
  })
  export class SearchArtistsGQL extends Apollo.Query<Operations.SearchArtistsQuery, Operations.SearchArtistsQueryVariables> {
    document = Operations.SearchArtistsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }