import { Injectable, inject } from '@angular/core';
import {
  AudioChannel,
  CreateAudioChannelResponseDto,
} from '@pallotone/data-access';
import {
  CreateAudioChannelGQL,
  GetDownloadUrlGQL,
  GetListenUrlGQL,
  StartTranscodingGQL,
  UpdateAudioChannelGQL,
} from '@pallotone/data-access-ng';
import { map, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AudioChannelService {
  private createAudioChannelGql = inject(CreateAudioChannelGQL);
  private updateAudioChannelGql = inject(UpdateAudioChannelGQL);
  private startTranscodingGql = inject(StartTranscodingGQL);
  private getListenUrlGql = inject(GetListenUrlGQL);
  private getDownloadUrlGql = inject(GetDownloadUrlGQL);

  createAudioChannel(
    trackId: string,
    label: string,
    order: number,
    originalFilename: string,
  ): Observable<CreateAudioChannelResponseDto> {
    return this.createAudioChannelGql
      .mutate({
        trackId,
        label,
        order,
        originalFilename,
      })
      .pipe(
        map((result) => {
          return result.data.createAudioChannel;
        }),
      );
  }

  updateAudioChannel(
    audioChannelId: string,
    label: string,
    order: number,
  ): Observable<AudioChannel> {
    return this.updateAudioChannelGql
      .mutate({
        audioChannelId,
        label,
        order,
      })
      .pipe(
        map((result) => {
          return result.data.updateAudioChannel;
        }),
      );
  }

  startTranscoding(audioChannelId: string): Observable<AudioChannel> {
    return this.startTranscodingGql
      .mutate({
        audioChannelId,
      })
      .pipe(
        map((result) => {
          return result.data.startTranscoding;
        }),
      );
  }

  getListenUrl(audioChannelId: string): Observable<string> {
    return this.getListenUrlGql
      .fetch({
        audioChannelId,
      })
      .pipe(
        map((result) => {
          const res = result.data.getListenUrl;
          return res;
        }),
      );
  }

  getDownloadUrl(audioChannelId: string): Observable<string> {
    return this.getDownloadUrlGql
      .fetch({
        audioChannelId,
      })
      .pipe(
        map((result) => {
          const res = result.data.getDownloadUrl;
          return res;
        }),
      );
  }
}
