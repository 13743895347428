import { Record } from 'immutable';
import { RepeatState } from './repeat-state.enum';

export class PlayerSettings extends Record({
  volume: 1,
  mute: false,
  repeat: RepeatState.none,
}) {
  volume: number;
  mute: boolean;
  repeat: RepeatState;
}
