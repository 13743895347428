import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  Renderer2,
  inject,
} from '@angular/core';
import { getImageResizeUrl } from '../../../core/utils/image-resize-url.utils';

@Directive({
  selector: '[ptoneImage]',
  standalone: true,
})
export class ResizableImageDirective implements OnChanges {
  private Renderer = inject(Renderer2);
  private el = inject(ElementRef);

  @Input() public ptoneImageUrl: string;
  @Input() public ptoneImageDefault: string;
  @Input() public ptoneImageSize: string;
  @Input() public ptoneImageFallback: boolean;

  private dataUrlRegex =
    /^\s*data:([a-z]+\/[a-z]+(;[a-z\-]+\=[a-z\-]+)?)?(;base64)?,[a-z0-9\!\$\&\'\,\(\)\*\+\,\;\=\-\.\_\~\:\@\/\?\%\s]*\s*$/i;
  private ptoneImgRegex = /^https:\/\/.*ptoneimg[.]com.*$/;

  ngOnChanges(): void {
    let finalUrl: string;
    if (this.ptoneImageUrl) {
      finalUrl = this.ptoneImageUrl;
      if (
        !this.isDataURL(this.ptoneImageUrl) &&
        this.isPtoneImgUrl(this.ptoneImageUrl) &&
        this.ptoneImageSize
      ) {
        finalUrl = getImageResizeUrl(finalUrl, this.ptoneImageSize);
      }
    } else if (this.ptoneImageDefault) {
      finalUrl = this.ptoneImageDefault;
    }
    if (finalUrl) {
      this.Renderer.setStyle(
        this.el.nativeElement,
        'background',
        (finalUrl.indexOf('#') === 0 || finalUrl === 'transparent'
          ? finalUrl
          : `url(${finalUrl}) center`) +
          (this.ptoneImageDefault && this.ptoneImageFallback
            ? `, url(${this.ptoneImageDefault})`
            : ''),
      );
    }
  }

  isDataURL(s) {
    return this.dataUrlRegex.test(s);
  }

  isPtoneImgUrl(s) {
    return this.ptoneImgRegex.test(s);
  }
}
