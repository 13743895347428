import { Injectable, inject } from '@angular/core';
import {
  AddOrRemoveFavoriteGQL,
  CreateTrackGQL,
  DeleteTrackGQL,
  FindPublishedTracksByUserGQL,
  FindTrackByIdentifierGQL,
  GetMyFavoriteTracksGQL,
  GetMyPurchasedTracksGQL,
  GetMyUploadedTracksGQL,
  GetPublishedTracksGQL,
  IncrementPlayCountGQL,
  PurchaseLicenseGQL,
  UpdateTrackGQL,
  UpdateTrackStatusGQL,
} from '@pallotone/data-access-ng';
import { EMPTY, Observable, catchError, map, tap } from 'rxjs';
import { TrackEntity } from '../../models/track-entity.model';
import { TrackDataService } from '../track-data/track-data.service';
import {
  LicenseType,
  PaginatedTracksResponse,
  TrackStatus,
  UpdateTrackMutationVariables,
} from '@pallotone/data-access';
import {
  removeTrackFromCache,
  updateFavoritesCache,
  updateTrackStatusCache,
} from '../utils/apollo-cache.utils';
import { Apollo } from 'apollo-angular';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class TrackService {
  private apollo = inject(Apollo);
  private trackDataService = inject(TrackDataService);
  private createTrackGql = inject(CreateTrackGQL);
  private getMyUploadedTracksGql = inject(GetMyUploadedTracksGQL);
  private findTrackByIdentifierGql = inject(FindTrackByIdentifierGQL);
  private findPublishedTracksByUserGql = inject(FindPublishedTracksByUserGQL);
  private getPublishedTracksGql = inject(GetPublishedTracksGQL);
  private updateTrackGql = inject(UpdateTrackGQL);
  private incrementPlayCountGql = inject(IncrementPlayCountGQL);
  private getMyFavoriteTracksGql = inject(GetMyFavoriteTracksGQL);
  private purchaseLicenseGql = inject(PurchaseLicenseGQL);
  private getMyPurchasedTracksGql = inject(GetMyPurchasedTracksGQL);
  private updateTrackStatusGql = inject(UpdateTrackStatusGQL);
  private deleteTrackGql = inject(DeleteTrackGQL);
  private addOrRemoveFavoriteGql = inject(AddOrRemoveFavoriteGQL);
  private router = inject(Router);

  getApolloClient() {
    return this.apollo.client;
  }

  getApolloCache() {
    const cache = this.apollo.client.cache.extract();
    return cache;
  }

  createTrack(title: string): Observable<TrackEntity> {
    return this.createTrackGql.mutate({ title }).pipe(
      map((result) => {
        return result.data.createTrack as TrackEntity;
      }),
    );
  }

  findTrackByIdentifier(identifier: string): Observable<TrackEntity> {
    return this.findTrackByIdentifierGql.fetch({ identifier }).pipe(
      map((result) => {
        const track = new TrackEntity(result.data.findTrackByIdentifier);
        this.trackDataService.processTrack(track);
        return track;
      }),
    );
  }

  getMyUploadedTracks(): Observable<TrackEntity[]> {
    return this.getMyUploadedTracksGql.fetch().pipe(
      map((result) => {
        const tracks = result.data.getMyUploadedTracks.map((trackData) => {
          const track = new TrackEntity(trackData);
          this.trackDataService.processTrack(track);
          return track;
        });
        return tracks;
      }),
    );
  }

  findPublishedTracksByUser(username: string): Observable<TrackEntity[]> {
    return this.findPublishedTracksByUserGql.fetch({ username }).pipe(
      map((result) => {
        const tracks = result.data.findPublishedTracksByUser.map(
          (trackData) => {
            const track = new TrackEntity(trackData);
            this.trackDataService.processTrack(track);
            return track;
          },
        );
        return tracks;
      }),
    );
  }

  getPublishedTracks(
    page: number,
    limit: number,
    genres: string[] = [],
    instruments: string[] = [],
    moods: string[] = [],
    isFree?: boolean,
    isExclusiveOnly?: boolean,
  ): Observable<PaginatedTracksResponse> {
    return this.getPublishedTracksGql
      .fetch({
        page,
        limit,
        genres,
        instruments,
        moods,
        isFree,
        isExclusiveOnly,
      })
      .pipe(
        map((result) => {
          const tracks = result.data.getPublishedTracks.tracks.map(
            (trackData) => {
              const track = new TrackEntity(trackData);
              this.trackDataService.processTrack(track);
              return track;
            },
          );
          return {
            ...result.data.getPublishedTracks,
            tracks,
          };
        }),
      );
  }

  updateTrack(
    trackId: string,
    updateInput: UpdateTrackMutationVariables['updateInput'],
  ): Observable<TrackEntity> {
    return this.updateTrackGql
      .mutate({
        trackId,
        updateInput,
      })
      .pipe(
        map((result) => {
          const track = new TrackEntity(result.data.updateTrack);
          this.trackDataService.processTrack(track);
          return track;
        }),
      );
  }

  incrementPlayCount(trackId: string): Observable<number> {
    return this.incrementPlayCountGql
      .mutate({
        trackId,
      })
      .pipe(map((result) => result.data.incrementPlayCount));
  }

  getMyFavoriteTracks(): Observable<TrackEntity[]> {
    return this.getMyFavoriteTracksGql.fetch().pipe(
      map((result) => {
        const tracks = result.data.getMyFavoriteTracks.map((trackData) => {
          const track = new TrackEntity(trackData);
          this.trackDataService.processTrack(track);
          return track;
        });
        return tracks;
      }),
    );
  }

  purchaseLicense(
    trackId: string,
    licenseType: LicenseType,
    paymentMethodId: string,
  ): Observable<boolean> {
    return this.purchaseLicenseGql
      .mutate({
        input: {
          trackId,
          licenseType,
          paymentMethodId,
        },
      })
      .pipe(map((result) => result.data?.purchaseLicense.success));
  }

  getMyPurchasedTracks(): Observable<TrackEntity[]> {
    return this.getMyPurchasedTracksGql.fetch().pipe(
      map((result) => {
        return result.data.getMyPurchasedTracks.map((purchasedTrack) => {
          const track = new TrackEntity(purchasedTrack);
          this.trackDataService.processTrack(track);
          return track;
        });
      }),
    );
  }

  updateTrackStatus(
    trackId: string,
    status: TrackStatus,
  ): Observable<TrackStatus> {
    return this.updateTrackStatusGql
      .mutate({
        trackId,
        status,
      })
      .pipe(
        tap((result) => {
          const trackStatus = result.data?.updateTrackStatus.status;
          if (trackStatus) {
            updateTrackStatusCache(
              this.getApolloClient(),
              trackId,
              trackStatus,
            );
          }
        }),
        map((result) => {
          return result.data.updateTrackStatus.status;
        }),
      );
  }

  deleteTrack(trackId: string): Observable<boolean> {
    return this.deleteTrackGql
      .mutate({
        trackId,
      })
      .pipe(
        tap((result) => {
          if (result.data.deleteTrack) {
            removeTrackFromCache(this.getApolloClient(), trackId);
          }
        }),
        map((result) => result.data.deleteTrack),
      );
  }

  addOrRemoveFavorite(trackId: string): Observable<boolean> {
    return this.addOrRemoveFavoriteGql
      .mutate({
        trackId,
      })
      .pipe(
        tap((result) => {
          const likedByUser = result.data?.addOrRemoveFavorite ? true : false;
          updateFavoritesCache(this.getApolloClient(), trackId, likedByUser);
        }),
        map((result) => (result.data?.addOrRemoveFavorite ? true : false)),
        catchError((error) => {
          if (error.message.includes('Unauthorized')) {
            this.router.navigateByUrl('login');
          }
          return EMPTY;
        }),
      );
  }
}
