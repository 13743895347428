import { UploadFile } from './upload-file';
import { AudioProfile } from '../../models/audio-profile.model';
import { first } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AudioProfileUpload } from '../../models/audio-profile-upload.model';
import { UploadManagerService } from './upload.service';
import { FileItem } from 'ng2-file-upload';
import { UploadType } from '@pallotone/data-access';

export class AudioUploadFile extends UploadFile {
  private static audioFormats =
    /^(audio\/mpeg|audio\/x-mpeg|audio\/mp4|audio\/mp3|audio\/aac|audio\/x-m4a|audio\/m4a|audio\/flac|audio\/mpeg3|audio\/x-mpeg-3|audio\/ogg|audio\/webm|audio\/wav|audio\/x-wav|audio\/aiff|audio\/x-aiff)$/;

  audioProfileId: number;
  shouldUpload: boolean;
  previousProgress: number;

  private uploadCompleteSource = new Subject<boolean>(); // [success, audioProfileId]
  uploadCompleteWatch$ = this.uploadCompleteSource.asObservable();

  private uploadProgressSource = new Subject<[number, number, number]>(); // [previousProgress, currentProgress, audioProfileId]
  uploadProgressWatch$ = this.uploadProgressSource.asObservable();

  static ShouldAllowFile(fileItem: FileItem): [boolean, string] {
    console.log('file type', fileItem.file.type);
    if (this.audioFormats.test(fileItem.file.type)) {
      return [true, ''];
    }
    return [false, 'This file type is not supported. Please try again.'];
  }

  setup(
    type: UploadType,
    manager: UploadManagerService,
    fileName: string,
    uploadItem: FileItem,
  ) {
    super.setup(type, manager, fileName, uploadItem);
    return this;
  }

  upload() {
    this.uploadProgressSource.next([0, 0, this.audioProfileId]);
    this.manager.pallotoneApiService
      .uploadAudioProfile(this.audioProfileId)
      .pipe(first())
      .subscribe(
        (res: AudioProfileUpload) => {
          this.setAudioProfileUpload(res);
        },
        (error) => {
          this.errorSource.next(error);
        },
      );
  }

  cancel() {
    this.uploadItem.cancel();
  }

  remove() {
    this.uploadItem.remove();
  }

  setAudioProfileId(audioProfileId: number) {
    this.audioProfileId = audioProfileId;
  }

  setAudioProfileUpload(audioProfileUpload: AudioProfileUpload) {
    this.presignedUrl = audioProfileUpload.upload_url;
    this.splitPresignedUrl = this.splitUrl(this.presignedUrl);
    this.awsFileName = audioProfileUpload.master_key;
    this.awsParams = this.URLToArray(this.presignedUrl);
    audioProfileUpload.master_format = this.uploadItem.file.type;
    audioProfileUpload.master_original_filename = this.uploadItem.file.name;
    this.uploadItem.method = 'PUT';
    this.uploadItem.url = this.presignedUrl;
    this.uploadItem.onSuccess = (response) => {
      console.log('uploadComplete!');
      delete audioProfileUpload.id;
      delete audioProfileUpload.upload_url;
      this.manager.pallotoneApiService
        .updateAudioProfile(this.audioProfileId, audioProfileUpload)
        .pipe(first())
        .subscribe(
          (audioProfile: AudioProfile) => {
            this.uploadComplete = true;
            this.uploadCompleteSource.next(true);
            this.uploadProgressSource.complete();
            this.uploadCompleteSource.complete();
          },
          (error: any) => {
            this.uploadStarted = false;
            this.uploadCompleteSource.next(false);
            this.uploadProgressSource.complete();
            this.uploadCompleteSource.complete();
            this.errorSource.next(error);
          },
        );
      // let result = new UploadResult(this.saveUrl, this.uploadType);
    };
    this.uploadItem.onError = (err: string) => {
      this.uploadStarted = false;
      this.uploadCompleteSource.next(false);
      this.uploadProgressSource.complete();
      this.uploadCompleteSource.complete();
    };
    this.uploadItem.onProgress = (value: number) => {
      // console.log('progress', this.audioProfileId, value);
      this.uploadProgressSource.next([
        this.previousProgress,
        value,
        this.audioProfileId,
      ]);
      this.previousProgress = value;
    };
    this.uploadItem.upload();
  }
}
