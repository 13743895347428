import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeString',
  standalone: true,
})
export class TimeStringPipe implements PipeTransform {
  transform(seconds: number, args?: any): any {
    return new Date(seconds * 1000).toUTCString().match(/\d\d:\d(\d:\d\d)/)[1];
  }
}
