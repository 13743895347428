import { ApolloClient, Reference, gql } from '@apollo/client/core';

export const updateFavoritesCache = (
  apolloClient: ApolloClient<any>,
  trackId: string,
  likedByUser: boolean,
): void => {
  const trackRef = { __typename: 'Track', id: trackId };

  apolloClient.cache.modify({
    id: apolloClient.cache.identify(trackRef),
    fields: {
      likedByUser() {
        return likedByUser;
      },
    },
  });

  apolloClient.cache.modify({
    fields: {
      getMyFavoriteTracks(existingTrackRefs: Reference[] = [], { readField }) {
        const trackIndex = existingTrackRefs.findIndex(
          (ref) => readField<string>('id', ref) === trackId,
        );
        if (likedByUser && trackIndex === -1) {
          return [
            ...existingTrackRefs,
            apolloClient.cache.writeFragment({
              data: { __typename: 'Track', id: trackId, likedByUser },
              fragment: gql`
                fragment NewFavorite on Track {
                  id
                  likedByUser
                }
              `,
            }),
          ];
        } else if (!likedByUser && trackIndex !== -1) {
          return existingTrackRefs.filter(
            (ref) => readField<string>('id', ref) !== trackId,
          );
        }
        return existingTrackRefs;
      },
    },
  });
};

export const updateTrackStatusCache = (
  apolloClient: ApolloClient<any>,
  trackId: string,
  status: string,
): void => {
  const trackRef = { __typename: 'TrackEntity', id: trackId };

  apolloClient.cache.modify({
    id: apolloClient.cache.identify(trackRef),
    fields: {
      status() {
        return status;
      },
    },
  });
};

export const removeTrackFromCache = (
  apolloClient: ApolloClient<any>,
  trackId: string,
): void => {
  const trackRef = { __typename: 'TrackEntity', id: trackId };

  apolloClient.cache.evict({ id: apolloClient.cache.identify(trackRef) });
  apolloClient.cache.gc();

  apolloClient.cache.modify({
    fields: {
      getMyUploadedTracks(existingTrackRefs: Reference[] = [], { readField }) {
        return existingTrackRefs.filter(
          (trackRef) => trackId !== readField('id', trackRef),
        );
      },
      getPublishedTracks(existingTrackRefs: Reference[] = [], { readField }) {
        return existingTrackRefs.filter(
          (trackRef) => trackId !== readField('id', trackRef),
        );
      },
      getMyFavoriteTracks(existingTrackRefs: Reference[] = [], { readField }) {
        return existingTrackRefs.filter(
          (trackRef) => trackId !== readField('id', trackRef),
        );
      },
    },
  });
};
