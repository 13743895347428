import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, map, take } from 'rxjs';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  private authService = inject(AuthService);
  private router = inject(Router);

  canActivate(): Observable<boolean> {
    return this.authService.authDto$.pipe(
      take(1),
      map((authDto) => {
        if (!authDto) {
          this.router.navigate(['/signin']);
          return false;
        }
        return true;
      }),
    );
  }

  canLoad(): Observable<boolean> {
    return this.authService.authDto$.pipe(
      take(1),
      map((authDto) => {
        const isAuthenticated = !!authDto;
        if (!isAuthenticated) {
          this.router.navigate(['/signin']);
          return false;
        }
        return true;
      }),
    );
  }
}
