import { Injectable, inject } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { Observable, map, take } from 'rxjs';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { UserRole } from '@pallotone/data-access';

@Injectable({
  providedIn: 'root',
})
export class ArtistGuard {
  private authService = inject(AuthService);
  private router = inject(Router);

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.authService.authDto$.pipe(
      take(1),
      map((authDto) => {
        const isArtist = authDto && authDto.roles.includes(UserRole.Artist);
        if (isArtist) {
          return true;
        } else {
          const redirectTo = route.data['redirectTo'] || '/';
          this.router.navigate([redirectTo]);
          return false;
        }
      }),
    );
  }
}
