<div class="dialog-container">
  <div class="header">Keyboard Shortcuts</div>
  <dl>
    <div class="dl-item">
      <dt>Toggle play/pause</dt>
      <dd>SPACEBAR</dd>
    </div>
    <div class="dl-item">
      <dt>Rewind 5 seconds</dt>
      <dd>
        <mat-icon class="size-20">arrow_back</mat-icon>
      </dd>
    </div>
    <div class="dl-item">
      <dt>Skip 5 seconds</dt>
      <dd>
        <mat-icon class="size-20">arrow_forward</mat-icon>
      </dd>
    </div>
    <div class="dl-item">
      <dt>Previous channel</dt>
      <dd>
        <mat-icon class="size-20">arrow_upward</mat-icon>
      </dd>
    </div>
    <div class="dl-item">
      <dt>Next channel</dt>
      <dd>
        <mat-icon class="size-20">arrow_downward</mat-icon>
      </dd>
    </div>
    <div class="dl-item">
      <dt>Go to beginning</dt>
      <dd>a</dd>
    </div>
    <div class="dl-item">
      <dt>Seek to position (3 goes to 30%)</dt>
      <dd>0...9</dd>
    </div>
    <div class="dl-item">
      <dt>Toggle mute</dt>
      <dd>m</dd>
    </div>
    <!--    <div class="dl-item">-->
    <!--      <dt>Increase master volume</dt>-->
    <!--      <dd>-->
    <!--        SHIFT&nbsp;-->
    <!--        <mat-icon class="size-20">arrow_upward</mat-icon>-->
    <!--      </dd>-->
    <!--    </div>-->
    <!--    <div class="dl-item">-->
    <!--      <dt>Decrease master volume</dt>-->
    <!--      <dd>-->
    <!--        SHIFT&nbsp;-->
    <!--        <mat-icon class="size-20">arrow_downward</mat-icon>-->
    <!--      </dd>-->
    <!--    </div>-->
  </dl>
  <button
    mat-icon-button
    class="close-dialog-button"
    (click)="dialogRef.close()"
  >
    <mat-icon>close</mat-icon>
  </button>
</div>
