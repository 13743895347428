import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfirmDialog } from '../../core-ui/dialogs/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';

export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable()
export class PendingChangesGuard {
  private matDialog = inject(MatDialog);

  canDeactivate(
    component: ComponentCanDeactivate,
  ): boolean | Observable<boolean> {
    // if there are no pending changes, just allow deactivation; else confirm first
    return component.canDeactivate()
      ? true
      : // NOTE: this warning message will only be shown when navigating elsewhere within your angular app;
        // when navigating away from your angular app, the browser will show a generic warning message
        // see http://stackoverflow.com/a/42207299/7307355
        ConfirmDialog.showConfirmDialog(
          this.matDialog,
          'Discard changes?',
          'Any unsaved changes will be discarded.',
          'DISCARD',
          'CANCEL',
        ).afterClosed();
  }
}
