import { GenesisMixer } from './mixer';
import { EventNames, GenesisEvents } from './events';

export class GenesisTrack {
  name: string;
  mix: GenesisMixer;
  options: TrackOptions;
  gain: number;
  isMuted: boolean;
  isSolo: boolean;
  cachedTime: number;
  startTime: number;
  cachedGain: number;
  firstFragLoaded: boolean = false;
  destroyed: boolean = false;

  source: AudioBufferSourceNode | MediaElementAudioSourceNode;
  bufferSource: AudioBufferSourceNode;
  audioData;
  buffer;
  gainNode: GainNode;
  analyserNode: AnalyserNode;
  lastNode: AudioNode;
  onendtimer: any;
  events: GenesisEvents;

  downloadWorker: Worker;

  vuSupport: VUSupport;
  iOSDevice =
    !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

  shouldPlay: boolean = false;
  status = {
    loaded: false, // media is loaded
    ready: false, // nodes are created, we’re ready to play
    playing: false, // currently playing
    paused: true, // currently paused
    ended: false,
    error: undefined,
  };

  constructor(name: string, mix: GenesisMixer, options: TrackOptions) {
    this.name = name;
    this.mix = mix;
    this.options = options;
    this.events = new GenesisEvents();

    this.processOptions(options);

    if (options.stream) {
      this.initializeElement();
    } else {
      this.initializeBuffer();
    }
  }

  processOptions(options: TrackOptions) {
    this.gain = options.gain;
    this.isMuted = options.mute;
  }

  initializeBuffer() {
    if (this.downloadWorker === undefined) {
      this.downloadWorker = new Worker(
        '/assets/scripts/audio-download-worker.js',
      );
    }
    this.downloadWorker.postMessage(this.options.source);
    this.downloadWorker.onmessage = (e) => {
      // this.downloadWorker = undefined;
      let result = e.data;
      if (!result.error && result.readyState === 4 && !this.destroyed) {
        if (
          result.status === 200 ||
          result.status === 206 ||
          result.status === 304
        ) {
          // 200 -> success
          // debug.log(2, '"' + name + '" loaded "' +this.options.source + '"');
          this.audioData = result.response; // cache the audio data
          this.mix.context.decodeAudioData(this.audioData, (decodedBuffer) => {
            this.buffer = decodedBuffer;
            // if (this.buffer) {
            //   this.source = this.mix.context.createBufferSource();
            //   this.bufferSource = (this.source as AudioBufferSourceNode);
            //   this.bufferSource.buffer = this.buffer;
            //   // resolve();
            // }
            if (!this.destroyed) {
              this.ready();
            }
            // this.status.loaded = true;
            // this.events.trigger('load', this);
          });
        } else {
          // other -> failure
          // debug.log(1, 'couldn’t load track "' + name + '" with source "' +this.options.source + '"');
          this.status.error = { status: result.status };
          this.events.trigger('loadError', this);
        }
      }
    };
  }

  initializeElement() {
    let mediaElement: any = this.options.mediaElement
      ? this.options.mediaElement
      : new Audio(
          'data:audio/wav;base64,UklGRkYAAABXQVZFZm10IBAAAAABAAIAgLsAAADuAgAEABAATElTVBoAAABJTkZPSVNGVA4AAABMYXZmNTcuODMuMTAwAGRhdGEAAAAA',
        );
    mediaElement.crossOrigin = 'anonymous';
    mediaElement.muted = false;
    this.options.mediaElement = mediaElement;
    if (this.mix.requiresInstantPlay) {
      mediaElement.play().then(() => {
        mediaElement.pause();
        this.initializeElement2();
      });
    } else {
      this.initializeElement2();
    }
  }

  initializeElement2() {
    this.options.mediaElement.addEventListener(
      'canplaythrough',
      (ev: Event) => {
        if (!this.firstFragLoaded && !this.destroyed) {
          this.firstFragLoaded = true;
          // this.source = this.mix.context.createMediaElementSource(this.options.mediaElement);
          this.ready();
        }
      },
    );
    this.options.mediaElement.addEventListener(
      'ended',
      () => {
        this.events.trigger('ended', this);
      },
      false,
    );
    this.options.mediaElement.addEventListener(
      'loop',
      () => {
        this.events.trigger('loop', this);
      },
      false,
    );
    this.options.mediaElement.addEventListener(
      'pause',
      () => {
        this.events.trigger('pause', this);
      },
      false,
    );
    this.options.mediaElement.src = this.options.source;
    this.options.mediaElement.load();
  }

  ready() {
    if (!this.destroyed) {
      this.status.loaded = true;
      this.events.trigger('load', this);

      if (this.options.stream) {
        if (
          !this.iOSDevice &&
          this.options.canvases &&
          this.options.canvases.length > 0
        ) {
          this.source = this.mix.context.createMediaElementSource(
            this.options.mediaElement,
          );
          this.attachVUNodesOnly();
        }
        this.setGain(this.gain);
      }

      if (!this.options.stream && this.options.mediaElement) {
        this.source = this.mix.context.createMediaElementSource(
          this.options.mediaElement,
        );
        this.attachNodes();
      }

      this.status.ready = true;
      this.events.trigger('ready', this);

      if (this.options.autoplay || this.shouldPlay) {
        this.play();
      }
    }
    // else {
    //  this.options.mediaElement.pause();
    // }
  }

  detachNodes() {
    if (this.gainNode) {
      this.gainNode.disconnect();
    }
    if (this.analyserNode) {
      this.analyserNode.disconnect();
    }
  }

  // Connect AudioNodes
  attachNodes() {
    this.lastNode = this.source;
    if (this.options.canvases && this.options.canvases.length > 0) {
      this.createAnalyserNode();
      this.setupVuSupport();
    }
    this.createGainNode();
    this.connectDestination();
  }

  attachVUNodesOnly() {
    this.lastNode = this.source;
    if (this.options.canvases && this.options.canvases.length > 0) {
      this.createAnalyserNode();
      this.setupVuSupport();
    }
    this.createGainNode();
    this.connectDestination();
  }

  destroy() {
    this.destroyed = true;
    if (this.downloadWorker) {
      this.downloadWorker.postMessage(-1);
      this.downloadWorker.terminate();
    }
    if (!this.options.stream && !this.options.mediaElement) {
      // this.bufferSource.buffer.destroy();

      this.bufferSource = null;
      this.audioData = null;
      this.buffer = null;
    }
    if (this.options.mediaElement) {
      this.options.mediaElement.src = '';
    }
    if (this.source) {
      this.source.disconnect();
      this.source = null;
    }
    if (this.gainNode) {
      this.gainNode.disconnect();
      this.gainNode = null;
    }
    if (this.analyserNode) {
      this.analyserNode.disconnect();
      this.analyserNode = null;
    }
    if (this.vuSupport) {
      this.vuSupport.destroy();
      this.vuSupport = null;
    }
  }

  play(startPlayAt?: number): GenesisTrack {
    // console.time('play' + ranNum);
    // if track isn’t loaded yet, tell it to play when it loads
    if (!this.status.loaded) {
      this.shouldPlay = true;
      return this;
    }

    if (this.status.playing) {
      return this;
    }

    this.shouldPlay = false;

    if (!this.options.stream) {
      this.playBufferSource(startPlayAt);
    } else {
      this.playElementSource();
    }

    return this;
  }

  playBufferSource(startPlayAt?: number) {
    if (!this.status.playing) {
      if (this.buffer) {
        // this.detachNodes();
        this.source = this.mix.context.createBufferSource();
        this.bufferSource = this.source as AudioBufferSourceNode;
        this.bufferSource.buffer = this.buffer;
        this.attachNodes();
      } else {
        return;
      }
      // this.status.ready = true;
      // this.events.trigger('ready', this);

      // Play
      // ~~~~

      this.startTime = this.source.context.currentTime - (this.cachedTime || 0);
      let startFrom = this.cachedTime || 0;
      // debug.log(2, 'Playing track (buffer) "' + name + '" from ' + startFrom + ' (' + startTime + ') gain ' + gain());

      // prefer start() but fall back to deprecated noteOn()
      if (typeof this.bufferSource.start === 'function') {
        this.bufferSource.start(startPlayAt, startFrom);
        // console.timeEnd('play' + ranNum);
        // console.log('currentTime', mix.context.currentTime, 'startPlayAt', startPlayAt, 'startFrom', startFrom, 'startTime', startTime, 'cachedTime', cachedTime);
      } else {
        (this.source as any).noteOn(startFrom + 0.1);
      }

      // Apply Options
      this.bufferSource.loop = this.options.loop;
      // this.setGain(this.options.gain);
      this.setEndTimer();
      this.status.playing = true;
      this.events.trigger('play', this);
    }
  }

  playElementSource() {
    // unlike buffer mode, we only need to construct the nodes once
    // we’ll also take this opportunity to do event listeners

    // Apply Options
    // ~~~~~~~~~~~~~~

    if (this.options.loop) {
      this.options.mediaElement.loop = true;
    }

    // this.setGain(this.options.gain);

    // Start Time

    this.startTime =
      this.options.mediaElement.currentTime - (this.cachedTime || 0);

    this.options.mediaElement.currentTime = this.cachedTime || 0;
    this.options.mediaElement.play();

    this.status.playing = true;
    this.events.trigger('play', this);
  }

  pause(at?: number) {
    // turn off autoplay if we've paused the track before it manages to load
    if (!this.status.ready || !this.status.playing) {
      this.shouldPlay = false;
      this.options.autoplay = false;
      return this;
    }

    // cache time to resume from later
    if (at !== undefined) {
      this.cachedTime = at;
    } else {
      if (!this.options.stream && !this.options.mediaElement) {
        this.cachedTime =
          this.source.context.currentTime - (this.startTime || 0);
      } else {
        this.cachedTime = this.options.mediaElement.currentTime || 0;
      }
    }
    this.status.playing = false;

    if (this.onendtimer) {
      clearTimeout(this.onendtimer as unknown as number);
    }
    if (!this.options.stream && !this.options.mediaElement) {
      // prefer stop(), fallback to deprecated noteOff()
      if (typeof this.bufferSource.stop === 'function') {
        this.bufferSource.stop(0);
      } else if (typeof (this.source as any).noteOff === 'function') {
        (this.source as any).noteOff(0);
      }
    } else {
      if (!this.options.mediaElement.paused) {
        this.cachedTime = at !== undefined ? at : this.currentTime();
        this.options.mediaElement.pause();
      }
    }

    // debug.log(2, 'Pausing track "' + name + '" at ' + cachedTime);
    this.events.trigger('pause', this);

    return this;
  }

  stop() {
    if (!this.status.ready || !this.status.playing) {
      return this;
    }

    if (this.onendtimer) {
      clearTimeout(this.onendtimer as unknown as number);
    }

    this.cachedTime = 0;
    this.startTime = 0;

    if (!this.options.stream && !this.options.mediaElement) {
      // prefer stop(), fallback to deprecated noteOff()
      if (typeof this.bufferSource.stop === 'function') {
        this.bufferSource.stop(0);
      } else if (typeof (this.source as any).noteOff === 'function') {
        (this.source as any).noteOff(0);
      }
    } else {
      this.options.autoplay = false;
      this.options.mediaElement.pause();
      this.options.mediaElement.currentTime = 0;
    }

    this.status.playing = false;
    this.events.trigger('stop', this);

    // this.gain = this.options.gain;

    return this;
  }

  setCanvas(canvas: HTMLCanvasElement) {
    this.options.canvases = [canvas];
    if (this.vuSupport) {
      this.vuSupport.setCanvases([canvas]);
    }
  }

  mute(doMute?: boolean) {
    // console.log('mute', doMute, this.isMuted, this.cachedGain);
    if (doMute !== undefined) {
      if (doMute) {
        this.setGain(0);
      } else if (this.isMuted) {
        this.setGain(this.cachedGain);
      }
    } else {
      if (this.isMuted) {
        this.setGain(this.cachedGain);
      } else {
        this.setGain(0);
      }
    }
    // if ((doMute !== undefined && doMute) || !this.isMuted) {
    //   this.setGain(0);
    // } else {
    //   this.setGain(this.cachedGain);
    //   this.isMuted = false;
    // }
  }

  solo(doSolo: boolean) {
    this.isSolo = doSolo;
    let shouldSolo = false;
    for (let track of this.mix.tracks) {
      if (track.isSolo) {
        shouldSolo = true;
      }
    }
  }

  refreshGain() {
    this.setGain(this.gain);
  }

  setGain(gain: number = 1) {
    if (gain === 0 && !this.isMuted) {
      this.cachedGain = this.gain;
      this.isMuted = true;
    } else if (gain !== 0) {
      this.isMuted = false;
    }
    this.gain = gain;
    if (this.gainNode) {
      const newGainValue =
        gain * this.mix.gain * (this.mix.soloEffect && !this.isSolo ? 0 : 1);
      this.gainNode.gain.value = isFinite(newGainValue) ? newGainValue : 0;
      this.events.trigger('gain', this);
    } else if (this.options.stream) {
      this.options.mediaElement.volume = isFinite(gain * this.mix.gain)
        ? gain * this.mix.gain
        : 0;
    }
    return this;
  }

  currentTime(setTo?: number): number {
    if (!this.status.ready) {
      return 0;
    }
    if (setTo !== undefined) {
      if (this.status.playing) {
        if (this.options.stream) {
          this.options.mediaElement.currentTime = setTo;
        } else {
          this.pause(setTo);
          this.play();
        }
      } else {
        this.cachedTime = setTo;
      }
      return setTo;
    }
    if (!this.status.playing) {
      return this.cachedTime || 0;
    }
    if (!this.options.stream && !this.options.mediaElement) {
      return this.source.context.currentTime - (this.startTime || 0);
    } else {
      return this.options.mediaElement.currentTime || 0;
    }
  }

  duration() {
    if (!this.status.ready) {
      return 0;
    }
    if (!this.options.stream && !this.options.mediaElement) {
      return this.buffer.duration || 0;
    } else {
      return this.options.mediaElement.duration || 0;
    }
  }

  // fake ended event
  setEndTimer() {
    let startFrom = this.cachedTime || 0;
    let timerDuration = this.bufferSource.buffer.duration - startFrom;

    if (this.onendtimer) {
      clearTimeout(this.onendtimer);
    }

    this.onendtimer = setTimeout(() => {
      this.ended();
    }, timerDuration * 1000);
  }

  ended() {
    if (this.options.loop) {
      this.events.trigger('loop', this);
      this.pause(0);
      this.play();
    } else {
      // this.stop();
      this.events.trigger('ended', this);
    }
  }

  private createGainNode() {
    this.gainNode = this.mix.context.createGain();
    this.lastNode.connect(this.gainNode);
    this.lastNode = this.gainNode;
    this.setGain(this.gain);
  }

  private createAnalyserNode() {
    this.analyserNode = this.mix.context.createAnalyser();
    // this.analyserNode.fftSize = 1024;
    this.source.connect(this.analyserNode);
    // this.lastNode = this.analyserNode;
  }

  private connectDestination() {
    this.lastNode.connect(this.mix.context.destination);
  }

  private setupVuSupport() {
    if (!this.vuSupport) {
      this.vuSupport = new VUSupport(
        this.options.canvases,
        this.analyserNode,
        this.events,
      );
    } else {
      this.vuSupport.analyserNode = this.analyserNode;
    }
  }
}

export class TrackOptions {
  source: string;
  stream?: boolean = false;
  mediaElement?: HTMLMediaElement = undefined;
  gain?: number = 1;
  mute?: boolean = false;
  autoplay?: boolean = true;
  loop?: boolean = false;
  canvases?: HTMLCanvasElement[] = [];

  public constructor(init?: Partial<TrackOptions>) {
    Object.assign(this, init);
  }
}

export class CanvasSupport {
  element: HTMLCanvasElement;
  context: CanvasRenderingContext2D;
  fall: number = 1000;

  constructor(canvas: HTMLCanvasElement) {
    this.element = canvas;
    this.context = canvas.getContext('2d');
  }

  destroy() {
    this.context.clearRect(0, 0, this.element.width, this.element.height);
    this.context = null;
    this.element = null;
  }
}

export class VUSupport {
  analyserNode: AnalyserNode;
  bufferLength: number;
  dataArray: Uint8Array;
  canvas: CanvasSupport;
  canvases: CanvasSupport[];
  requestAnimation: number;
  events: GenesisEvents;

  private lastFrameTime = performance.now();
  private currentFrameTime = performance.now();
  private cancelAnimation: boolean = false;

  constructor(
    canvases: HTMLCanvasElement[],
    analyserNode: AnalyserNode,
    events: GenesisEvents,
  ) {
    this.setCanvases(canvases);
    this.analyserNode = analyserNode;
    this.events = events;
    this.analyserNode.fftSize = 2048;
    this.bufferLength = this.analyserNode.frequencyBinCount;
    this.dataArray = new Uint8Array(this.bufferLength);
    this.analyserNode.getByteTimeDomainData(this.dataArray);

    this.startVU = this.startVU.bind(this);
    this.drawVU = this.drawVU.bind(this);

    this.events.on(EventNames.play).subscribe(() => {
      this.startVU();
    });
    this.events.on(EventNames.pause).subscribe(() => {
      this.pauseVU();
    });
    this.events.on(EventNames.ended).subscribe(() => {
      this.stopVU();
    });
  }

  setCanvas(canvas: HTMLCanvasElement) {
    this.canvas = new CanvasSupport(canvas);
  }

  setCanvases(canvases: HTMLCanvasElement[]) {
    this.canvases = canvases.map((canvas) => new CanvasSupport(canvas));
  }

  startVU() {
    this.cancelAnimation = false;
    if (!this.requestAnimation) {
      this.drawVU();
    }
  }

  stopVU() {
    this.cancelAnimation = true;
    cancelAnimationFrame(this.requestAnimation);
    this.requestAnimation = undefined;
  }

  pauseVU() {
    this.cancelAnimation = true;
  }

  destroy() {
    this.stopVU();
    this.dataArray = null;
    this.analyserNode = null;
    this.events = null;
    this.canvases.forEach((canvas) => canvas.destroy());
    this.canvases = null;
  }

  checkFall(): boolean {
    if (this.canvases) {
      for (let canvas of this.canvases) {
        if (canvas.fall < canvas.element.height) {
          return true;
        }
      }
    }
    return false;
  }

  private drawVU() {
    this.lastFrameTime = this.currentFrameTime;
    this.currentFrameTime = performance.now();
    let delta = this.currentFrameTime - this.lastFrameTime;

    this.analyserNode.getByteTimeDomainData(this.dataArray);

    if (this.canvases && this.canvases.length) {
      this.canvases.forEach((canvas) => this.drawCanvas(canvas, delta));
    }

    if (!this.cancelAnimation || (this.cancelAnimation && this.checkFall())) {
      this.requestAnimation = requestAnimationFrame(this.drawVU);
    } else {
      this.requestAnimation = undefined;
    }
  }

  private drawCanvas(canvas: CanvasSupport, delta: number) {
    canvas.context.fillStyle = '#000000';
    canvas.context.fillRect(0, 0, canvas.element.width, canvas.element.height);
    canvas.context.fillStyle = 'rgb(234, 175, 56)';

    let lowestY: number = canvas.element.height;

    for (let i = 0; i < this.bufferLength; i++) {
      let v = this.dataArray[i] / 128.0;
      let y = Math.pow(v, 0.8) * (canvas.element.height + 10);

      if (i !== 0 && y < lowestY) {
        lowestY = y;
      }
    }

    let drawY: number = canvas.fall + 0.2 * delta;
    if (lowestY < drawY && !this.cancelAnimation) {
      drawY = lowestY;
      canvas.fall = lowestY;
    } else {
      canvas.fall += 0.2 * delta;
    }

    canvas.context.fillRect(
      0,
      drawY,
      canvas.element.width,
      canvas.element.height,
    );
  }
}
