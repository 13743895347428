import { Observable, Subject } from 'rxjs';
import { GenesisTrack } from './track';

export const EventNames = {
  load: 'load',
  loadError: 'loadError',
  play: 'play',
  pause: 'pause',
  ended: 'ended',
  error: 'error',
  ready: 'ready',
  loop: 'loop',
};

export class GenesisEvents {
  eventSources: Map<string, Subject<GenesisTrack>>;
  eventObservables: Map<string, Observable<GenesisTrack>>;

  constructor() {
    this.eventSources = new Map();
    this.eventObservables = new Map();
    for (let event in EventNames) {
      if (event in EventNames) {
        this.eventSources[event] = new Subject<GenesisTrack>();
        this.eventObservables[event] = this.eventSources[event].asObservable();
      }
    }
  }

  on(event: string): Observable<GenesisTrack> {
    if (event in this.eventObservables) {
      return this.eventObservables[event];
    } else {
      return undefined;
    }
  }

  trigger(event: string, data?: any) {
    if (event in this.eventSources) {
      this.eventSources[event].next(data);
    }
  }
}
