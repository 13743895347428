<div class="buttons-row">
  <button
    mat-stroked-button
    class="master-button"
    title="Master"
    (click)="
      !track.settings.stemsActive.get(0) ? togglePlay(track.id, $event, [0]) : 0
    "
    [ngClass]="{
      active:
        track.settings.stemsActive.get(0) &&
        !track.settings.stemsBuffering.get(0),
      buffering: track.settings.stemsBuffering.get(0)
    }"
  >
    <span class="button-text">Master</span>
    @if (track.settings.stemsBuffering.get(0)) {
    <mat-spinner diameter="16" class="inverted absolute-center"></mat-spinner>
    }
  </button>
  @if (track.audioChannels.length) {
  <div class="stem-buttons-group">
    @for (channel of track.audioChannels.slice(1); track $index) {
    <button
      mat-button
      class="stem-button truncate"
      [title]="channel.label || 'Stem ' + $index"
      (click)="
        !track.settings.stemsActive.get($index + 1)
          ? togglePlay(track.id, $event, [$index + 1])
          : 0
      "
      [ngClass]="{
        active:
          track.settings.stemsActive.get($index + 1) &&
          !track.settings.stemsBuffering.get($index + 1),
        buffering: track.settings.stemsBuffering.get($index + 1)
      }"
    >
      <span class="button-text truncate">{{ channel.label }}</span>
      @if (track.settings.stemsBuffering.get($index + 1)) {
      <mat-spinner diameter="16" class="inverted absolute-center"></mat-spinner>
      }
    </button>
    }
  </div>
  } @if (track.audioChannels.length === 0) {
  <div class="no-stems">No stems available</div>
  }
</div>
