import { FileItem } from 'ng2-file-upload';
import { UploadManagerService } from './upload.service';
import { UploadFileBuilder } from './upload-file.builder';
import { Subject } from 'rxjs';
import { UploadType } from '@pallotone/data-access';

export abstract class UploadFile {
  uploadItem: FileItem;
  uploadType: UploadType;
  uploadComplete: boolean = false;
  uploadStarted: boolean = false;
  fileName: string;
  identifier: any;

  policy: string;
  s3signature: string;
  presignedUrl: string;
  splitPresignedUrl: Array<string>;
  saveUrl: string;
  awsFileName: string;
  awsParams: any;

  manager: UploadManagerService;

  protected errorSource = new Subject<any>();
  errorWatch$ = this.errorSource.asObservable();

  static create<T extends UploadFile>(
    uploadFileType: UploadFileBuilder<T>,
    type: UploadType,
    manager: UploadManagerService,
    fileName: string,
    uploadItem: FileItem,
  ) {
    return new uploadFileType().setup(type, manager, fileName, uploadItem);
  }

  // Do not protect this, as that would not work with the generic types
  constructor() {}

  abstract upload();

  abstract cancel();

  abstract remove();

  setup(
    type: UploadType,
    manager: UploadManagerService,
    fileName: string,
    uploadItem: FileItem,
  ) {
    this.manager = manager;
    this.fileName = fileName;
    this.uploadItem = uploadItem;
    this.uploadType = type;
    return this;
  }

  protected URLToArray(url): any {
    let request = {};
    let pairs = url.substring(url.indexOf('?') + 1).split('&');
    for (let i = 0; i < pairs.length; i++) {
      if (!pairs[i]) {
        continue;
      }
      let pair = pairs[i].split('=');
      request[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
    }
    return request;
  }

  protected splitUrl(url): any {
    return url.split('/');
  }
}
