import { FileItem } from 'ng2-file-upload';
import { Observable } from 'rxjs';

export class ImageUtils {
  static resizeToDataUrl(
    uploadImage: FileItem,
    fileName: string,
    width: number,
    doQualityOnNoResize = false,
  ) {
    return new Promise((resolve, reject) => {
      let file = uploadImage._file;
      let myReader: FileReader = new FileReader();
      myReader.onloadend = (loadEvent: any) => {
        let img = new Image();
        img.onload = () => {
          if (img.width > width) {
            let cc: HTMLCanvasElement = document.createElement('canvas');
            cc.width = width;
            cc.height = (img.height / img.width) * width;
            console.log('resize new height', cc.height);
            let ctx = cc.getContext('2d');
            ctx.fillStyle = '#ffffff';
            ctx.fillRect(0, 0, cc.width, cc.height);
            ctx.drawImage(img, 0, 0, cc.width, cc.height);
            let dataUrl = cc.toDataURL('image/jpeg', 0.75);
            this.dataURLtoBlob(dataUrl).subscribe((blob: Blob) => {
              uploadImage._file = new File([blob], fileName, {
                type: 'image/jpeg',
              });
            });
            resolve(dataUrl);
          } else if (doQualityOnNoResize) {
            let cc: HTMLCanvasElement = document.createElement('canvas');
            cc.width = img.width;
            cc.height = img.height;
            let ctx = cc.getContext('2d');
            ctx.fillStyle = '#ffffff';
            ctx.fillRect(0, 0, cc.width, cc.height);
            ctx.drawImage(img, 0, 0, cc.width, cc.height);
            let dataUrl = cc.toDataURL('image/jpeg', 0.75);
            this.dataURLtoBlob(dataUrl).subscribe((blob: Blob) => {
              uploadImage._file = new File([blob], fileName, {
                type: 'image/jpeg',
              });
            });
            resolve(dataUrl);
          } else {
            resolve(loadEvent.target.result);
          }
        };
        img.src = loadEvent.target.result;
      };
      myReader.readAsDataURL(file);
    });
  }

  static dataURLtoBlob(dataURL): Observable<Blob> {
    return Observable.create((observer) => {
      // convert base64/URLEncoded data component to raw binary data held in a string
      let byteString;
      if (dataURL.split(',')[0].indexOf('base64') >= 0) {
        byteString = atob(dataURL.split(',')[1]);
      } else {
        byteString = unescape(dataURL.split(',')[1]);
      }

      // separate out the mime component
      let mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];

      // write the bytes of the string to a typed array
      let ia = new Uint8Array(byteString.length);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      observer.next(new Blob([ia], { type: mimeString }));
    });
  }
}
