import { Injectable } from '@angular/core';
import { PlayerSettings } from '../../models/player-settings.model';
import { BehaviorSubject } from 'rxjs';
import { RepeatState } from '../../models/repeat-state.enum';

@Injectable()
export class LocalSettingsService {
  private playerSettingsSource = new BehaviorSubject<PlayerSettings>(
    JSON.parse(localStorage.getItem('ptone_web4_player_settings')) ||
      new PlayerSettings(),
  );
  playerSettingsWatch$ = this.playerSettingsSource.asObservable();

  private playerSettings: PlayerSettings;
  private repeatCache: RepeatState;

  constructor() {
    this.playerSettings =
      new PlayerSettings(
        JSON.parse(localStorage.getItem('ptone_web4_player_settings')),
      ) || new PlayerSettings();
  }

  setPlayerSettings(playerSettings: PlayerSettings) {
    this.playerSettings = playerSettings;
    if (playerSettings) {
      localStorage.setItem(
        'ptone_web4_player_settings',
        JSON.stringify(playerSettings),
      );
    } else {
      localStorage.removeItem('ptone_web4_player_settings');
    }
    this.playerSettingsSource.next(playerSettings);
  }

  getPlayerSettings() {
    return this.playerSettings;
  }

  setPlayerVolume(volume: number) {
    this.setPlayerSettings(
      new PlayerSettings(
        this.playerSettings.set('volume', volume).set('mute', false),
      ),
    );
  }

  toggleMute() {
    this.setPlayerSettings(
      new PlayerSettings(
        this.playerSettings.set('mute', !this.playerSettings.mute),
      ),
    );
  }

  toggleRepeat(repeat: RepeatState) {
    if (repeat !== this.playerSettings.repeat) {
      this.repeatCache = this.playerSettings.repeat;
      this.setPlayerSettings(
        new PlayerSettings(this.playerSettings.set('repeat', repeat)),
      );
    }
  }

  setRepeatFromCache() {
    this.setPlayerSettings(
      new PlayerSettings(this.playerSettings.set('repeat', this.repeatCache)),
    );
  }
}
