import { Injectable, inject } from '@angular/core';
import { GenesisMixer } from '../genesis/mixer';
import { GenesisTrack, TrackOptions } from '../genesis/track';
import { PlayerState } from '../../models/player-state.enum';
import { TrackDataService } from '../track-data/track-data.service';
import { first } from 'rxjs/operators';
import { TrackSettingsService } from '../track-settings/track-settings.service';
import {
  TrackSettings,
  TrackSettingsRecord,
} from '../../models/track-settings.model';
import { EventNames } from '../genesis/events';
import { PlayerSettings } from '../../models/player-settings.model';
import { LocalSettingsService } from '../local-settings/local-settings.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { TrackState } from '../../models/track-state.enum';
import { TrackEntity } from '../../models/track-entity.model';
import { TrackService } from '../track/track.service';
import { AudioChannelService } from '../audio-channel/audio-channel.service';

@Injectable({
  providedIn: 'root',
})
export class RevelationService {
  private trackDataService = inject(TrackDataService);
  private trackSettingsService = inject(TrackSettingsService);
  private localSettingsService = inject(LocalSettingsService);
  private audioChannelService = inject(AudioChannelService);
  private trackService = inject(TrackService);

  private Mixer: GenesisMixer; // Main GenesisMixer for all playback
  private track: TrackEntity; // Current playing Track data
  private trackId: string; // Current playing track ID, same as track.id
  private trackSettingsSub: Subscription;
  // Tracks that are actively being played back by the Mixer
  private playingTracks: { [id: string]: GenesisTrack } = {};
  // Tracks that have been loaded and may or may not be attached to the mixer
  private loadedTracks: { [id: string]: GenesisTrack } = {};
  private playingChannels: number[] = []; // Ditto for indexes
  private loadedIndexes: number[] = []; // Ditto for indexes
  private longestPlayingTrack: GenesisTrack; // The GenesisTrack with the longest duration
  private state = PlayerState.no_track;
  private playerSettings: PlayerSettings;
  // Tracks that have completed loading, this is temp used when loading tracks
  private tracksDoneLoading: number = 0;
  private animationFrameId: number; // ID of the animation frame for progress updates
  private initialPlayed: boolean = false; // Has the player played at least once?
  private savedProgressMs: number; // Progress saved between stem changes
  private savedProgressPercent: number; // Progress saved without track loaded, percent
  private isStreaming: boolean = true;
  private unlocked: boolean = false;

  private dragging: boolean;

  private vuCanvases: Map<string, HTMLCanvasElement[]> = new Map();

  private activeTrackSource = new BehaviorSubject<TrackEntity>(undefined);
  activeTrack$ = this.activeTrackSource.asObservable();

  private trackProgressSource = new BehaviorSubject<[number, number]>(
    undefined,
  );
  trackProgress$ = this.trackProgressSource.asObservable();

  private playerStateSource = new BehaviorSubject<PlayerState>(
    PlayerState.no_track,
  );
  playerState$ = this.playerStateSource.asObservable();

  constructor() {
    window.addEventListener('keydown', (e) => {
      let focusElem: Element = document.activeElement;
      if (
        this.track &&
        !(
          focusElem.tagName === 'INPUT' ||
          focusElem.tagName === 'TEXTAREA' ||
          focusElem.hasAttribute('contenteditable')
        )
      ) {
        switch (e.key) {
          case ' ':
            e.preventDefault();
            this.togglePlay(this.track.id);
            break;
          case 'a':
            e.preventDefault();
            this.toTop();
            break;
          case 'ArrowLeft':
            e.preventDefault();
            this.backwardFiveSeconds();
            break;
          case 'ArrowRight':
            e.preventDefault();
            this.forwardFiveSeconds();
            break;
          case 'ArrowDown':
            e.preventDefault();
            if (!this.track.settings.investigating) {
              this.nextChannel();
            }
            break;
          case 'ArrowUp':
            e.preventDefault();
            if (!this.track.settings.investigating) {
              this.previousChannel();
            }
            break;
          case 'm':
            e.preventDefault();
            this.localSettingsService.toggleMute();
            break;
          case '0':
            e.preventDefault();
            this.setTrackTimePercent(0);
            break;
          case '1':
            e.preventDefault();
            this.setTrackTimePercent(0.1);
            break;
          case '2':
            e.preventDefault();
            this.setTrackTimePercent(0.2);
            break;
          case '3':
            e.preventDefault();
            this.setTrackTimePercent(0.3);
            break;
          case '4':
            e.preventDefault();
            this.setTrackTimePercent(0.4);
            break;
          case '5':
            e.preventDefault();
            this.setTrackTimePercent(0.5);
            break;
          case '6':
            e.preventDefault();
            this.setTrackTimePercent(0.6);
            break;
          case '7':
            e.preventDefault();
            this.setTrackTimePercent(0.7);
            break;
          case '8':
            e.preventDefault();
            this.setTrackTimePercent(0.8);
            break;
          case '9':
            e.preventDefault();
            this.setTrackTimePercent(0.9);
            break;
        }
      }
    });
    document.body.addEventListener(
      'touchmove',
      () => {
        this.dragging = true;
      },
      false,
    );
    document.body.addEventListener(
      'touchstart',
      () => {
        this.dragging = false;
      },
      false,
    );

    this.localSettingsService.playerSettingsWatch$.subscribe(
      (change: PlayerSettings) => {
        this.playerSettings = new PlayerSettings(change);
        // Watch for player volume control
        if (this.Mixer) {
          if (this.playerSettings.mute) {
            this.Mixer.setGain(0); // Zero gain if muted
          } else {
            this.Mixer.setGain(this.playerSettings.volume); // Set volume otherwise
          }
        }
      },
    );
  }

  initMixer() {
    this.Mixer = new GenesisMixer();
    if (this.playerSettings.mute) {
      this.Mixer.setGain(0); // Zero gain if muted
    } else {
      this.Mixer.setGain(this.playerSettings.volume); // Set volume otherwise
    }
  }

  get playerState(): PlayerState {
    return this.state;
  }

  get savedProgress(): number {
    if (this.savedProgressMs) {
      return this.savedProgressMs;
    } else if (this.savedProgressPercent) {
      // If track percent was trying to be set before track was loaded
      return this.savedProgressPercent * this.longestPlayingTrack.duration();
    }
    return undefined;
  }

  set savedProgress(ms: number) {
    this.savedProgressMs = ms;
    this.savedProgressPercent = undefined;
  }

  getCurrentPlaying(): TrackEntity {
    return this.track;
  }

  setActiveTrack(trackId: string) {
    if (this.state !== PlayerState.no_track) {
      this.resetPlayer();
    }
    this.trackDataService
      .getTrack(trackId)
      .pipe(first())
      .subscribe((track) => {
        // this.playingChannels = [0];
        this.trackId = track.id;
        this.track = track;
        this.setTrackSettingsListener();
        this.track.settings.set('active', true).save();
        this.trackSettingsService.setActiveTrack(this.trackId);
        this.activeTrackSource.next(track);
        this.setState(PlayerState.begin_play);
      });
  }

  setTrackSettingsListener() {
    if (this.trackSettingsSub) {
      this.trackSettingsSub.unsubscribe();
    }
    this.trackSettingsSub = this.track.settings.change$.subscribe(
      (settings: [TrackSettingsRecord, TrackSettings]) => {
        if (this.track) {
          this.processTrackSettings(settings[0], this.track.settings);
        } else {
          this.trackSettingsSub.unsubscribe();
          this.trackSettingsSub = undefined;
        }
      },
    );
  }

  setCanvases(componentId: string, canvases: HTMLCanvasElement[]) {
    this.vuCanvases.set(componentId, canvases);
    this.updateCanvasForPlayingTracks(componentId);
  }

  removeCanvases(componentId: string) {
    this.vuCanvases.delete(componentId);
  }

  private updateCanvasForPlayingTracks(componentId: string) {
    const canvases = this.vuCanvases.get(componentId);
    if (!canvases) {
      return;
    }

    this.playingChannels.forEach((channel) => {
      if (
        !canvases[channel] ||
        !(canvases[channel] instanceof HTMLCanvasElement)
      ) {
        return;
      }
      if (
        this.playingTracks[channel] &&
        this.playingTracks[channel].vuSupport
      ) {
        this.playingTracks[channel].vuSupport.setCanvas(canvases[channel]);
      }
    });
  }

  setTrackTimePercent(percent: number) {
    if (this.longestPlayingTrack) {
      this.setTrackTime(percent * this.longestPlayingTrack.duration());
    } else {
      this.savedProgressPercent = percent;
    }
  }

  setTrackTime(time: number) {
    for (let channel of this.playingChannels) {
      this.playingTracks[channel]?.currentTime(time);
    }
    this.requestProgress();
  }

  togglePlay(trackId: string, channels?: number[], event?: UIEvent) {
    if (!channels) {
      channels =
        trackId === this.trackId && this.playingChannels.length > 0
          ? this.playingChannels
          : [0];
    }
    if (
      trackId === this.trackId &&
      this.track.settings.investigating &&
      channels &&
      channels[0] === 0
    ) {
      this.trackSettingsService.setTrackInvestigating(trackId, false);
    }
    this.unlock(event);
    if (
      trackId === this.trackId &&
      this.playingIndexesEqual(this.playingChannels, channels) &&
      this.areChannelsLoaded(channels)
    ) {
      if (this.isState(PlayerState.play)) {
        this.stopPlay();
      } else {
        this.startPlay();
      }
    } else if (trackId === this.trackId) {
      // If player is streaming, but new channels length is greater than 1 (meaning we can no longer stream)
      // skip this step, we'll need to load new GenesisTracks for buffering.
      this.stopPlay();
      if (this.longestPlayingTrack) {
        this.savedProgress = this.longestPlayingTrack.currentTime();
      }
      if (
        this.playingChannels.length > 0 &&
        this.playingChannels[0] !== 0 &&
        channels[0] === 0
      ) {
        this.track.settings.resetSettings();
      }
      // this.setState(PlayerState.begin_play);
      this.changeStems(channels);
    } else {
      this.stopPlay();
      if (trackId === this.trackId) {
        this.savedProgress = this.longestPlayingTrack.currentTime();
      } else {
        this.savedProgress = undefined;
      }
      this.setState(PlayerState.begin_play);
      this.trackDataService
        .getTrack(trackId)
        .pipe(first())
        .subscribe((track: TrackEntity) => {
          this.initTrack(track, channels);
        });
    }
  }

  investigate(trackId: string, event?: UIEvent) {
    this.trackDataService
      .getTrack(trackId)
      .pipe(first())
      .subscribe((track) => {
        const primaryStemIndexes = this.getPrimaryStems(track);
        this.togglePlay(trackId, primaryStemIndexes, event);
      });
  }

  unlock(event: Event) {
    if (!this.Mixer) {
      this.initMixer();
    }
    if (!this.unlocked && !this.initialPlayed && event && !this.dragging) {
      this.Mixer.unlock();
      this.unlocked = true;
    }
  }

  // nextTrack() {
  //   this.nextUpService.nextTrack().then((track: Track) => {
  //     if (track) {
  //       this.togglePlay(track.id);
  //     }
  //   });
  // }

  // previousTrack() {
  //   if (this.longestPlayingTrack) {
  //     if (this.longestPlayingTrack.currentTime() < 4) {
  //       this.nextUpService.previousTrack().then((track: Track) => {
  //         this.togglePlay(track.id);
  //       });
  //     } else {
  //       if (this.isState(PlayerState.play)) {
  //         this.Mixer.pause(0);
  //         this.Mixer.play();
  //       } else {
  //         this.setTrackTime(0);
  //         this.togglePlay(this.trackId);
  //       }
  //     }
  //   }
  // }

  toTop() {
    this.setTrackTime(0);
  }

  forwardFiveSeconds() {
    if (
      this.longestPlayingTrack.currentTime() >
      this.track.audioChannels[0].duration - 5
    ) {
      this.setTrackTime(this.track.audioChannels[0].duration);
    } else {
      this.setTrackTime(this.longestPlayingTrack.currentTime() + 5);
    }
  }

  backwardFiveSeconds() {
    if (this.longestPlayingTrack.currentTime() > 5) {
      this.setTrackTime(this.longestPlayingTrack.currentTime() - 5);
    } else {
      this.setTrackTime(0);
    }
  }

  nextChannel() {
    const index = this.track.settings.stemsActive.findIndex(
      (value) => value === true,
    );
    if (index + 1 !== this.track.audioChannels.length) {
      this.togglePlay(this.trackId, [index + 1]);
    } else {
      this.togglePlay(this.trackId, [0]);
    }
  }

  previousChannel() {
    const index = this.track.settings.stemsActive.findIndex(
      (value) => value === true,
    );
    if (index !== 0) {
      this.togglePlay(this.trackId, [index - 1]);
    } else {
      this.togglePlay(this.trackId, [this.track.audioChannels.length - 1]);
    }
  }

  setTrackSolo(which: number) {
    this.track.settings.toggleSolo(which).save();
  }

  private areChannelsLoaded(channels: number[]) {
    let returner = true;
    for (let channel of channels) {
      if (returner) {
        returner = this.loadedIndexes.includes(channel);
      }
    }
    return returner;
  }

  private processTrackSettings(
    prevTrackSettings: TrackSettingsRecord,
    curTrackSettings: TrackSettings,
    force = false,
  ) {
    if (curTrackSettings) {
      let numPrevMutes = 0;
      if (prevTrackSettings) {
        numPrevMutes = prevTrackSettings
          .get('mutes')
          .filter((value) => value === true).size;
      }
      const numNewMutes = curTrackSettings.mutes.filter(
        (value) => value === true,
      ).size;
      if (this.isStreaming && numNewMutes > numPrevMutes && !force) {
        this.investigate(this.trackId);
      } else if (curTrackSettings.ready) {
        curTrackSettings.gains.forEach((value: number, index: number) => {
          let tempTrack = this.playingTracks[index];
          if (tempTrack) {
            if (
              force ||
              (value !== prevTrackSettings.get('gains').get(index) &&
                !curTrackSettings.mutes.get(index))
            ) {
              tempTrack.setGain(value);
            }
            let newMute = curTrackSettings.mutes.get(index);
            if (
              force ||
              newMute !== prevTrackSettings.get('mutes').get(index)
            ) {
              if (!newMute) {
                tempTrack.setGain(value);
              } else {
                tempTrack.mute(newMute);
              }
            }
          }
        });
      }
      if (
        force ||
        !curTrackSettings.solos.equals(prevTrackSettings.get('solos'))
      ) {
        const multipleSolos =
          curTrackSettings.solos.filter((value, i) => i !== 0 && value === true)
            .size > 1;
        if (this.isStreaming && multipleSolos && !force) {
          // this.investigate(this.trackId);
        } else if (this.isStreaming && !force) {
          const index = curTrackSettings.solos.findIndex(
            (value, i) => value === true,
          );
          if (index > 0) {
            this.togglePlay(this.trackId, [index]);
          } else if (this.playingChannels[0] !== 0) {
            this.togglePlay(this.trackId, [0]);
          }
        } else if (!this.isStreaming) {
          curTrackSettings.solos.forEach((value: boolean, index: number) => {
            let tempTrack = this.playingTracks[index];
            if (tempTrack) {
              tempTrack.solo(value);
            }
          });
          this.Mixer.processSolo();
        }
      }
    }
  }

  private changeStems(channels: number[]) {
    this.playingTracks = {};
    this.Mixer.unhookAllTracks();
    this.loadedIndexes
      .slice()
      .reverse()
      .forEach((channel, index, object) => {
        if (
          this.loadedTracks[channel] &&
          channels.indexOf(channel) !== -1 &&
          channels.length > 1 &&
          this.loadedTracks[channel].options.stream
        ) {
          this.loadedTracks[channel].destroy();
          delete this.loadedTracks[channel];
          this.loadedIndexes.splice(object.length - 1 - index, 1);
        }
      });
    this.playingChannels = channels; // Set new channels
    let shouldSkipBuffering = true; // Should skip creation of new Genesis Tracks
    for (const index of this.playingChannels) {
      if (
        !this.playingTracks.hasOwnProperty(index) &&
        this.loadedIndexes.indexOf(index) >= 0
      ) {
        this.playingTracks[index] = this.loadedTracks[index];
        this.Mixer.hookTrack(this.playingTracks[index]);
      } else if (!this.playingTracks.hasOwnProperty(index)) {
        shouldSkipBuffering = false;
      }
    }
    this.tracksDoneLoading = Object.keys(this.playingTracks).length;
    this.startLoadStems(shouldSkipBuffering);
  }

  resetPlayer() {
    this.reInitPlayer();
    this.trackProgressSource.next([0, 0]);
    this.savedProgress = undefined;
    this.trackId = undefined;
    this.track = undefined;
    this.setState(PlayerState.no_track);
    this.trackSettingsService.setActiveTrack(undefined);
    this.activeTrackSource.next(undefined);
  }

  private initTrack(track: TrackEntity, channels: number[]) {
    this.reInitPlayer();
    this.playingChannels = channels;
    if (this.trackId !== track.id) {
      track.settings.set('active', true);
      this.activeTrackSource.next(track);
    }
    this.trackId = track.id;
    this.track = track;
    this.setTrackSettingsListener();
    this.trackDataService.saveRecentTrack(track.id);
    this.startLoadStems(false);
  }

  private startLoadStems(skipBuffering: boolean) {
    this.isStreaming = this.playingChannels.length === 1;
    if (!this.isStreaming) {
      this.track.settings.set('investigating', true);
    }
    this.longestPlayingTrack = undefined;
    this.track.settings.setStemsActive(this.playingChannels);
    if (!skipBuffering) {
      this.track.settings.set('buffering', true);
      this.setState(PlayerState.begin_play);
      // this.trackSettings = this.trackSettingsService.getTrackSettings(this.trackId);
      this.createTracksHelper(this.playingChannels).subscribe(() =>
        this.load(true),
      );
    } else {
      this.setLongestPlayingTrack();
      this.setTrackTime(this.savedProgress);
      this.processTrackSettings(undefined, this.track.settings, true);
      this.startPlay();
    }
  }

  private reInitPlayer() {
    if (this.Mixer) {
      this.stopPlay();
      this.Mixer.removeAllTracks();
    }
    this.longestPlayingTrack = undefined;
    for (const prop in this.playingTracks) {
      this.playingTracks[prop].destroy();
      delete this.playingTracks[prop];
    }
    for (const prop in this.loadedTracks) {
      this.loadedTracks[prop].destroy();
      delete this.loadedTracks[prop];
    }
    this.playingChannels = [];
    this.loadedIndexes = [];
    this.tracksDoneLoading = 0;
    if (this.trackSettingsSub) {
      this.trackSettingsSub.unsubscribe();
      this.trackSettingsSub = undefined;
    }
    if (this.trackId) {
      this.track.settings
        .resetSettings()
        .setStemsBuffering([])
        .setStemsActive([])
        .set('state', TrackState.inactive)
        .set('buffering', false)
        .set('active', false)
        .set('ready', false)
        .set('investigating', false)
        .save();
    }
  }

  private startPlay() {
    this.Mixer.play();
    this.setState(PlayerState.play);
    this.startTrackTimeInterval();
  }

  stopPlay() {
    this.stopTrackTimeInterval();
    this.Mixer.pause();
    this.setState(PlayerState.silent);
  }

  private setState(state: PlayerState) {
    this.state = state;
    this.playerStateSource.next(state);
    if (this.track && PlayerState[state] in TrackState) {
      this.track.settings.set('state', TrackState[PlayerState[state]]).save();
    }
    if (state === PlayerState.ended) {
      this.stopTrackTimeInterval();
      this.Mixer.stop();
    }
  }

  isState(state: PlayerState): boolean {
    return this.state === state;
  }

  /**
   * Set up events for new tracks
   * @param play
   */
  private load(play = false) {
    let player = this;
    for (let channelIndex of player.playingChannels) {
      if (player.loadedIndexes.indexOf(channelIndex) === -1) {
        const mixerTrack = player.playingTracks[channelIndex];
        mixerTrack.events.on(EventNames.loadError).subscribe(() => {
          player.setState(PlayerState.error);
        });
        mixerTrack.events.on(EventNames.ready).subscribe((gTrack) => {
          player.tracksDoneLoading++;
          player.loadedTracks[gTrack.name] = gTrack;
          player.loadedIndexes.push(parseInt(gTrack.name, 10));
          if (player.tracksDoneLoading === player.playingChannels.length) {
            player.setLongestPlayingTrack();
            // player.arrayUnique(player.loadedIndexes.concat(player.playingChannels));
            player.track.settings
              .setStemsBuffering([])
              .set('buffering', false)
              .set('ready', true)
              .set('duration', player.track.audioChannels[0].duration)
              .save();
            if (play) {
              if (player.savedProgress) {
                player.setTrackTime(player.savedProgress);
              }
              player.processTrackSettings(
                undefined,
                player.track.settings,
                true,
              );
              player.startPlay();
              this.trackService.incrementPlayCount(this.trackId).subscribe();
            }
          }
        });
      }
    }
    this.initialPlayed = true;
  }

  setLongestPlayingTrack() {
    this.longestPlayingTrack = undefined;
    const firstKey = Object.keys(this.playingTracks)[0];
    this.longestPlayingTrack = this.playingTracks[firstKey];
  }

  /**
   * Create GenesisTracks and add them to playingTracks
   * @param channels
   */
  private createTracksHelper(channels: number[]): Observable<void> {
    return new Observable((observer) => {
      const toLoadIndexes = this.getStemIndexesToLoad(channels);
      this.track.settings.setStemsBuffering(toLoadIndexes).save();
      let completeCount = 0;
      const nowPlayingBarCanvases = this.vuCanvases.get('nowPlayingBar') || [];
      const audioExplorerCanvases = this.vuCanvases.get('audioExplorer') || [];
      for (let channel of toLoadIndexes) {
        let audioChannel;
        audioChannel = this.track.audioChannels[channel];
        let selectedCanvases = [];
        if (nowPlayingBarCanvases[channel])
          selectedCanvases.push(nowPlayingBarCanvases[channel]);
        if (audioExplorerCanvases[channel])
          selectedCanvases.push(audioExplorerCanvases[channel]);
        if (!audioChannel.isLocal) {
          this.audioChannelService
            .getListenUrl(this.track.audioChannels[channel].id)
            .pipe(first())
            .subscribe({
              next: (res) => {
                let trackData, options;
                trackData = this.track.audioChannels[channel];
                options = new TrackOptions({
                  source: res,
                  gain: 1,
                  autoplay: false,
                  canvases: selectedCanvases,
                  stream: channels.length === 1,
                });
                const tempGenesisTrack = this.Mixer.createTrack(
                  `${channel}`,
                  options,
                );
                this.playingTracks[tempGenesisTrack.name] = tempGenesisTrack;
                completeCount++;
                if (completeCount === toLoadIndexes.length) {
                  observer.next();
                  observer.complete();
                }
              },
              error: () => {
                console.log('error getting listenUrl');
              },
            });
        } else {
          // TODO: Duplicate of above
          let trackData, options;
          trackData = this.track.audioChannels[channel];
          options = new TrackOptions({
            source: audioChannel.blob,
            gain: 1,
            autoplay: false,
            canvases: selectedCanvases,
            // stream: channels.length === 1
            stream: false,
          });
          const tempGenesisTrack = this.Mixer.createTrack(
            `${channel}`,
            options,
          );
          this.playingTracks[tempGenesisTrack.name] = tempGenesisTrack;
          completeCount++;
          if (completeCount === toLoadIndexes.length) {
            observer.next();
            observer.complete();
          }
        }
      }
    });
    // this.loadedTracks[tempGenesisTrack.name] = tempGenesisTrack;
  }

  /**
   * Check if presigned URL is expired
   */
  private isUrlExpired(): boolean {
    const firstKey = Object.keys(this.playingTracks)[0];
    if (!firstKey) {
      return false;
    }
    const url = this.playingTracks[firstKey].options.source;
    const match = url.match(/Expires=(\d+)/);
    const expirationTime = match ? parseInt(match[1], 10) : 0;
    const currentTime = Math.floor(Date.now() / 1000);
    return currentTime >= expirationTime;
  }

  /**
   * Compare two arrays equal
   * @param arr1
   * @param arr2
   */
  private playingIndexesEqual(arr1, arr2) {
    return (
      arr1.length === arr2.length &&
      arr1.every((value, index) => value === arr2[index])
    );
  }

  /**
   * Calculate progress and emit
   */
  requestProgress() {
    if (this.longestPlayingTrack) {
      let curTime = this.longestPlayingTrack.currentTime();
      this.trackProgressSource.next([
        curTime,
        curTime / this.track.settings.duration,
      ]);
    }
  }

  /**
   * Start progress request loop
   */
  private startTrackTimeInterval() {
    const updateProgress = () => {
      if (this.state === PlayerState.play) {
        this.requestProgress();
        requestAnimationFrame(updateProgress);
      }
    };
    updateProgress();
  }

  /**
   * Stop progress request loop
   */
  private stopTrackTimeInterval() {
    if (this.animationFrameId) {
      cancelAnimationFrame(this.animationFrameId);
      this.animationFrameId = undefined;
    }
  }

  /**
   * @param channels
   * @returns stems that are needed, but not yet in playingTracks
   */
  private getStemIndexesToLoad(channels: number[]): number[] {
    const toLoadIndexes = [];
    for (const index of channels) {
      if (!this.playingTracks.hasOwnProperty(index)) {
        toLoadIndexes.push(index);
      }
    }
    return toLoadIndexes;
  }

  /**
   * @param track
   * @returns array of primary stem indexes for current or passed track
   */
  private getPrimaryStems(track: TrackEntity): number[] {
    return Array.from(
      { length: track.audioChannels.length - 1 },
      (_, i) => i + 1,
    );
  }

  createCustomMix(trackName: string) {
    this.Mixer.createMix(trackName);
  }
}
