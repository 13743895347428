import { Injectable, inject } from '@angular/core';
import { Uploadable } from './uploadable';
import { UploadDialogManagerService } from './upload-dialog-manager.service';
import { FileItem } from 'ng2-file-upload';
import { PallotoneApiService } from '../ptoneapi/ptoneapi.service';
import { Uploader } from './uploader';
import { AudioUploadFile } from './audio.upload-file';
import { UploadFile } from './upload-file';
import { TrackDataService } from '../track-data/track-data.service';
import { UploadType } from '@pallotone/data-access';
import { GetPresignedUrlGQL } from '@pallotone/data-access-ng';

@Injectable()
export class UploadManagerService {
  public uploadDialogManagerService = inject(UploadDialogManagerService);
  public pallotoneApiService = inject(PallotoneApiService);
  public trackDataService = inject(TrackDataService);
  public getPresignedUrlGql = inject(GetPresignedUrlGQL);

  uploadables: Uploadable[] = [];
  uploaders: Uploader<UploadFile>[] = [];

  createUploader(type: UploadType, fileLimit = 1): Uploader<UploadFile> {
    if (type === UploadType.Audio) {
      const uploader = new Uploader(AudioUploadFile, type, fileLimit, this);
      this.uploaders.push(uploader);
      return uploader;
    }
    throw new TypeError('Uploader only supports track type');
  }

  createUpload<U extends Uploadable>(
    uploadableType: { new (): U },
    type: UploadType,
    ...params
  ): U {
    const entity = new uploadableType(); // create a new object of that type
    entity.setup(this, type, ...params);
    this.uploadables.push(entity);
    return entity;
  }

  findByIdentifier(identifier: any): Uploadable {
    return this.uploadables.find((element: Uploadable): boolean => {
      return element.identifier === identifier;
    });
  }
}

export class UploadResult {
  public saveUrl: string;
  public type: UploadType;

  public constructor(saveUrl: string, type: UploadType) {
    this.saveUrl = saveUrl;
    this.type = type;
  }
}

export class FileAddedResult {
  public dataUrl?: string;
  public type: UploadType;
  public fileItem?: FileItem;

  public constructor(init?: Partial<FileAddedResult>) {
    Object.assign(this, init);
  }
}
