import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import { MatMenu, MatMenuModule } from '@angular/material/menu';
import { KeyboardShortcutsDialog } from '../../core-ui/dialogs/keyboard-shortcuts-dialog/keyboard-shortcuts-dialog.component';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { AuthService } from '../../core/auth/auth.service';
import { Observable } from 'rxjs';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { RouterLink } from '@angular/router';
import { AuthResultDto, UserRole } from '@pallotone/data-access';
import { RevelationService } from '../../core/revelation/revelation.service';

@Component({
  selector: 'ptone-popover-user-menu',
  templateUrl: './popover-user-menu.component.html',
  styleUrls: ['./popover-user-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    RouterLink,
  ],
})
export class PopoverUserMenuComponent implements OnInit {
  private authService = inject(AuthService);
  private playerService = inject(RevelationService);
  private matDialog = inject(MatDialog);

  @ViewChild('matMenu', { static: true }) matMenu: MatMenu;

  authDto$: Observable<AuthResultDto | null>;

  readonly UserRole = UserRole;

  constructor() {
    this.authDto$ = this.authService.authDto$;
  }

  ngOnInit(): void {}

  signout(): void {
    this.authService.signout();
    this.playerService.resetPlayer();
  }

  showKeyboardShortcutsDialog(): void {
    KeyboardShortcutsDialog.showKeyboardShortcutsDialog(this.matDialog);
  }
}
