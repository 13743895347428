import { Injectable, inject } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { TrackSettingsService } from '../track-settings/track-settings.service';
import { TrackListType } from '../../models/track-list-type.enum';
import {
  AudioSnackbarComponent,
  AudioSnackbarConfig,
} from '../../core-ui/audio-snackbar/audio-snackbar.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TrackList } from '../../models/tracklist.model';
import { first } from 'rxjs/operators';
import { getPtoneSnackBarConfig } from '../../utils/ptone-material.config';
import { TrackEntity } from '../../models/track-entity.model';

@Injectable()
export class TrackDataService {
  private trackSettingsService = inject(TrackSettingsService);
  private matSnackBar = inject(MatSnackBar);

  private tracks: any = {};
  private recentTracks: string[] = [];

  replaceCachedTrack(track: TrackEntity) {
    this.processTrack(track, true);
  }

  updateTrack(newTrack: TrackEntity) {
    let existingTrack = this.tracks[newTrack.id];
    if (existingTrack) {
      for (let key in existingTrack) {
        if (existingTrack.hasOwnProperty(key)) {
          existingTrack[key] = newTrack[key];
        }
      }
      this.processTrack(existingTrack, true);
    }
  }

  updateChannelLabel(trackId: string, label: string, index: number) {
    this.tracks[trackId].audioChannels[index].label = label;
  }

  removeChannel(trackId: string, index: number) {
    let track = this.tracks[trackId];
    track.audioChannels.splice(index, 1);
    this.processTrack(track, true);
  }

  getTrack(id?: string, forceLoad?: boolean): Observable<TrackEntity> {
    return Observable.create((observer) => {
      if (id) {
        if (!forceLoad && id in this.tracks) {
          observer.next(this.tracks[id]);
        } else {
          // old getTrack method was here
        }
      } else {
        observer.error('No id passed');
      }
    });
  }

  saveRecentTrack(id: string) {
    let index = this.recentTracks.indexOf(id);
    if (index > -1) {
      this.recentTracks.splice(index, 1);
    }
    this.recentTracks.unshift(id);
  }

  getRecentTracks(): Observable<TrackEntity[]> {
    return Observable.create((observer) => {
      let tempArray = [];
      if (this.recentTracks.length > 0) {
        let tracksLoaded = 0;
        for (let id of this.recentTracks) {
          this.getTrack(id).subscribe((track: TrackEntity) => {
            tempArray.push(track);
            tracksLoaded++;
            if (tracksLoaded === this.recentTracks.length) {
              observer.next(tempArray);
            }
          });
        }
      } else {
        observer.next(tempArray);
      }
    });
  }

  refreshTracks(trackList: TrackList): Observable<TrackEntity[]> {
    return this.loadTracks(
      trackList,
      1,
      trackList.perPage * trackList.maxPage,
      true,
    );
  }

  loadNextTracks(trackList: TrackList): Observable<TrackEntity[]> {
    return this.loadTracks(
      trackList,
      trackList.maxPage ? trackList.maxPage + 1 : 1,
      trackList.perPage,
    );
  }

  loadTracks(
    trackList: TrackList,
    loadPage: number,
    perPage: number,
    replace: boolean = false,
  ): Observable<TrackEntity[]> {
    return Observable.create((observer) => {
      // if (trackListType === this.currentListType && page <= this.currentMaxPage && data === this.currentListData) {
      //   observer.next(this.currentList.slice((page - 1) * perPage, page * perPage));
      // } else {
      switch (trackList.type) {
        case TrackListType.recent:
          this.getRecentTracks()
            .pipe(first())
            .subscribe((res: TrackEntity[]) => {
              this.loadedTracks(trackList, res, 0, observer, true);
            });
          break;
      }
    });
  }

  // NOTE Possible bug in future when NextUpService loads next page, currentList gets set to that max page, but does not include all the pages before in currentList

  private loadedTracks(
    trackList: TrackList,
    result: TrackEntity[],
    loadPage: number,
    observer: Observer<TrackEntity[]>,
    replace: boolean,
  ) {
    if (replace) {
      trackList.replaceTracks(result);
    } else {
      trackList.addTracks(result, loadPage);
    }
    observer.next(result);
  }

  // private loadedTracks(trackList: TrackList, result: Track[], page: number, observer: Observer<Track[]>) {
  //   if (trackListType === this.currentListType) {
  //     this.currentList = this.currentList.concat(result);
  //   } else {
  //     this.currentList = result;
  //   }
  //   this.currentListType = trackListType;
  //   this.currentListData = trackListData;
  //   this.currentMaxPage = page;
  //   console.log('!!loadedTracks1', this.nextUpTrackList, this.currentListType, this.currentListData);
  //   if (this.nextUpTrackList && (this.currentListType === this.nextUpTrackList.type && this.currentListData === this.nextUpTrackList.data)) {
  //     console.log('!!loadedTracks2', this.nextUpTrackList);
  //     this.nextUpTrackList.addTracks(result, page);
  //     // this.upNextChangedSource.next(this.nextUpTrackList);
  //   }
  //   observer.next(result);
  // }

  processTrack(track: TrackEntity, forceCreateSettings?: boolean): TrackEntity {
    const sortedAudioChannels = [...track.audioChannels].sort((a, b) => a.order - b.order);
    track.audioChannels = sortedAudioChannels;
    if (!(track.id in this.tracks) || forceCreateSettings) {
      this.trackSettingsService.createSettingsFor(track);
    } else {
      track.settings = this.trackSettingsService.getTrackSettings(track.id);
    }
    this.tracks[track.id] = track;
    return track;
  }

  private processTracks(tracks: TrackEntity[]): TrackEntity[] {
    for (let i = 0; i < tracks.length; i++) {
      tracks[i] = this.processTrack(tracks[i]);
    }
    return tracks;
  }

  private processDeepTracks(deepTracks: Array<any>): TrackEntity[] {
    let tempTracks: TrackEntity[] = Array.from(deepTracks, (deepTrack: any) => {
      return this.processTrack(deepTrack.track as TrackEntity);
    });
    return tempTracks;
  }

  private openSnackbar(
    text: string,
    audioSnackbar?: boolean,
    audioSnackbarConfig?: AudioSnackbarConfig,
  ) {
    let snackBarConfig = getPtoneSnackBarConfig();
    if (audioSnackbar && audioSnackbarConfig) {
      snackBarConfig.panelClass = 'audio-snackbar';
      snackBarConfig.data = { config: audioSnackbarConfig };
      this.matSnackBar.openFromComponent(
        AudioSnackbarComponent,
        snackBarConfig,
      );
    } else {
      this.matSnackBar.open(text, undefined, snackBarConfig);
    }
  }
}
