import { Component, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { Router, RouterModule } from '@angular/router';
import { getPtoneDialogConfig } from '../../../utils/ptone-material.config';
import { AuthService } from '../../../core/auth/auth.service';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'ptone-login',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatInputModule,
    MatIconModule,
    MatDialogModule,
    MatProgressSpinner,
    MatFormFieldModule,
    FormsModule,
    RouterModule,
  ],
})
export class LoginDialog implements OnInit {
  authValues: any = {};
  signInError: boolean;
  signInAttempt: boolean;

  static showLoginDialog(matDialog: MatDialog) {
    let config: MatDialogConfig = getPtoneDialogConfig();
    config.width = '420px';
    config.autoFocus = true;
    matDialog.open(LoginDialog, config);
  }

  constructor(
    public dialogRef: MatDialogRef<LoginDialog>,
    private router: Router,
    private authService: AuthService,
  ) {}

  ngOnInit(): void {}

  signIn(): void {
    this.signInAttempt = true;
    this.signInError = false;
    this.signInAttempt = true;
    this.authService
      .signin(this.authValues.email, this.authValues.password)
      .subscribe({
        next: () => {
          this.dialogRef.close();
        },
        error: () => {
          this.signInError = true;
          this.signInAttempt = false;
        },
      });
  }

  signUpLink(): void {
    this.router.navigate(['/signup']).then(() => {
      this.dialogRef.close();
    });
  }
}
