<div class="login-dialog-container">
  <svg xmlns="http://www.w3.org/2000/svg" width="50" viewBox="0 0 66.92 56.3">
    <path
      d="M42.91,22a20.08,20.08,0,1,0,0,28.4A20.1,20.1,0,0,0,42.91,22ZM32.25,34.21l-1.77-1.77a.81.81,0,0,1-.24-.6.88.88,0,0,1,.24-.6l6.08-6.08a.86.86,0,0,1,1.19,0l1.77,1.77a.85.85,0,0,1,0,1.19l-6.08,6.08a.85.85,0,0,1-.6.24A.71.71,0,0,1,32.25,34.21Z"
      fill="#a45cec"
    ></path>
    <path
      d="M11.7,55.69l.26-.26a.81.81,0,0,0,.25-.62.84.84,0,0,0-.28-.62,24.62,24.62,0,1,1,41.44-18,.87.87,0,0,0,.87.87H66.05a.87.87,0,0,0,.87-.87,36.56,36.56,0,0,0-2.68-13.75A36.9,36.9,0,0,0,56.87,10.9,34,34,0,0,0,32,0,32,32,0,0,0,10.51,55.71.85.85,0,0,0,11.7,55.69Z"
      fill="#a45cec"
    ></path>
  </svg>
  <div class="copy">
    <h1 class="header">Sign in to Pallotone</h1>
  </div>
  <form (submit)="signIn()">
    <mat-form-field appearance="fill" class="full">
      <mat-label>Email</mat-label>
      <input
        matInput
        id="email"
        name="email"
        autocapitalize="none"
        spellcheck="false"
        autofocus
        [(ngModel)]="authValues.email"
      />
    </mat-form-field>
    <mat-form-field appearance="fill" class="full">
      <mat-label>Password</mat-label>
      <input
        matInput
        id="password"
        name="password"
        [(ngModel)]="authValues.password"
        type="password"
      />
      @if (signInError) {
      <mat-hint>Invalid credentials</mat-hint>
      }
    </mat-form-field>
    <button
      mat-flat-button
      color="primary"
      type="submit"
      class="large submit-button"
      [ngClass]="{ shake: signInError }"
    >
      @if (!signInAttempt) {
      <span>SIGN IN</span>
      } @if (signInAttempt) {
      <mat-spinner diameter="20" class="inverted"></mat-spinner>
      }
    </button>
  </form>
  <div class="sub-text">
    Need to create an account? <a (click)="signUpLink()">Sign up</a>
  </div>
  <button
    mat-icon-button
    class="close-dialog-button"
    (click)="dialogRef.close()"
  >
    <mat-icon>close</mat-icon>
  </button>
</div>
