import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { map, filter, mergeMap, toArray } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FileUploadService {
  constructor(private http: HttpClient) {}

  uploadFile(uploadUrl: string, file: File): Observable<any> {
    return this.http
      .put(uploadUrl, file, {
        reportProgress: true,
        observe: 'events',
        headers: { 'Content-Type': file.type },
      })
      .pipe(
        map((event) => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              return {
                type: 'progress',
                progress: Math.round((100 * event.loaded) / event.total),
                fileName: file.name,
              };
            case HttpEventType.Response:
              return {
                type: 'complete',
                message: 'Upload complete',
                fileName: file.name,
              };
            default:
              return null;
          }
        }),
        filter((event) => event !== null),
      );
  }

  uploadMultipleFiles(uploadUrl: string, files: File[]): Observable<any> {
    return from(files).pipe(
      mergeMap((file) => this.uploadFile(uploadUrl, file)),
      toArray(),
    );
  }
}
