export function getImageResizeUrl(url: string, size: string): string {
  const urlParts = url.split('/');

  const pathIndex = urlParts.findIndex(
    (part) => part === 'avatar' || part === 'artwork',
  );

  if (pathIndex > -1) {
    urlParts[pathIndex] = 'resize';
  }

  return `${urlParts.join('/')}/${size}`;
}
