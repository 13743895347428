import { MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBarConfig } from '@angular/material/snack-bar';

const ptoneSnackBarConfig: MatSnackBarConfig = {
  duration: 3500,
  verticalPosition: 'bottom',
  horizontalPosition: 'center',
};

export function getPtoneSnackBarConfig() {
  return Object.assign({}, ptoneSnackBarConfig);
}

const ptoneDialogConfig: MatDialogConfig = {
  restoreFocus: false,
  autoFocus: false,
};

export function getPtoneDialogConfig() {
  return Object.assign({}, ptoneDialogConfig);
}
