<div class="search">
  <button class="search-button">
    <mat-icon class="size-22">search</mat-icon>
    <!-- @if (searching()) {
    <mat-spinner diameter="16" class="inverted"></mat-spinner>
    } -->
  </button>
  <input
    [formControl]="userSearchInput"
    class="search-input"
    type="text"
    placeholder="Search Artists"
    autocomplete="off"
    spellcheck="false"
    [matAutocomplete]="userStringAuto"
  />
  <mat-autocomplete
    #userStringAuto="matAutocomplete"
    (optionSelected)="
      router.navigate(['/artist', $event.option.value.username])
    "
    [displayWith]="getArtistName"
  >
    @for (user of userStringResults(); track user.id) { @if (user.username) {
    <mat-option [value]="user" class="no-checkbox">
      <span class="user-option">
        <img
          class="user-option-avatar"
          [src]="
            user.avatarUrl
              ? getImageResizeUrl(user.avatarUrl, '100x100')
              : 'assets/images/common/profile-default.svg'
          "
          [alt]="user.name"
        />
        <span class="user-option-text truncate">
          <span class="user-option-name">{{ user.name }}</span>
          <span class="user-option-username">&#64;{{ user.username }}</span>
        </span>
      </span>
    </mat-option>
    } } @if (userStringResults().length === 0 && userSearchInput.value.length >
    0) {
    <mat-option class="no-results-option">
      <span class="no-results">No results</span>
    </mat-option>
    }
  </mat-autocomplete>
</div>
