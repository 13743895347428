export enum TrackListType {
  user_tracks,
  user_collection_tracks,
  user_liked_tracks,
  user_all_tracks,
  featured_tracks,
  feed,
  queue,
  recent,
  playlist,
  track_collection,
}

export const TrackListType_feed = TrackListType.feed;
export const TrackListType_featured_tracks = TrackListType.featured_tracks;
export const TrackListType_queue = TrackListType.queue;
export const TrackListType_recent = TrackListType.recent;
export const TrackListType_playlist = TrackListType.playlist;

export const TrackListType_user_tracks = TrackListType.user_tracks;
export const TrackListType_user_collection_tracks =
  TrackListType.user_collection_tracks;
export const TrackListType_user_all_tracks = TrackListType.user_all_tracks;
export const TrackListType_user_liked_tracks = TrackListType.user_liked_tracks;
