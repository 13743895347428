import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatMenu, MatMenuModule } from '@angular/material/menu';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'ptone-popover-mobile-menu',
  templateUrl: './popover-mobile-menu.component.html',
  styleUrls: ['./popover-mobile-menu.component.scss'],
  standalone: true,
  imports: [CommonModule, MatMenuModule, RouterLink],
})
export class PopoverMobileMenuComponent implements OnInit {
  @ViewChild('matMenu', { static: true }) matMenu: MatMenu;

  ngOnInit(): void {}
}
