export const STANDARD_LICENSE_DESCRIPTION: string =
  'For non-commercial use. Suitable for personal projects.';

export const STANDARD_LICENSE_PRICE: number = 89;

export const COMMERCIAL_LICENSE_DESCRIPTION: string =
  'For limited commercial use. Suitable for client projects.';

export const COMMERCIAL_LICENSE_PRICE: number = 299;

export const EXCLUSIVE_LICENSE_DESCRIPTION: string =
  'For broad commercial use. Removed from store on purchase.';

export const EXCLUSIVE_LICENSE_PRICE: number = 2499;
