import { FieldPolicy, FieldReadFunction, TypePolicies, TypePolicy } from '@apollo/client/cache';
export type AudioChannelKeySpecifier = ('aacKey' | 'aacUrl' | 'duration' | 'highQualityKey' | 'highQualityUrl' | 'id' | 'label' | 'order' | 'originalFilename' | 'originalKey' | 'originalUrl' | 'standardQualityKey' | 'standardQualityUrl' | 'transcodeStatus' | 'transcoderJobId' | 'waveformUrl' | AudioChannelKeySpecifier)[];
export type AudioChannelFieldPolicy = {
	aacKey?: FieldPolicy<any> | FieldReadFunction<any>,
	aacUrl?: FieldPolicy<any> | FieldReadFunction<any>,
	duration?: FieldPolicy<any> | FieldReadFunction<any>,
	highQualityKey?: FieldPolicy<any> | FieldReadFunction<any>,
	highQualityUrl?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	order?: FieldPolicy<any> | FieldReadFunction<any>,
	originalFilename?: FieldPolicy<any> | FieldReadFunction<any>,
	originalKey?: FieldPolicy<any> | FieldReadFunction<any>,
	originalUrl?: FieldPolicy<any> | FieldReadFunction<any>,
	standardQualityKey?: FieldPolicy<any> | FieldReadFunction<any>,
	standardQualityUrl?: FieldPolicy<any> | FieldReadFunction<any>,
	transcodeStatus?: FieldPolicy<any> | FieldReadFunction<any>,
	transcoderJobId?: FieldPolicy<any> | FieldReadFunction<any>,
	waveformUrl?: FieldPolicy<any> | FieldReadFunction<any>
};
export type AuthResultDtoKeySpecifier = ('accessToken' | 'avatarUrl' | 'email' | 'id' | 'name' | 'refreshToken' | 'roles' | 'stripeAccountId' | 'username' | AuthResultDtoKeySpecifier)[];
export type AuthResultDtoFieldPolicy = {
	accessToken?: FieldPolicy<any> | FieldReadFunction<any>,
	avatarUrl?: FieldPolicy<any> | FieldReadFunction<any>,
	email?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	refreshToken?: FieldPolicy<any> | FieldReadFunction<any>,
	roles?: FieldPolicy<any> | FieldReadFunction<any>,
	stripeAccountId?: FieldPolicy<any> | FieldReadFunction<any>,
	username?: FieldPolicy<any> | FieldReadFunction<any>
};
export type CreateAudioChannelResponseDtoKeySpecifier = ('audioChannel' | 'uploadUrl' | CreateAudioChannelResponseDtoKeySpecifier)[];
export type CreateAudioChannelResponseDtoFieldPolicy = {
	audioChannel?: FieldPolicy<any> | FieldReadFunction<any>,
	uploadUrl?: FieldPolicy<any> | FieldReadFunction<any>
};
export type DeleteManyResponseKeySpecifier = ('deletedCount' | DeleteManyResponseKeySpecifier)[];
export type DeleteManyResponseFieldPolicy = {
	deletedCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type FavoriteKeySpecifier = ('id' | 'trackId' | 'userId' | FavoriteKeySpecifier)[];
export type FavoriteFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	trackId?: FieldPolicy<any> | FieldReadFunction<any>,
	userId?: FieldPolicy<any> | FieldReadFunction<any>
};
export type FavoriteConnectionKeySpecifier = ('edges' | 'pageInfo' | FavoriteConnectionKeySpecifier)[];
export type FavoriteConnectionFieldPolicy = {
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
};
export type FavoriteDeleteResponseKeySpecifier = ('id' | 'trackId' | 'userId' | FavoriteDeleteResponseKeySpecifier)[];
export type FavoriteDeleteResponseFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	trackId?: FieldPolicy<any> | FieldReadFunction<any>,
	userId?: FieldPolicy<any> | FieldReadFunction<any>
};
export type FavoriteEdgeKeySpecifier = ('cursor' | 'node' | FavoriteEdgeKeySpecifier)[];
export type FavoriteEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type LicensePurchaseKeySpecifier = ('id' | 'licenseType' | 'paymentIntentId' | 'paymentMethodId' | 'purchaseAmount' | 'purchasedAt' | 'track' | 'trackId' | 'user' | 'userId' | LicensePurchaseKeySpecifier)[];
export type LicensePurchaseFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	licenseType?: FieldPolicy<any> | FieldReadFunction<any>,
	paymentIntentId?: FieldPolicy<any> | FieldReadFunction<any>,
	paymentMethodId?: FieldPolicy<any> | FieldReadFunction<any>,
	purchaseAmount?: FieldPolicy<any> | FieldReadFunction<any>,
	purchasedAt?: FieldPolicy<any> | FieldReadFunction<any>,
	track?: FieldPolicy<any> | FieldReadFunction<any>,
	trackId?: FieldPolicy<any> | FieldReadFunction<any>,
	user?: FieldPolicy<any> | FieldReadFunction<any>,
	userId?: FieldPolicy<any> | FieldReadFunction<any>
};
export type LicensePurchaseConnectionKeySpecifier = ('edges' | 'pageInfo' | LicensePurchaseConnectionKeySpecifier)[];
export type LicensePurchaseConnectionFieldPolicy = {
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
};
export type LicensePurchaseEdgeKeySpecifier = ('cursor' | 'node' | LicensePurchaseEdgeKeySpecifier)[];
export type LicensePurchaseEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type LicenseSalesKeySpecifier = ('count' | 'licenseType' | 'revenue' | LicenseSalesKeySpecifier)[];
export type LicenseSalesFieldPolicy = {
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	licenseType?: FieldPolicy<any> | FieldReadFunction<any>,
	revenue?: FieldPolicy<any> | FieldReadFunction<any>
};
export type MutationKeySpecifier = ('addOrRemoveFavorite' | 'addRole' | 'changePassword' | 'createAudioChannel' | 'createManyFavorites' | 'createManyTracks' | 'createOneFavorite' | 'createOneTrack' | 'createStripeLoginLink' | 'createTrack' | 'deleteManyFavorites' | 'deleteManyTracks' | 'deleteManyUsers' | 'deleteOneFavorite' | 'deleteOneTrack' | 'deleteOneUser' | 'deleteTrack' | 'getPresignedUrl' | 'incrementPlayCount' | 'onboardArtist' | 'publishTrack' | 'purchaseLicense' | 'removeRole' | 'requestSignup' | 'resetPassword' | 'sendPasswordResetEmail' | 'signin' | 'signup' | 'signupAdmin' | 'signupWithToken' | 'startTranscoding' | 'updateAudioChannel' | 'updateManyFavorites' | 'updateManyTracks' | 'updateManyUsers' | 'updateOneFavorite' | 'updateOneTrack' | 'updateOneUser' | 'updateStripeAccount' | 'updateTrack' | 'updateTrackStatus' | MutationKeySpecifier)[];
export type MutationFieldPolicy = {
	addOrRemoveFavorite?: FieldPolicy<any> | FieldReadFunction<any>,
	addRole?: FieldPolicy<any> | FieldReadFunction<any>,
	changePassword?: FieldPolicy<any> | FieldReadFunction<any>,
	createAudioChannel?: FieldPolicy<any> | FieldReadFunction<any>,
	createManyFavorites?: FieldPolicy<any> | FieldReadFunction<any>,
	createManyTracks?: FieldPolicy<any> | FieldReadFunction<any>,
	createOneFavorite?: FieldPolicy<any> | FieldReadFunction<any>,
	createOneTrack?: FieldPolicy<any> | FieldReadFunction<any>,
	createStripeLoginLink?: FieldPolicy<any> | FieldReadFunction<any>,
	createTrack?: FieldPolicy<any> | FieldReadFunction<any>,
	deleteManyFavorites?: FieldPolicy<any> | FieldReadFunction<any>,
	deleteManyTracks?: FieldPolicy<any> | FieldReadFunction<any>,
	deleteManyUsers?: FieldPolicy<any> | FieldReadFunction<any>,
	deleteOneFavorite?: FieldPolicy<any> | FieldReadFunction<any>,
	deleteOneTrack?: FieldPolicy<any> | FieldReadFunction<any>,
	deleteOneUser?: FieldPolicy<any> | FieldReadFunction<any>,
	deleteTrack?: FieldPolicy<any> | FieldReadFunction<any>,
	getPresignedUrl?: FieldPolicy<any> | FieldReadFunction<any>,
	incrementPlayCount?: FieldPolicy<any> | FieldReadFunction<any>,
	onboardArtist?: FieldPolicy<any> | FieldReadFunction<any>,
	publishTrack?: FieldPolicy<any> | FieldReadFunction<any>,
	purchaseLicense?: FieldPolicy<any> | FieldReadFunction<any>,
	removeRole?: FieldPolicy<any> | FieldReadFunction<any>,
	requestSignup?: FieldPolicy<any> | FieldReadFunction<any>,
	resetPassword?: FieldPolicy<any> | FieldReadFunction<any>,
	sendPasswordResetEmail?: FieldPolicy<any> | FieldReadFunction<any>,
	signin?: FieldPolicy<any> | FieldReadFunction<any>,
	signup?: FieldPolicy<any> | FieldReadFunction<any>,
	signupAdmin?: FieldPolicy<any> | FieldReadFunction<any>,
	signupWithToken?: FieldPolicy<any> | FieldReadFunction<any>,
	startTranscoding?: FieldPolicy<any> | FieldReadFunction<any>,
	updateAudioChannel?: FieldPolicy<any> | FieldReadFunction<any>,
	updateManyFavorites?: FieldPolicy<any> | FieldReadFunction<any>,
	updateManyTracks?: FieldPolicy<any> | FieldReadFunction<any>,
	updateManyUsers?: FieldPolicy<any> | FieldReadFunction<any>,
	updateOneFavorite?: FieldPolicy<any> | FieldReadFunction<any>,
	updateOneTrack?: FieldPolicy<any> | FieldReadFunction<any>,
	updateOneUser?: FieldPolicy<any> | FieldReadFunction<any>,
	updateStripeAccount?: FieldPolicy<any> | FieldReadFunction<any>,
	updateTrack?: FieldPolicy<any> | FieldReadFunction<any>,
	updateTrackStatus?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PageInfoKeySpecifier = ('endCursor' | 'hasNextPage' | 'hasPreviousPage' | 'startCursor' | PageInfoKeySpecifier)[];
export type PageInfoFieldPolicy = {
	endCursor?: FieldPolicy<any> | FieldReadFunction<any>,
	hasNextPage?: FieldPolicy<any> | FieldReadFunction<any>,
	hasPreviousPage?: FieldPolicy<any> | FieldReadFunction<any>,
	startCursor?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PaginatedTracksResponseKeySpecifier = ('hasNextPage' | 'totalCount' | 'tracks' | PaginatedTracksResponseKeySpecifier)[];
export type PaginatedTracksResponseFieldPolicy = {
	hasNextPage?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCount?: FieldPolicy<any> | FieldReadFunction<any>,
	tracks?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PresignedUrlResponseKeySpecifier = ('key' | 'saveUrl' | 'url' | PresignedUrlResponseKeySpecifier)[];
export type PresignedUrlResponseFieldPolicy = {
	key?: FieldPolicy<any> | FieldReadFunction<any>,
	saveUrl?: FieldPolicy<any> | FieldReadFunction<any>,
	url?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PurchaseResultKeySpecifier = ('message' | 'success' | PurchaseResultKeySpecifier)[];
export type PurchaseResultFieldPolicy = {
	message?: FieldPolicy<any> | FieldReadFunction<any>,
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type QueryKeySpecifier = ('favorite' | 'favorites' | 'findPublishedTracksByUser' | 'findTrackByIdentifier' | 'getDownloadUrl' | 'getListenUrl' | 'getMyFavoriteTracks' | 'getMyPurchasedTracks' | 'getMyUploadedTracks' | 'getPublishedTracks' | 'getTags' | 'getUsersByRoles' | 'isStripeAccountVerified' | 'licensePurchase' | 'licensePurchases' | 'refreshAuthData' | 'searchArtists' | 'track' | 'tracks' | 'tracksByTags' | 'user' | 'userByEmail' | 'userByIdentifier' | 'users' | QueryKeySpecifier)[];
export type QueryFieldPolicy = {
	favorite?: FieldPolicy<any> | FieldReadFunction<any>,
	favorites?: FieldPolicy<any> | FieldReadFunction<any>,
	findPublishedTracksByUser?: FieldPolicy<any> | FieldReadFunction<any>,
	findTrackByIdentifier?: FieldPolicy<any> | FieldReadFunction<any>,
	getDownloadUrl?: FieldPolicy<any> | FieldReadFunction<any>,
	getListenUrl?: FieldPolicy<any> | FieldReadFunction<any>,
	getMyFavoriteTracks?: FieldPolicy<any> | FieldReadFunction<any>,
	getMyPurchasedTracks?: FieldPolicy<any> | FieldReadFunction<any>,
	getMyUploadedTracks?: FieldPolicy<any> | FieldReadFunction<any>,
	getPublishedTracks?: FieldPolicy<any> | FieldReadFunction<any>,
	getTags?: FieldPolicy<any> | FieldReadFunction<any>,
	getUsersByRoles?: FieldPolicy<any> | FieldReadFunction<any>,
	isStripeAccountVerified?: FieldPolicy<any> | FieldReadFunction<any>,
	licensePurchase?: FieldPolicy<any> | FieldReadFunction<any>,
	licensePurchases?: FieldPolicy<any> | FieldReadFunction<any>,
	refreshAuthData?: FieldPolicy<any> | FieldReadFunction<any>,
	searchArtists?: FieldPolicy<any> | FieldReadFunction<any>,
	track?: FieldPolicy<any> | FieldReadFunction<any>,
	tracks?: FieldPolicy<any> | FieldReadFunction<any>,
	tracksByTags?: FieldPolicy<any> | FieldReadFunction<any>,
	user?: FieldPolicy<any> | FieldReadFunction<any>,
	userByEmail?: FieldPolicy<any> | FieldReadFunction<any>,
	userByIdentifier?: FieldPolicy<any> | FieldReadFunction<any>,
	users?: FieldPolicy<any> | FieldReadFunction<any>
};
export type TagsResponseKeySpecifier = ('count' | 'tag' | TagsResponseKeySpecifier)[];
export type TagsResponseFieldPolicy = {
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	tag?: FieldPolicy<any> | FieldReadFunction<any>
};
export type TrackKeySpecifier = ('artist' | 'artworkUrl' | 'audioChannels' | 'commercialLicensePrice' | 'createdAt' | 'deletedAt' | 'description' | 'exclusiveLicensePrice' | 'favorites' | 'genres' | 'id' | 'instruments' | 'isExclusiveOnly' | 'isFree' | 'licensePurchases' | 'likedByUser' | 'moods' | 'playCount' | 'publishedAt' | 'purchasedLicenses' | 'salesSummary' | 'slug' | 'standardLicensePrice' | 'status' | 'tags' | 'title' | 'totalRevenue' | 'updatedAt' | 'userLicensePurchases' | TrackKeySpecifier)[];
export type TrackFieldPolicy = {
	artist?: FieldPolicy<any> | FieldReadFunction<any>,
	artworkUrl?: FieldPolicy<any> | FieldReadFunction<any>,
	audioChannels?: FieldPolicy<any> | FieldReadFunction<any>,
	commercialLicensePrice?: FieldPolicy<any> | FieldReadFunction<any>,
	createdAt?: FieldPolicy<any> | FieldReadFunction<any>,
	deletedAt?: FieldPolicy<any> | FieldReadFunction<any>,
	description?: FieldPolicy<any> | FieldReadFunction<any>,
	exclusiveLicensePrice?: FieldPolicy<any> | FieldReadFunction<any>,
	favorites?: FieldPolicy<any> | FieldReadFunction<any>,
	genres?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	instruments?: FieldPolicy<any> | FieldReadFunction<any>,
	isExclusiveOnly?: FieldPolicy<any> | FieldReadFunction<any>,
	isFree?: FieldPolicy<any> | FieldReadFunction<any>,
	licensePurchases?: FieldPolicy<any> | FieldReadFunction<any>,
	likedByUser?: FieldPolicy<any> | FieldReadFunction<any>,
	moods?: FieldPolicy<any> | FieldReadFunction<any>,
	playCount?: FieldPolicy<any> | FieldReadFunction<any>,
	publishedAt?: FieldPolicy<any> | FieldReadFunction<any>,
	purchasedLicenses?: FieldPolicy<any> | FieldReadFunction<any>,
	salesSummary?: FieldPolicy<any> | FieldReadFunction<any>,
	slug?: FieldPolicy<any> | FieldReadFunction<any>,
	standardLicensePrice?: FieldPolicy<any> | FieldReadFunction<any>,
	status?: FieldPolicy<any> | FieldReadFunction<any>,
	tags?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>,
	totalRevenue?: FieldPolicy<any> | FieldReadFunction<any>,
	updatedAt?: FieldPolicy<any> | FieldReadFunction<any>,
	userLicensePurchases?: FieldPolicy<any> | FieldReadFunction<any>
};
export type TrackConnectionKeySpecifier = ('edges' | 'pageInfo' | TrackConnectionKeySpecifier)[];
export type TrackConnectionFieldPolicy = {
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
};
export type TrackDeleteResponseKeySpecifier = ('artworkUrl' | 'audioChannels' | 'commercialLicensePrice' | 'createdAt' | 'deletedAt' | 'description' | 'exclusiveLicensePrice' | 'genres' | 'id' | 'instruments' | 'isExclusiveOnly' | 'isFree' | 'likedByUser' | 'moods' | 'playCount' | 'publishedAt' | 'purchasedLicenses' | 'slug' | 'standardLicensePrice' | 'status' | 'tags' | 'title' | 'updatedAt' | TrackDeleteResponseKeySpecifier)[];
export type TrackDeleteResponseFieldPolicy = {
	artworkUrl?: FieldPolicy<any> | FieldReadFunction<any>,
	audioChannels?: FieldPolicy<any> | FieldReadFunction<any>,
	commercialLicensePrice?: FieldPolicy<any> | FieldReadFunction<any>,
	createdAt?: FieldPolicy<any> | FieldReadFunction<any>,
	deletedAt?: FieldPolicy<any> | FieldReadFunction<any>,
	description?: FieldPolicy<any> | FieldReadFunction<any>,
	exclusiveLicensePrice?: FieldPolicy<any> | FieldReadFunction<any>,
	genres?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	instruments?: FieldPolicy<any> | FieldReadFunction<any>,
	isExclusiveOnly?: FieldPolicy<any> | FieldReadFunction<any>,
	isFree?: FieldPolicy<any> | FieldReadFunction<any>,
	likedByUser?: FieldPolicy<any> | FieldReadFunction<any>,
	moods?: FieldPolicy<any> | FieldReadFunction<any>,
	playCount?: FieldPolicy<any> | FieldReadFunction<any>,
	publishedAt?: FieldPolicy<any> | FieldReadFunction<any>,
	purchasedLicenses?: FieldPolicy<any> | FieldReadFunction<any>,
	slug?: FieldPolicy<any> | FieldReadFunction<any>,
	standardLicensePrice?: FieldPolicy<any> | FieldReadFunction<any>,
	status?: FieldPolicy<any> | FieldReadFunction<any>,
	tags?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>,
	updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
};
export type TrackEdgeKeySpecifier = ('cursor' | 'node' | TrackEdgeKeySpecifier)[];
export type TrackEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type UpdateManyResponseKeySpecifier = ('updatedCount' | UpdateManyResponseKeySpecifier)[];
export type UpdateManyResponseFieldPolicy = {
	updatedCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type UserKeySpecifier = ('avatarUrl' | 'biography' | 'createdAt' | 'email' | 'favorites' | 'id' | 'name' | 'roles' | 'stripeAccountId' | 'tracks' | 'updatedAt' | 'username' | 'websiteUrl' | UserKeySpecifier)[];
export type UserFieldPolicy = {
	avatarUrl?: FieldPolicy<any> | FieldReadFunction<any>,
	biography?: FieldPolicy<any> | FieldReadFunction<any>,
	createdAt?: FieldPolicy<any> | FieldReadFunction<any>,
	email?: FieldPolicy<any> | FieldReadFunction<any>,
	favorites?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	roles?: FieldPolicy<any> | FieldReadFunction<any>,
	stripeAccountId?: FieldPolicy<any> | FieldReadFunction<any>,
	tracks?: FieldPolicy<any> | FieldReadFunction<any>,
	updatedAt?: FieldPolicy<any> | FieldReadFunction<any>,
	username?: FieldPolicy<any> | FieldReadFunction<any>,
	websiteUrl?: FieldPolicy<any> | FieldReadFunction<any>
};
export type UserConnectionKeySpecifier = ('edges' | 'pageInfo' | 'totalCount' | UserConnectionKeySpecifier)[];
export type UserConnectionFieldPolicy = {
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type UserDeleteResponseKeySpecifier = ('avatarUrl' | 'biography' | 'createdAt' | 'email' | 'id' | 'name' | 'roles' | 'stripeAccountId' | 'updatedAt' | 'username' | 'websiteUrl' | UserDeleteResponseKeySpecifier)[];
export type UserDeleteResponseFieldPolicy = {
	avatarUrl?: FieldPolicy<any> | FieldReadFunction<any>,
	biography?: FieldPolicy<any> | FieldReadFunction<any>,
	createdAt?: FieldPolicy<any> | FieldReadFunction<any>,
	email?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	roles?: FieldPolicy<any> | FieldReadFunction<any>,
	stripeAccountId?: FieldPolicy<any> | FieldReadFunction<any>,
	updatedAt?: FieldPolicy<any> | FieldReadFunction<any>,
	username?: FieldPolicy<any> | FieldReadFunction<any>,
	websiteUrl?: FieldPolicy<any> | FieldReadFunction<any>
};
export type UserEdgeKeySpecifier = ('cursor' | 'node' | UserEdgeKeySpecifier)[];
export type UserEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type UserFavoritesConnectionKeySpecifier = ('edges' | 'pageInfo' | UserFavoritesConnectionKeySpecifier)[];
export type UserFavoritesConnectionFieldPolicy = {
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
};
export type UserLicensePurchaseKeySpecifier = ('licenseType' | 'purchaseAmount' | 'purchasedAt' | UserLicensePurchaseKeySpecifier)[];
export type UserLicensePurchaseFieldPolicy = {
	licenseType?: FieldPolicy<any> | FieldReadFunction<any>,
	purchaseAmount?: FieldPolicy<any> | FieldReadFunction<any>,
	purchasedAt?: FieldPolicy<any> | FieldReadFunction<any>
};
export type UserTracksConnectionKeySpecifier = ('edges' | 'pageInfo' | UserTracksConnectionKeySpecifier)[];
export type UserTracksConnectionFieldPolicy = {
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
};
export type StrictTypedTypePolicies = {
	AudioChannel?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | AudioChannelKeySpecifier | (() => undefined | AudioChannelKeySpecifier),
		fields?: AudioChannelFieldPolicy,
	},
	AuthResultDto?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | AuthResultDtoKeySpecifier | (() => undefined | AuthResultDtoKeySpecifier),
		fields?: AuthResultDtoFieldPolicy,
	},
	CreateAudioChannelResponseDto?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CreateAudioChannelResponseDtoKeySpecifier | (() => undefined | CreateAudioChannelResponseDtoKeySpecifier),
		fields?: CreateAudioChannelResponseDtoFieldPolicy,
	},
	DeleteManyResponse?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | DeleteManyResponseKeySpecifier | (() => undefined | DeleteManyResponseKeySpecifier),
		fields?: DeleteManyResponseFieldPolicy,
	},
	Favorite?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | FavoriteKeySpecifier | (() => undefined | FavoriteKeySpecifier),
		fields?: FavoriteFieldPolicy,
	},
	FavoriteConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | FavoriteConnectionKeySpecifier | (() => undefined | FavoriteConnectionKeySpecifier),
		fields?: FavoriteConnectionFieldPolicy,
	},
	FavoriteDeleteResponse?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | FavoriteDeleteResponseKeySpecifier | (() => undefined | FavoriteDeleteResponseKeySpecifier),
		fields?: FavoriteDeleteResponseFieldPolicy,
	},
	FavoriteEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | FavoriteEdgeKeySpecifier | (() => undefined | FavoriteEdgeKeySpecifier),
		fields?: FavoriteEdgeFieldPolicy,
	},
	LicensePurchase?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | LicensePurchaseKeySpecifier | (() => undefined | LicensePurchaseKeySpecifier),
		fields?: LicensePurchaseFieldPolicy,
	},
	LicensePurchaseConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | LicensePurchaseConnectionKeySpecifier | (() => undefined | LicensePurchaseConnectionKeySpecifier),
		fields?: LicensePurchaseConnectionFieldPolicy,
	},
	LicensePurchaseEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | LicensePurchaseEdgeKeySpecifier | (() => undefined | LicensePurchaseEdgeKeySpecifier),
		fields?: LicensePurchaseEdgeFieldPolicy,
	},
	LicenseSales?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | LicenseSalesKeySpecifier | (() => undefined | LicenseSalesKeySpecifier),
		fields?: LicenseSalesFieldPolicy,
	},
	Mutation?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | MutationKeySpecifier | (() => undefined | MutationKeySpecifier),
		fields?: MutationFieldPolicy,
	},
	PageInfo?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PageInfoKeySpecifier | (() => undefined | PageInfoKeySpecifier),
		fields?: PageInfoFieldPolicy,
	},
	PaginatedTracksResponse?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PaginatedTracksResponseKeySpecifier | (() => undefined | PaginatedTracksResponseKeySpecifier),
		fields?: PaginatedTracksResponseFieldPolicy,
	},
	PresignedUrlResponse?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PresignedUrlResponseKeySpecifier | (() => undefined | PresignedUrlResponseKeySpecifier),
		fields?: PresignedUrlResponseFieldPolicy,
	},
	PurchaseResult?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PurchaseResultKeySpecifier | (() => undefined | PurchaseResultKeySpecifier),
		fields?: PurchaseResultFieldPolicy,
	},
	Query?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | QueryKeySpecifier | (() => undefined | QueryKeySpecifier),
		fields?: QueryFieldPolicy,
	},
	TagsResponse?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | TagsResponseKeySpecifier | (() => undefined | TagsResponseKeySpecifier),
		fields?: TagsResponseFieldPolicy,
	},
	Track?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | TrackKeySpecifier | (() => undefined | TrackKeySpecifier),
		fields?: TrackFieldPolicy,
	},
	TrackConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | TrackConnectionKeySpecifier | (() => undefined | TrackConnectionKeySpecifier),
		fields?: TrackConnectionFieldPolicy,
	},
	TrackDeleteResponse?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | TrackDeleteResponseKeySpecifier | (() => undefined | TrackDeleteResponseKeySpecifier),
		fields?: TrackDeleteResponseFieldPolicy,
	},
	TrackEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | TrackEdgeKeySpecifier | (() => undefined | TrackEdgeKeySpecifier),
		fields?: TrackEdgeFieldPolicy,
	},
	UpdateManyResponse?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | UpdateManyResponseKeySpecifier | (() => undefined | UpdateManyResponseKeySpecifier),
		fields?: UpdateManyResponseFieldPolicy,
	},
	User?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | UserKeySpecifier | (() => undefined | UserKeySpecifier),
		fields?: UserFieldPolicy,
	},
	UserConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | UserConnectionKeySpecifier | (() => undefined | UserConnectionKeySpecifier),
		fields?: UserConnectionFieldPolicy,
	},
	UserDeleteResponse?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | UserDeleteResponseKeySpecifier | (() => undefined | UserDeleteResponseKeySpecifier),
		fields?: UserDeleteResponseFieldPolicy,
	},
	UserEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | UserEdgeKeySpecifier | (() => undefined | UserEdgeKeySpecifier),
		fields?: UserEdgeFieldPolicy,
	},
	UserFavoritesConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | UserFavoritesConnectionKeySpecifier | (() => undefined | UserFavoritesConnectionKeySpecifier),
		fields?: UserFavoritesConnectionFieldPolicy,
	},
	UserLicensePurchase?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | UserLicensePurchaseKeySpecifier | (() => undefined | UserLicensePurchaseKeySpecifier),
		fields?: UserLicensePurchaseFieldPolicy,
	},
	UserTracksConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | UserTracksConnectionKeySpecifier | (() => undefined | UserTracksConnectionKeySpecifier),
		fields?: UserTracksConnectionFieldPolicy,
	}
};
export type TypedTypePolicies = StrictTypedTypePolicies & TypePolicies;