@if (isArtistOfTrack()) {
<button
  mat-flat-button
  class="manage-track-button"
  [routerLink]="['/track', track().id, 'edit']"
>
  @if (!shortenText()) {
  <mat-icon>edit</mat-icon>
  } Manage</button
>} @else { @if (!track().isFree) {
<button
  mat-stroked-button
  class="buy-license-button"
  (click)="showLicenseDialog()"
>
  @if (!shortenText()) {
  <span>BUY&nbsp;</span>
  }LICENSE
</button>
} @if (track().isFree) {
<div class="free-track-indicator">Free Track</div>
} }
