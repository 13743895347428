import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  inject,
} from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '../core/auth/auth.service';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { PopoverMobileMenuComponent } from './popover-mobile-menu/popover-mobile-menu.component';
import { PopoverMoreMenuComponent } from './popover-more-menu/popover-more-menu.component';
import { PopoverNotificationsMenuComponent } from './popover-notifications-menu/popover-notifications-menu.component';
import { PopoverUserMenuComponent } from './popover-user-menu/popover-user-menu.component';
import { ToolbarSearchComponent } from './toolbar-search/toolbar-search.component';
import { MatMenuModule } from '@angular/material/menu';
import { AuthResultDto, UserRole } from '@pallotone/data-access';
import { getImageResizeUrl } from '../core/utils/image-resize-url.utils';

@Component({
  selector: 'ptone-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    RouterModule,
    PopoverMobileMenuComponent,
    PopoverMoreMenuComponent,
    PopoverNotificationsMenuComponent,
    PopoverUserMenuComponent,
    ToolbarSearchComponent,
  ],
})
export class ToolbarComponent implements OnInit {
  private authService = inject(AuthService);

  @Input() scrolling: boolean = false;
  @Input() transparent: boolean = false;

  readonly UserRole = UserRole;
  authDto$: Observable<AuthResultDto | null>;

  constructor() {
    this.authDto$ = this.authService.authDto$;
  }

  ngOnInit(): void {}

  getImageResizeUrl(url: string, size: string): string {
    return getImageResizeUrl(url, size);
  }
}
