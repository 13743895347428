import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginDialog } from './core-ui/dialogs/login-dialog/login-dialog.component';
import { LoginDialogGuard } from './core/gaurds/login-dialog.guard';
import { AuthGuard } from './core/gaurds/auth.guard';
import { AlreadyAuthGuard } from './core/gaurds/already-auth.guard';
import { PendingChangesGuard } from './core/gaurds/pending-changes.gaurd';
import { ArtistGuard } from './core/gaurds/artist.guard';
import { ArtistOfTrackGuard } from './core/gaurds/artist-of-track.guard';

const routes: Routes = [
  {
    path: 'login',
    component: LoginDialog,
    canActivate: [LoginDialogGuard],
    data: {
      title: 'Sign in | Pallotone',
    },
  },
  {
    path: '',
    loadComponent: () =>
      import('./home/home.component').then((m) => m.HomeComponent),
  },
  {
    path: 'signin',
    loadComponent: () =>
      import('./auth/sign-in/sign-in.component').then((m) => m.SignInComponent),
    canActivate: [AlreadyAuthGuard],
    data: {
      title: 'Sign in | Pallotone',
      noToolbar: true,
      noFooter: true,
    },
  },
  {
    path: 'signup',
    loadComponent: () =>
      import('./auth/request-signup/request-signup.component').then((m) => m.RequestSignupComponent),
    canActivate: [AlreadyAuthGuard],
    data: {
      title: 'Sign up | Pallotone',
      noToolbar: true,
      noFooter: true,
    },
  },
  {
    path: 'onboarding',
    loadComponent: () =>
      import('./auth/onboarding/onboarding.component').then(
        (m) => m.OnboardingComponent,
      ),
    canActivate: [AlreadyAuthGuard],
    data: {
      title: 'Onboarding | Pallotone',
      noToolbar: true,
      noFooter: true,
    },
  },
  {
    path: 'reset-password',
    loadComponent: () =>
      import('./auth/recover/recover.component').then(
        (m) => m.RecoverComponent,
      ),
    canActivate: [AlreadyAuthGuard],
    data: {
      title: 'Recover account | Pallotone',
      noToolbar: true,
      noFooter: true,
    },
  },
  {
    path: 'track/:identifier/edit',
    loadComponent: () =>
      import('./track-edit/track-edit.component').then(
        (m) => m.TrackEditComponent,
      ),
    canActivate: [AuthGuard],
    data: {
      noFooter: true,
    },
    canDeactivate: [PendingChangesGuard],
  },
  {
    path: 'about',
    loadComponent: () =>
      import('./about/about.component').then((m) => m.AboutComponent),
    data: {
      title: 'About | Pallotone',
      scrollingToolbar: true,
      transparentToolbar: true,
    },
  },
  {
    path: 'about-us',
    loadComponent: () =>
      import('./about-us/about-us.component').then((m) => m.AboutUsComponent),
    data: {
      title: 'About us | Pallotone',
    },
  },
  // {
  //   path: 'artists',
  //   loadComponent: () =>
  //     import('./artists/artists.component').then((m) => m.ArtistsComponent),
  //   data: {
  //     title: 'Artists | Pallotone',
  //   },
  // },
  {
    path: 'checkout/:id',
    loadComponent: () =>
      import('./license-checkout/license-checkout.component').then(
        (m) => m.LicenseCheckoutComponent,
      ),
    canActivate: [ArtistOfTrackGuard],
    data: {
      title: 'Buy License | Pallotone',
    },
  },
  {
    path: 'favorites',
    loadComponent: () =>
      import('./favorites/favorites.component').then(
        (m) => m.FavoritesComponent,
      ),
    data: {
      title: 'Favorites | Pallotone',
    },
  },
  {
    path: 'purchases',
    loadComponent: () =>
      import('./purchases/purchases.component').then(
        (m) => m.PurchasesComponent,
      ),
    data: {
      title: 'Purchases | Pallotone',
    },
  },
  {
    path: 'guides',
    loadComponent: () =>
      import('./guides/guides.component').then((m) => m.GuidesComponent),
    children: [
      {
        path: '',
        loadComponent: () =>
          import('./guides/guides-home/guides-home.component').then(
            (m) => m.GuidesHomeComponent,
          ),
        data: {
          title: 'Guides | Pallotone',
          breadcrumb: 'Guides',
        },
      },
      {
        path: 'audio-file-formats',
        loadComponent: () =>
          import(
            './guides/audio-file-formats-guide/audio-file-formats-guide.component'
          ).then((m) => m.AudioFileFormatsGuideComponent),
        data: {
          title: 'Audio file formats | Pallotone Guides',
          breadcrumb: 'Audio file formats',
        },
      },
      {
        path: 'glossary',
        loadComponent: () =>
          import('./guides/glossary-guide/glossary-guide.component').then(
            (m) => m.GlossaryGuideComponent,
          ),
        data: {
          title: 'Glossary | Pallotone Guides',
          breadcrumb: 'Glossary',
        },
      },
      {
        path: 'how-to-upload',
        loadComponent: () =>
          import(
            './guides/how-to-upload-guide/how-to-upload-guide.component'
          ).then((m) => m.HowToUploadGuideComponent),
        data: {
          title: 'How to Upload | Pallotone Guides',
          breadcrumb: 'How to Upload',
        },
      },
      {
        path: 'keyboard-shortcuts',
        loadComponent: () =>
          import(
            './guides/keyboard-shortcuts-guide/keyboard-shortcuts-guide.component'
          ).then((m) => m.KeyboardShortcutsGuideComponent),
        data: {
          title: 'Keyboard Shortcuts | Pallotone Guides',
          breadcrumb: 'Keyboard Shortcuts',
        },
      },
      {
        path: 'playback-modes',
        loadComponent: () =>
          import(
            './guides/playback-modes-guide/playback-modes-guide.component'
          ).then((m) => m.PlaybackModesGuideComponent),
        data: {
          title: 'Playback Modes | Pallotone Guides',
          breadcrumb: 'Playback Modes',
        },
      },
    ],
  },
  {
    path: 'legal',
    loadComponent: () =>
      import('./legal/legal.component').then((m) => m.LegalComponent),
    children: [
      {
        path: 'privacy',
        loadComponent: () =>
          import('./legal/privacy/privacy.component').then(
            (m) => m.PrivacyComponent,
          ),
        data: {
          title: 'Privacy Policy | Pallotone',
        },
      },
      {
        path: 'terms',
        loadComponent: () =>
          import('./legal/terms/terms.component').then((m) => m.TermsComponent),
        data: {
          title: 'Terms of Use | Pallotone',
        },
      },
      {
        path: 'license',
        loadComponent: () =>
          import('./legal/licenses/licenses.component').then(
            (m) => m.LicensesComponent,
          ),
        data: {
          title: 'License Agreement | Pallotone',
        },
      },
    ],
  },
  {
    path: 'library',
    loadComponent: () =>
      import('./library/library.component').then((m) => m.LibraryComponent),
    canActivate: [LoginDialogGuard],
    children: [
      {
        path: '',
        loadComponent: () =>
          import(
            './library/your-tracks-library/your-tracks-library.component'
          ).then((m) => m.YourTracksLibraryComponent),
        canActivate: [ArtistGuard],
        data: {
          title: 'Library | Pallotone',
          redirectTo: '/library/purchases',
        },
      },
      {
        path: 'purchases',
        loadComponent: () =>
          import(
            './library/purchased-library/purchased-library.component'
          ).then((m) => m.PurchasedLibraryComponent),
        data: {
          title: 'Purchases | Pallotone',
        },
      },
      {
        path: 'favorites',
        loadComponent: () =>
          import(
            './library/favorites-library/favorites-library.component'
          ).then((m) => m.FavoritesLibraryComponent),
        data: {
          title: 'Favorites | Pallotone',
        },
      },
    ],
  },
  // {
  //   path: 'pricing',
  //   loadComponent: () =>
  //     import('./pricing/pricing.component').then((m) => m.PricingComponent),
  //   data: {
  //     title: 'Pricing | Pallotone',
  //     scrollingToolbar: true,
  //     transparentToolbar: true,
  //   },
  // },
  {
    path: 'account',
    loadComponent: () =>
      import('./account/account.component').then((m) => m.AccountComponent),
    canActivate: [AuthGuard],
    children: [
      {
        path: 'edit-profile',
        loadComponent: () =>
          import('./account/edit-profile/edit-profile.component').then(
            (m) => m.EditProfileComponent,
          ),
        data: {
          title: 'Edit Profile | Pallotone',
        },
      },
      {
        path: 'password',
        loadComponent: () =>
          import('./account/change-password/change-password.component').then(
            (m) => m.ChangePasswordComponent,
          ),
        data: {
          title: 'Change Password | Pallotone',
        },
      },
      {
        path: 'artist-account',
        loadComponent: () =>
          import('./account/artist-account/artist-account.component').then(
            (m) => m.ArtistAccountComponent,
          ),
        data: {
          title: 'Artist Account | Pallotone',
        },
      },
    ],
  },
  {
    path: 'track/:identifier',
    loadComponent: () =>
      import('./track/track.component').then((m) => m.TrackComponent),
  },
  {
    path: 'upload',
    loadComponent: () =>
      import('./track-upload/track-upload.component').then(
        (m) => m.TrackUploadComponent,
      ),
    data: {
      title: 'Upload | Pallotone',
    },
    canDeactivate: [PendingChangesGuard],
  },
  {
    path: 'artist/:identifier',
    loadComponent: () =>
      import('./user/user.component').then((m) => m.UserComponent),
    children: [
      {
        path: '',
        loadComponent: () =>
          import('./user/user-tracks/user-tracks.component').then(
            (m) => m.UserTracksComponent,
          ),
      },
      {
        path: 'favorites',
        loadComponent: () =>
          import('./user/user-favorites/user-favorites.component').then(
            (m) => m.UserFavoritesComponent,
          ),
      },
    ],
  },
  // {
  //   path: 'notifications',
  //   loadComponent: () =>
  //     import('./notifications/notifications.component').then(
  //       (m) => m.NotificationsComponent,
  //     ),
  //   canLoad: [AuthGuard],
  //   data: {
  //     title: 'Notifications | Pallotone',
  //   },
  // },
  // {
  //   path: 'pro',
  //   loadComponent: () =>
  //     import('./pro/pro.component').then((m) => m.ProComponent),
  //   data: {
  //     title: 'Pallotone Pro | Pallotone',
  //     scrollingToolbar: true,
  //     transparentToolbar: true,
  //   },
  // },
  {
    path: 'not-found',
    loadComponent: () =>
      import('./not-found/not-found.component').then(
        (m) => m.NotFoundComponent,
      ),
    data: {
      title: 'Not found | Pallotone',
    },
  },
  {
    path: '**',
    redirectTo: 'not-found',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      onSameUrlNavigation: 'reload',
      initialNavigation: 'enabledBlocking',
    }),
  ],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
