import {
  ChangeDetectorRef,
  Component,
  inject,
  OnInit,
  signal,
} from '@angular/core';
import { Subject } from 'rxjs';
import { RevelationService } from './core/revelation/revelation.service';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, take, takeUntil } from 'rxjs/operators';
import { TrackEntity } from './models/track-entity.model';
import { AuthService } from './core/auth/auth.service';
import { AuthResultDto } from '@pallotone/data-access';

@Component({
  selector: 'ptone-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  private playerService = inject(RevelationService);
  private authService = inject(AuthService);
  private titleService = inject(Title);
  private router = inject(Router);
  private activatedRoute = inject(ActivatedRoute);
  private changeDetectorRef = inject(ChangeDetectorRef);

  activeTrack: TrackEntity;
  noToolbar: boolean = false;
  transparentToolbar: boolean = false;
  scrollingToolbar: boolean = false;
  noFooter: boolean = false;

  authDto = signal<AuthResultDto>(null);

  private destroy$ = new Subject<void>();

  ngOnInit() {
    // if (this.authService.isTokenExpired()) {
    //   this.authService
    //     .refreshAuthData()
    //     .pipe(take(1))
    //     .subscribe({
    //       next: (authData) => {
    //         this.authDto.set(authData);
    //       },
    //       error: () => {
    //         this.authService.signout();
    //         this.playerService.resetPlayer();
    //       },
    //     });
    // }
    this.authService.authDto$
      .pipe(takeUntil(this.destroy$))
      .subscribe((authDto) => {
        this.authDto.set(authDto);
      });
    this.playerService.activeTrack$
      .pipe(takeUntil(this.destroy$))
      .subscribe((track: TrackEntity) => {
        this.activeTrack = track;
      });

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.destroy$),
      )
      .subscribe(() => this.setRouteData());
  }

  private setRouteData(): void {
    let route = this.activatedRoute;
    while (route.firstChild) route = route.firstChild;
    const routeData = route.snapshot.data;
    this.titleService.setTitle(routeData['title'] || 'Pallotone');
    this.noToolbar = !!routeData['noToolbar'];
    this.transparentToolbar = !!routeData['transparentToolbar'];
    this.scrollingToolbar = !!routeData['scrollingToolbar'];
    this.noFooter = !!routeData['noFooter'];
    this.changeDetectorRef.detectChanges();
  }
}
