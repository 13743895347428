import { Injectable } from '@angular/core';
import { TrackSettings } from '../../models/track-settings.model';
import { List } from 'immutable';
import { BehaviorSubject } from 'rxjs';
import { TrackEntity } from '../../models/track-entity.model';

@Injectable()
export class TrackSettingsService {
  private activeTrackSettingsSource = new BehaviorSubject<TrackSettings>(
    undefined,
  );
  activeTrackSettingsWatch$ = this.activeTrackSettingsSource.asObservable();

  activeTrackSettings: string;
  tracks: { [id: string]: TrackSettings } = {};
  trackDefaults: { [id: string]: TrackSettings } = {};

  constructor() {
    // this.activeTrackSettings = parseInt(localStorage.getItem('ptone_web4_active_track_settings_id'), 10);
    this.activeTrackSettings = undefined;
  }

  createSettingsFor(track: TrackEntity) {
    let gains = [],
      mutes = [],
      solos = [],
      stemsActive = [],
      stemsBuffering = [];
    track.audioChannels.forEach((_, i) => {
      gains[i] = 1;
      mutes[i] = false;
      solos[i] = false;
      stemsActive[i] = false;
      stemsBuffering[i] = false;
    });
    let trackSettings = new TrackSettings({
      id: track.id,
      gains: List(gains),
      mutes: List(mutes),
      solos: List(solos),
      stemsActive: List(stemsActive),
      stemsBuffering: List(stemsBuffering),
    });
    track.settings = trackSettings;
    if (track.id) {
      this.tracks[track.id] = trackSettings;
      this.trackDefaults[track.id] = trackSettings;
    }
  }

  private setActiveTrackSettings(trackSettings: TrackSettings) {
    if (trackSettings) {
      this.activeTrackSettings = trackSettings.id;
    } else {
      this.activeTrackSettings = undefined;
    }
    this.activeTrackSettingsSource.next(trackSettings);
  }

  getActiveTrackSettings(): TrackSettings {
    return this.tracks[this.activeTrackSettings];
  }

  private setTrackSettings(trackSettings: TrackSettings) {
    if (trackSettings.id === this.activeTrackSettings) {
      this.setActiveTrackSettings(trackSettings);
    }
    // this.tracks[trackSettings.id] = trackSettings;
    trackSettings.save();
  }

  getTrackSettings(id: string): TrackSettings {
    return this.tracks[id];
  }

  setActiveTrack(id: string) {
    // if (this.tracks[this.activeTrackSettings]) {
    //   this.tracks[this.activeTrackSettings].set('active', false).set('ready', false)
    //     .set('state', TrackState.inactive).setStemsActive([]).resetSettings();
    //   this.setTrackSettings(this.tracks[this.activeTrackSettings]);
    // }
    // if (id >= 0 && this.tracks[id]) {
    //   this.activeTrackSettings = id;
    //   this.setTrackSettings(this.tracks[id].set('active', true));
    // } else if (!id) {
    //   this.setActiveTrackSettings(undefined);
    // }
  }

  resetTrackSettings(trackId: string) {
    this.tracks[trackId].resetSettings();
  }

  setTrackInvestigating(trackId: string, investigating: boolean) {
    if (!investigating) {
      this.resetTrackSettings(trackId);
    }
    this.setTrackSettings(
      this.tracks[trackId].set('investigating', investigating),
    );
  }
}
