<div
  class="toolbar"
  [ngClass]="{ fixed: !scrolling, transparent: transparent }"
>
  <div class="container">
    <nav class="flex-container" role="navigation">
      <a
        class="ptone-logo-link height-25"
        routerLink="/"
        title="Pallotone Home"
      ></a>
      <div class="search-wrapper">
        <ptone-toolbar-search></ptone-toolbar-search>
      </div>
      <!-- <a
        mat-button
        class="basic-nav-link core-nav-item"
        routerLink="/"
        [routerLinkActiveOptions]="{ exact: true }"
        routerLinkActive="link-active"
        >Music</a
      >
      <a
        mat-button
        class="basic-nav-link core-nav-item"
        routerLink="/artists"
        routerLinkActive="link-active"
        >Artists</a
      >
      <a
        mat-button
        class="basic-nav-link core-nav-item"
        routerLink="/pricing"
        routerLinkActive="link-active"
        >Pricing</a
      > -->
      <div class="flex-spacer"></div>
      @if (authDto$ | async) {
      <a
        mat-button
        class="basic-nav-link core-nav-item"
        routerLink="/account"
        [routerLinkActiveOptions]="{ exact: true }"
        routerLinkActive="link-active"
        >Account</a
      >
      <a
        mat-button
        class="basic-nav-link core-nav-item"
        routerLink="/library"
        routerLinkActive="link-active"
        >Library</a
      >
      @if ((authDto$ | async).roles.includes(UserRole.Artist)) {
      <a
        mat-button
        class="basic-nav-link core-nav-item"
        routerLink="/upload"
        routerLinkActive="link-active"
        >Upload</a
      >
      }
      <!-- <button
        *ngIf="(authDto$ | async)"
        mat-icon-button
        class="notifications-button"
        [matBadge]="
          userData.new_notifications_count > 0
            ? userData.new_notifications_count
            : undefined
        "
        matTooltip="Notifications"
        [matMenuTriggerFor]="notificationsMenu.matMenu"
        (menuOpened)="markNotificationsAsRead()"
      >
        <mat-icon>notifications</mat-icon>
      </button> -->
      <button
        mat-icon-button
        class="avatar-button medium"
        [matMenuTriggerFor]="userMenu.matMenu"
      >
        <img
          [src]="
            (authDto$ | async).avatarUrl
              ? getImageResizeUrl((authDto$ | async).avatarUrl, '100x100')
              : 'assets/images/common/profile-default.svg'
          "
          ptoneImgFallback="assets/images/common/profile-default.svg"
          alt="{{ (authDto$ | async)?.name }}"
        />
      </button>
      } @else {
      <a
        mat-button
        class="basic-nav-link core-nav-item"
        routerLink="/upload"
        routerLinkActive="link-active"
        >Submit Music</a
      >
      <a mat-button class="auth-link" routerLink="/signin">Sign In</a>
      <a mat-stroked-button class="auth-link signup-link" routerLink="/signup"
        >Sign Up</a
      >
      <!-- <button
        mat-icon-button
        class="mobile-menu-toggle medium"
        [matMenuTriggerFor]="mobileMenu.matMenu"
      >
        <mat-icon>more_horiz</mat-icon>
      </button> -->
      }
    </nav>
  </div>
  <!-- <div class="container">
    <div class="search-wrapper mobile-search-wrapper">
      <ptone-toolbar-search></ptone-toolbar-search>
    </div>
  </div> -->
</div>
<ptone-popover-user-menu #userMenu></ptone-popover-user-menu>
<ptone-popover-mobile-menu #mobileMenu></ptone-popover-mobile-menu>
<!-- <ptone-popover-notifications-menu
  #notificationsMenu
></ptone-popover-notifications-menu> -->
