<div class="default-artwork">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="#7b7e82"
    width="50%"
    height="50%"
    class="absolute-center"
  >
    <path d="M0 0h24v24H0z" fill="none" />
    <path
      d="M12 3v9.28c-.47-.17-.97-.28-1.5-.28C8.01 12 6 14.01 6 16.5S8.01 21 10.5 21c2.31 0 4.2-1.75 4.45-4H15V6h4V3h-7z"
    />
  </svg>
</div>
