import { Injectable, inject } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { Observable, catchError, map, of, switchMap, take } from 'rxjs';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { getPtoneSnackBarConfig } from '../../utils/ptone-material.config';
import { TrackService } from '../track/track.service';

@Injectable({
  providedIn: 'root',
})
export class ArtistOfTrackGuard {
  private authService = inject(AuthService);
  private trackService = inject(TrackService);
  private router = inject(Router);
  private matSnackBar = inject(MatSnackBar);

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.authService.authDto$.pipe(
      take(1),
      switchMap((authDto) => {
        if (!authDto) {
          return of(true);
        }
        return this.trackService.findTrackByIdentifier(route.params['id']).pipe(
          map((track) => {
            const isArtistOfTrack = authDto.id === track.artist.id;
            if (isArtistOfTrack) {
              this.router.navigate(['/']);
              this.openSnackbar('This is your track.');
              return false;
            }
            return true;
          }),
          catchError(() => {
            this.router.navigate(['/']);
            return of(false);
          }),
        );
      }),
    );
  }

  private openSnackbar(text: string) {
    this.matSnackBar.open(text, undefined, getPtoneSnackBarConfig());
  }
}
