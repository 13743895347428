import { Injectable, inject } from '@angular/core';
import { WaveformModel } from '../../models/waveform.model';
import WaveformData from 'waveform-data';
import { TrackDataService } from '../track-data/track-data.service';
import { BehaviorSubject } from 'rxjs';
import { LocalAudioErrors } from '../../models/local-audio-errors.enum';
import { RevelationService } from '../revelation/revelation.service';
import { TrackEntity } from '../../models/track-entity.model';
import { AudioChannelEntity } from '../../models/audio-channel-entity.model';

@Injectable()
export class LocalTrackService {
  private trackDataService = inject(TrackDataService);
  private playerService = inject(RevelationService);

  localTrackIdCounter = -1;
  localAudioChannelIdCounter = -1;
  private _loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );
  private _error$: BehaviorSubject<LocalAudioErrors> =
    new BehaviorSubject<LocalAudioErrors>(null);

  audioContext = new AudioContext();

  get loading$() {
    return this._loading$.asObservable();
  }

  get error$() {
    return this._error$.asObservable();
  }

  generateLocalTrack(formTrack: any): Promise<TrackEntity> {
    this._loading$.next(true);
    this._error$.next(null);
    return new Promise((resolve, reject) => {
      this.setLocalTrack(formTrack).then((track: TrackEntity) => {
        this._loading$.next(false);
        resolve(track);
      });
    });
  }

  setLocalTrack(createTrack: any): Promise<TrackEntity> {
    return new Promise((resolve, reject) => {
      const localTrack: TrackEntity = createTrack;
      localTrack.title = 'Untitled Track';
      localTrack.id = this.localTrackIdCounter.toString();
      localTrack.audioChannels = createTrack.audioChannels;
      this.localTrackIdCounter--;
      const promArr = [];
      for (const channel of localTrack.audioChannels) {
        promArr.push(this.createLocalAudioChannel(channel));
      }
      Promise.all(promArr).then(() => {
        this.trackDataService.processTrack(localTrack, true);
        resolve(localTrack);
      });
    });
  }

  createLocalAudioChannel(audioChannel) {
    return new Promise((resolve, reject) => {
      if (audioChannel.uploaderFile) {
        audioChannel.isLocal = true;
        audioChannel.id = this.localAudioChannelIdCounter.toString();
        this.localAudioChannelIdCounter--;
        const reader = new FileReader();
        reader.onload = (e) => {
          audioChannel.blob = e.target.result;
          this.generateAudioChannelData(audioChannel).then(() =>
            resolve(audioChannel),
          );
        };
        reader.readAsDataURL(audioChannel.uploaderFile.uploadItem.file.rawFile);
      }
    });
  }

  generateAudioChannelData(
    audioChannel: AudioChannelEntity,
  ): Promise<AudioChannelEntity> {
    return new Promise((resolve, reject) => {
      this.audioContext
        .decodeAudioData(this.dataURItoArrayBuffer(audioChannel.blob as string))
        .then((buffer) => {
          audioChannel.duration = buffer.duration;
          this.createWaveformData(
            audioChannel,
            buffer.duration,
            buffer.sampleRate,
          ).then((waveform) => {
            waveform.data = waveform.data.map((val) => Math.abs(val));
            audioChannel.waveformData = waveform;
            resolve(audioChannel);
          });
        });
    });
  }

  createWaveformData(
    audioChannel: AudioChannelEntity,
    duration: number,
    sampleRate: number,
  ): Promise<WaveformModel> {
    return new Promise((resolve, reject) => {
      const scale = Math.ceil((duration * sampleRate) / 1800);
      const options = {
        audio_context: this.audioContext,
        array_buffer: this.dataURItoArrayBuffer(audioChannel.blob as string),
        scale: scale,
      };
      WaveformData.createFromAudio(options, (err, waveform) => {
        if (err) {
          console.log('waveform-data-error', err);
        } else {
          // console.log("waveform-data", waveform.length, waveform.toJSON());
          resolve(waveform.toJSON());
        }
      });
    });
  }

  dataURItoArrayBuffer(dataURI: string): ArrayBuffer {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    let byteString = atob(dataURI.split(',')[1]);
    // separate out the mime component
    let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    // write the bytes of the string to an ArrayBuffer
    let ab = new ArrayBuffer(byteString.length);
    // create a view into the buffer
    let ia = new Uint8Array(ab);
    // set the bytes of the buffer to the correct values
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return ab;
  }

  resetLocalTrack() {
    this.playerService.resetPlayer();
    this._loading$.next(false);
    this._error$.next(null);
  }
}
