import {
  Component,
  Input,
  OnInit,
  Signal,
  computed,
  inject,
  input,
  signal,
} from '@angular/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { TrackEntity } from '../../models/track-entity.model';
import { Subject, takeUntil } from 'rxjs';
import { AuthService } from '../../core/auth/auth.service';
import { RouterLink } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { AuthResultDto } from '@pallotone/data-access';
import { LicenseDialog } from '../dialogs/license-dialog/license-dialog.component';

@Component({
  selector: 'ptone-buy-license-button',
  templateUrl: './buy-license-button.component.html',
  styleUrls: ['./buy-license-button.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    RouterLink,
    MatIconModule,
  ],
})
export class BuyLicenseButtonComponent implements OnInit {
  private matDialog = inject(MatDialog);

  track = input<TrackEntity>();
  shortenText = input(false);
  authDto = signal<AuthResultDto>(null);

  isArtistOfTrack: Signal<boolean> = computed(
    () => this.track()?.artist.id === this.authDto()?.id,
  );

  private authService = inject(AuthService);

  private destroy$ = new Subject<void>();

  ngOnInit(): void {
    this.authService.authDto$
      .pipe(takeUntil(this.destroy$))
      .subscribe((authDto) => {
        this.authDto.set(authDto);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  showLicenseDialog() {
    if (this.track()) {
      LicenseDialog.showLicenseDialog(this.matDialog, this.track());
    }
  }
}
