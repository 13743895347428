<div class="dialog-container">
  <h4>{{ dialogData.header }}</h4>
  <p>{{ dialogData.message }}</p>
  <div class="divider"></div>
  <div class="actions">
    <button mat-button (click)="denyConfirm()">{{ dialogData.deny }}</button>
    <button
      mat-button
      [color]="dialogData.approveColor"
      (click)="approveConfirm()"
    >
      {{ dialogData.approve }}
    </button>
  </div>
</div>
