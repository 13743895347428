import { FileItem, FileUploader, ParsedResponseHeaders } from 'ng2-file-upload';
import { UploadManagerService } from './upload.service';
import { Subject } from 'rxjs';
import { UploadFile } from './upload-file';
import { UploadFileBuilder } from './upload-file.builder';
import { TrackEntity } from '../../models/track-entity.model';
import { UploadType } from '@pallotone/data-access';

export class Uploader<T extends UploadFile> {
  private uploadFileToCreate: UploadFileBuilder<T>;

  uploader: FileUploader = new FileUploader({ url: '' });
  uploadType: UploadType;
  manager: UploadManagerService;
  uploadsDone: boolean;

  fileLimit: number = 1;
  uploadFiles: T[] = [];

  // Audio
  trackId: string;
  track: TrackEntity;
  processTrackTimeout: any;

  private fileAddedSource = new Subject<[boolean, T, string]>();
  fileAddedWatch$ = this.fileAddedSource.asObservable();

  private progressAllSource = new Subject<number>();
  progressAllWatch$ = this.progressAllSource.asObservable();

  private processingCompleteSource = new Subject<TrackEntity>();
  processingCompleteWatch$ = this.processingCompleteSource.asObservable();

  private errorSource = new Subject<any>();
  errorWatch$ = this.errorSource.asObservable();

  constructor(
    uploadFileToCreate: UploadFileBuilder<T>,
    type: UploadType,
    fileLimit: number,
    manager: UploadManagerService,
  ) {
    this.uploadFileToCreate = uploadFileToCreate;
    this.fileLimit = fileLimit;

    this.uploader.setOptions({
      disableMultipart: true,
      method: 'PUT',
      url: '',
    });
    this.manager = manager;
    this.uploadType = type;
    this.uploader.onErrorItem = (
      item: FileItem,
      response: string,
      status: number,
      headers: ParsedResponseHeaders,
    ) => {
      this.uploadError({
        response: response,
        status: status,
        headers: headers,
      });
    };
    this.uploader.onAfterAddingFile = (fileItem: FileItem) => {
      let allowFileTuple = uploadFileToCreate.ShouldAllowFile(fileItem);
      let allowFile = allowFileTuple[0],
        error = allowFileTuple[1];
      if (allowFile) {
        if (this.uploader.queue.length > this.fileLimit) {
          this.uploader.removeFromQueue(fileItem);
          this.fileAddedSource.next([false, undefined, 'Exceeding file limit']);
        } else {
          const uploadFile = uploadFileToCreate.create(
            uploadFileToCreate,
            type,
            manager,
            fileItem.file.name,
            fileItem,
          );
          this.uploadFiles.push(uploadFile);
          uploadFile.errorWatch$.subscribe((error2) => {
            this.uploadError(error2);
          });
          this.fileAddedSource.next([true, uploadFile, undefined]);
        }
      } else {
        this.uploader.removeFromQueue(fileItem);
        this.fileAddedSource.next([false, undefined, error]);
      }
    };
    this.uploader.onProgressAll = this.progressAll;
  }

  progressAll = (progress: number) => {
    this.progressAllSource.next(progress);
    if (progress === 100 && this.uploadType === UploadType.Audio) {
      this.audioUploadsDone();
      this.uploadsDone = true;
    }
  };

  get isUploading() {
    return this.uploader.isUploading;
  }

  hasFiles() {
    // console.log('hasFiles?', this.uploadFiles.length);
    return this.uploader.queue.length > 0;
  }

  indexOf(uploadFile: T) {
    return this.uploadFiles.indexOf(uploadFile);
  }

  // Get upload file via index
  get(index: number): T {
    return this.uploadFiles[index];
  }

  setTrack(track: TrackEntity) {
    this.track = track;
  }

  uploadIndex(index: number) {
    const toUpload = this.uploadFiles[index];
    if (toUpload) {
      toUpload.upload();
    }
  }

  uploadAll(): void {
    for (let uploadFile of this.uploadFiles) {
      if (!uploadFile.uploadStarted) {
        uploadFile.upload();
      }
    }
  }

  cancelAll(): void {
    for (let uploadFile of this.uploadFiles) {
      uploadFile.cancel();
    }
  }

  removeAll(): void {
    for (let i = this.uploadFiles.length - 1; i >= 0; i--) {
      const uploadFile = this.uploadFiles[i];
      this.uploadFiles.splice(i, 1);
      uploadFile.remove();
    }
  }

  remove(uploadFile: T) {
    const index = this.uploadFiles.indexOf(uploadFile);
    uploadFile.remove();
    // this.uploader.removeFromQueue(uploadFile.uploadItem);
    this.uploadFiles.splice(index, 1);
  }

  audioUploadsDone() {
    if (!this.uploadsDone) {
      // this.manager.pallotoneApiService
      //   .getTrack(this.track.id)
      //   .pipe(first())
      //   .subscribe((res: Track) => {
      //     this.track = res;
      //   });
      // this.processTrackTimeout = window.setInterval(() => {
      //   this.manager.pallotoneApiService
      //     .getTrack(this.track.id)
      //     .pipe(first())
      //     .subscribe(
      //       (res: Track) => {
      //         this.track = res;
      //         if (
      //           this.track.transcode_status === 'complete' ||
      //           this.track.transcode_status === 'partial'
      //         ) {
      //           this.progressAllSource.next(101);
      //           this.processingCompleteSource.next(res);
      //           clearInterval(this.processTrackTimeout);
      //           this.processTrackTimeout = undefined;
      //         }
      //       },
      //       (error) => {
      //         this.uploadError(error);
      //       },
      //     );
      // }, 10000);
    }
  }

  uploadError(error: any) {
    if (this.processTrackTimeout) {
      clearInterval(this.processTrackTimeout);
    }
    this.errorSource.next(error);
  }
}
