import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogConfig,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { getPtoneDialogConfig } from '../../../utils/ptone-material.config';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';

interface ConfirmDialogData {
  header: string;
  message: string;
  approve: string;
  deny: string;
  center: boolean;
  approveColor: string;
}

@Component({
  selector: 'ptone-confirm',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatDialogModule],
})
export class ConfirmDialog implements OnInit {
  static showConfirmDialog(
    matDialog: MatDialog,
    header: string,
    message: string,
    approve: string,
    deny: string,
    approveColor = 'primary',
  ) {
    let config: MatDialogConfig = getPtoneDialogConfig();
    config.width = '440px';
    config.data = {
      header: header,
      message: message,
      approve: approve,
      deny: deny,
      approveColor: approveColor,
    };
    return matDialog.open(ConfirmDialog, config);
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: ConfirmDialogData,
    public dialogRef: MatDialogRef<ConfirmDialog>,
  ) {
    dialogRef.beforeClosed().subscribe((result) => {
      if (result === undefined) this.denyConfirm();
    });
  }

  ngOnInit(): void {}

  approveConfirm() {
    this.dialogRef.close(true);
  }

  denyConfirm() {
    this.dialogRef.close(false);
  }
}
