import { Injectable, inject } from '@angular/core';
import {
  CreateStripeLoginLinkGQL,
  IsStripeAccountVerifiedGQL,
  OnboardArtistGQL,
} from '@pallotone/data-access-ng';
import { map, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class StripeService {
  private onboardArtistGql = inject(OnboardArtistGQL);
  private createStripeLoginLinkGql = inject(CreateStripeLoginLinkGQL);
  private isStripeAccountVerifiedGql = inject(IsStripeAccountVerifiedGQL);

  onboardArtist(): Observable<string> {
    return this.onboardArtistGql.mutate().pipe(
      map((result) => {
        const res: string = result.data.onboardArtist;
        return res;
      }),
    );
  }

  createStripeLoginLink(): Observable<string> {
    return this.createStripeLoginLinkGql.mutate().pipe(
      map((result) => {
        const res: string = result.data.createStripeLoginLink;
        return res;
      }),
    );
  }

  isStripeAccountVerified(userId: string): Observable<boolean> {
    return this.isStripeAccountVerifiedGql
      .fetch({
        userId,
      })
      .pipe(
        map((result) => {
          const res: boolean = result.data.isStripeAccountVerified;
          return res;
        }),
      );
  }
}
