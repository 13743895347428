import { inject, Injectable } from '@angular/core';
import { ApolloLink } from '@apollo/client/core';
import { HttpLink } from 'apollo-angular/http';
import { InMemoryCache } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class ApolloService {
  private httpLink = inject(HttpLink);

  createApollo(): any {
    const authLink = setContext((_, { headers }) => {
      const token = localStorage.getItem('ptoneAuthDto')
        ? JSON.parse(localStorage.getItem('ptoneAuthDto')).accessToken
        : '';
      return {
        headers: {
          ...headers,
          Authorization: token ? `Bearer ${token}` : '',
        },
      };
    });
    const http = this.httpLink.create({
      uri: environment.apiUrl,
    });
    return {
      cache: new InMemoryCache({
        typePolicies: {
          Track: { keyFields: ['id'] },
          User: { keyFields: ['id'] },
          AudioChannel: { keyFields: ['id'] },
        },
      }),
      link: ApolloLink.from([authLink, http]),
    };
  }
}
