import { Injectable, inject } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  catchError,
  map,
  tap,
  throwError,
} from 'rxjs';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { getPtoneSnackBarConfig } from '../../utils/ptone-material.config';
import {
  ChangePasswordGQL,
  RefreshAuthDataGQL,
  RequestSignupGQL,
  ResetPasswordGQL,
  SendPasswordResetEmailGQL,
  SigninGQL,
  SignupGQL,
  SignupWithTokenGQL,
} from '@pallotone/data-access-ng';
import { AuthResultDto } from '@pallotone/data-access';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private matSnackBar = inject(MatSnackBar);
  private router = inject(Router);
  private signupGql = inject(SignupGQL);
  private signinGql = inject(SigninGQL);
  private changePasswordGql = inject(ChangePasswordGQL);
  private resetPasswordGql = inject(ResetPasswordGQL);
  private sendPasswordResetEmailGql = inject(SendPasswordResetEmailGQL);
  private refreshAuthDataGql = inject(RefreshAuthDataGQL);
  private requestSignupGql = inject(RequestSignupGQL);
  private signupWithTokenGql = inject(SignupWithTokenGQL);

  private _authDtoSubject = new BehaviorSubject<AuthResultDto | null>(
    JSON.parse(localStorage.getItem('ptoneAuthDto') || 'null'),
  );

  authDto$: Observable<AuthResultDto | null> =
    this._authDtoSubject.asObservable();

  getAccessToken(): string {
    return this._authDtoSubject.value?.accessToken || '';
  }

  requestSignup(
    email: string,
    name: string,
    password: string,
  ): Observable<boolean> {
    return this.requestSignupGql.mutate({ email, name, password }).pipe(
      map((response) => response.data.requestSignup),
      catchError((error) => throwError(() => error)),
    );
  }

  signupWithToken(token: string): Observable<AuthResultDto> {
    return this.signupWithTokenGql.mutate({ token }).pipe(
      map((response) => response.data.signupWithToken),
      tap((authDto) => {
        this.updateAuthDto(authDto);
      }),
      catchError((error) => {
        return throwError(() => error);
      }),
    );
  }

  signup(
    email: string,
    name: string,
    password: string,
  ): Observable<AuthResultDto> {
    return this.signupGql.mutate({ email, name, password }).pipe(
      map((response) => response.data.signup),
      tap((authDto) => {
        this.updateAuthDto(authDto);
      }),
      catchError((error) => {
        return throwError(() => error);
      }),
    );
  }

  signin(email: string, password: string): Observable<AuthResultDto> {
    return this.signinGql.mutate({ email, password }).pipe(
      map((response) => response.data.signin),
      tap((response) => {
        this.updateAuthDto(response);
        this.openSnackbar(`Welcome back ${response.name}`);
      }),
      catchError((error) => {
        return throwError(() => error);
      }),
    );
  }

  signout(): void {
    localStorage.removeItem('ptoneAuthDto');
    this._authDtoSubject.next(null);
    this.router.navigate(['/']).then(() => this.openSnackbar('Logged out'));
  }

  isTokenExpired(): boolean {
    const token = this._authDtoSubject.value?.accessToken;
    if (!token) return true;
    const { exp } = JSON.parse(atob(token.split('.')[1]));
    return Date.now() >= exp * 1000;
  }

  sendPasswordResetEmail(email: string): Observable<boolean> {
    return this.sendPasswordResetEmailGql.mutate({ email }).pipe(
      map((response) => response.data.sendPasswordResetEmail),
      tap((response) => {
        console.log('sendPasswordResetEmail response', response);
      }),
      catchError((error) => {
        return throwError(() => error);
      }),
    );
  }

  changePassword(
    currentPassword: string,
    newPassword: string,
  ): Observable<boolean> {
    return this.changePasswordGql.mutate({ currentPassword, newPassword }).pipe(
      map((response) => response.data.changePassword),
      tap((response) => {
        console.log('changePassword response', response);
      }),
      catchError((error) => {
        return throwError(() => error);
      }),
    );
  }

  resetPassword(token: string, newPassword: string): Observable<boolean> {
    return this.resetPasswordGql.mutate({ token, newPassword }).pipe(
      map((response) => response.data.resetPassword),
      tap((response) => {
        console.log('resetPassword response', response);
      }),
      catchError((error) => {
        return throwError(() => error);
      }),
    );
  }

  refreshAuthData(): Observable<AuthResultDto> {
    return this.refreshAuthDataGql
      .fetch({}, { fetchPolicy: 'network-only' })
      .pipe(
        map((response) => response.data.refreshAuthData),
        tap((response) => {
          this.updateAuthDto(response);
        }),
        catchError((error) => {
          return throwError(() => error);
        }),
      );
  }

  updateAuthProfile({
    name,
    username,
    avatarUrl,
  }: {
    name?: string;
    username?: string;
    avatarUrl?: string;
  }): void {
    const currentAuthDto = this._authDtoSubject.value;
    if (currentAuthDto) {
      const updatedAuthDto = {
        ...currentAuthDto,
        ...{ name },
        ...{ username },
        ...{ avatarUrl },
      };
      this.updateAuthDto(updatedAuthDto);
    }
  }

  private updateAuthDto(authDto: AuthResultDto | null): void {
    localStorage.setItem('ptoneAuthDto', JSON.stringify(authDto));
    this._authDtoSubject.next(authDto);
  }

  private openSnackbar(text: string) {
    this.matSnackBar.open(text, undefined, getPtoneSnackBarConfig());
  }
}
